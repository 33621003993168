import { Injectable } from '@angular/core';
import { throwError, Observable, Subject  } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, map} from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders, HttpBackend } from  '@angular/common/http';
import { GlobalVariable } from '.././global';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  
  private subjectName = new Subject<any>(); //need to create a subject

  errorData: {};
  dataTablesParameters: any;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };  
  
  private http: HttpClient;

  constructor(handler: HttpBackend,private httpClient: HttpClient) {
      this.http = new HttpClient(handler);
  }


 
  // Menu Update Subject
  sendMenuUpdateMessage(message: string) { //the component that wants to update something, calls this fn
    this.subjectName.next({ text: message }); //next() will feed the value in Subject
  }

  getUpdate(): Observable<any> { //the receiver component calls this function 
      return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  // pass data

  private numbersdata = new BehaviorSubject<any>({
    // numberslist:this.numbers
  });
  setNumbersdata(numbers: any) {
    this.numbersdata.next(numbers);
  }
  getnumbersInfo() {
    return this.numbersdata.asObservable();
  }
 
  // private menudata = new BehaviorSubject<any>({
  //   // numberslist:this.numbers
  // });
  // setMenudata(numbers: any) {
  //   this.menudata.next(numbers);
  // }
  // getMenuInfo() {
  //   return this.menudata.asObservable();
  // }

  // Header
  getcallvalue(company_uuid: string,timezone: number) {
    return this.http.post<any>(this.ServerUrl + 'getcallvalue/' + company_uuid,timezone).pipe(
      catchError(this.handleError)
    );
  }  
  
  getcompanydetailsbyuuid(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getcompanydetailsbyuuid/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  
  
  getcompanydetailsbyuuid1(length: string,limit:string) {
    return this.http.get<any>(this.ServerUrl + 'getcompanydetailsbyuuid1/' + length +'/'+ limit).pipe(
      catchError(this.handleError)
    );
  }  

  // Profile
  getProfiledata(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getProfiledata/' + company_uuid).pipe(
      catchError(this.handleError)
    );
  }  
  editProfile(formData, company_uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'editProfile/' + company_uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }  

  // Dashboard Module
  getNumberOfCount(company_uuid: string,timezone: number,user_uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'getNumberOfCount/'+company_uuid+'/'+user_uuid+'/',timezone).pipe(
      catchError(this.handleError)
    );
  } 
  
  getChartbyDate(company_uuid: string,date:any,timezone: number,user_uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'getChartNumberOfCalls/'+company_uuid+'/'+timezone+'/'+user_uuid+'/',date).pipe(
      catchError(this.handleError)
    );
  } 
  
  getChartbyfilter(company_uuid: string,chartarg:any,daytype:any,timezone:any) {
    // if(val==''){
    //   val = 0;
    // }
    return this.http.post<any>(this.ServerUrl + 'getChartbyfilter/'+company_uuid+'/'+daytype+'/'+timezone+'/',chartarg).pipe(
      catchError(this.handleError)
    );
  } 
  

  getChartbyfilterbing(company_uuid: string,chartarg:any,daytype:any,timezone:any) {
    // if(val==''){
    //   val = 0;
    // }
    return this.http.post<any>(this.ServerUrl + 'getChartbyfilter-bing/'+company_uuid+'/'+daytype+'/'+timezone+'/',chartarg).pipe(
      catchError(this.handleError)
    );
  } 
  
  // getChartNumberOfCalls(company_uuid: string,date:any) {
  //   return this.http.get<any>(this.ServerUrl + 'getChartNumberOfCalls/'+company_uuid).pipe(
  //     catchError(this.handleError)
  //   );
  // } 

  // Buyer
  deleteBuyer(id: number,user_uuid: string) {
    return this.http.get(this.ServerUrl + 'deleteBuyer/'+id+'/'+user_uuid).pipe(
      catchError(this.handleError)
    );
  }

  addBuyer(formData) {
    return this.http.post<any>(this.ServerUrl + 'addBuyer/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  editBuyer(formData) {
    return this.http.post<any>(this.ServerUrl + 'editBuyer/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }
 
   // Publishers

  addPublisher(formData) {
    return this.http.post<any>(this.ServerUrl + 'addPublisher/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  editPublisher(formData) {
    return this.http.post<any>(this.ServerUrl + 'editPublisher/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }
 
  editNumberdata(formData) {
    return this.http.post<any>(this.ServerUrl + 'editNumberdata/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  deletePublisher(id: number,user_uuid: string) {
    return this.http.get(this.ServerUrl + 'deletePublisher/' + id + '/' + user_uuid).pipe(
      catchError(this.handleError)
    );
  }

  loadNumbersfordropdown(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'loadNumbersfordropdown/' + company_uuid).pipe(
      catchError(this.handleError)
    );
  }  

  // Campaign
  addCampaign(company_uuid: string, formData) {
    return this.http.post<any>(this.ServerUrl + 'addCampaign/' + company_uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  deleteCampaign(id: number) {
    return this.http.get(this.ServerUrl + 'deleteCampaign/' + id).pipe(
      catchError(this.handleError)
    );
  }
  changeCampaignstatus(id: any,status: number) {
    return this.http.get(this.ServerUrl + 'changeCampaignstatus/' + id +'/'+ status).pipe(
      catchError(this.handleError)
    );
  }

  getcampainbyuuid(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getcampainbyuuid/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  
  
  getallcampain(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getallcampain/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  
  
  getallcampaindata(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getallcampaindata/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  

  getallruleset(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getallruleset/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  
  
  getallparty(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getallparty/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  

  getallbuyers(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getallbuyers/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  
  updateCampaign(uuid:string,company_uuid:string,formData) {
    return this.http.post<any>(this.ServerUrl + 'updateCampaign/'+uuid+'/'+company_uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  addpublisherpayout(uuid: string, formData) {
    return this.http.post<any>(this.ServerUrl + 'addpublisherpayout/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  addpublishertocampaign(uuid: string, formData) {
    return this.http.post<any>(this.ServerUrl + 'addpublishertocampaign/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  editRoutingSubmit(uuid: string, formData) {
    return this.http.post<any>(this.ServerUrl + 'editRoutingSubmit/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  editIVRRoutingSubmit(uuid: string, formData) {
    return this.http.post<any>(this.ServerUrl + 'editIVRRoutingSubmit/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  setcampaignRouting(uuid: string,campaign_uuid: string,user_uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'setcampaignRouting/'+ campaign_uuid + '/' + user_uuid,uuid)
    .pipe(
      catchError(this.handleError)
    );
  }
 
  setivrRouting(uuid: string,name: string,user_uuid: string,company_uuid: string) {
    return this.http.post(this.ServerUrl + 'setivrRouting/'+ user_uuid+ '/' + uuid + '/' +company_uuid,name)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  getplanbyuuid(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getplanbyuuid/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  

  addIVRPlan(user_uuid: string,company_uuid: string,formdata:any) {
    return this.http.post<any>(this.ServerUrl + 'addIVRPlan/'+ company_uuid + '/' + user_uuid,formdata)
    .pipe(
      catchError(this.handleError)
    );
  }

  editIVRPlan(user_uuid: string,uuid: string,formdata:any) {
    return this.http.post<any>(this.ServerUrl + 'editIVRPlan/'+ uuid + '/' + user_uuid,formdata)
    .pipe(
      catchError(this.handleError)
    );
  }

  getpayoutdata(uuid: string,phone: string) {
    return this.http.post<any>(this.ServerUrl + 'getpayoutdata/'+ uuid,phone).pipe(
      catchError(this.handleError)
    );
  }  

  getpayoutbynumberuuid(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getpayoutbynumberuuid/'+ uuid).pipe(
      catchError(this.handleError)
    );
  }  
  deleteRoutingPlan(uuid:string,user_uuid:string) {
    return this.http.get(this.ServerUrl + 'deleteRoutingPlan/'+uuid+'/'+user_uuid).pipe(
      catchError(this.handleError)
    );
  } 
  deleteIVRRoutingPlan(uuid:string,user_uuid:string) {
    return this.http.get(this.ServerUrl + 'deleteIVRRoutingPlan/'+uuid+'/'+user_uuid).pipe(
      catchError(this.handleError)
    );
  } 
  deletePublisherPayout(uuid:string) {
    return this.http.get(this.ServerUrl + 'deletePublisherPayout/'+uuid).pipe(
      catchError(this.handleError)
    );
  } 
  

  // Report
  savereport(formData) {
    return this.http.post<any>(this.ServerUrl + 'savereport/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  getAllsavedreport(company_uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'getAllsavedreport/',company_uuid).pipe(
      catchError(this.handleError)
    );
  }  

  getReportdetailsdata(row_uuid: string,company_uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'getReportdetailsdata/'+row_uuid,company_uuid).pipe(
      catchError(this.handleError)
    );
  }  
  
  getsavedreportsbyuuid(uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'getsavedreportsbyuuid/',uuid).pipe(
      catchError(this.handleError)
    );
  }  

  deleteSelectedSavedReport(uuid:string) {
    return this.http.post(this.ServerUrl + 'deleteSelectedSavedReport/',uuid).pipe(
      catchError(this.handleError)
    );
  } 

  // Number Module Start
  getnumberAssciatedlabels(company_uuid: string,number: string) {
    return this.http.post<any>(this.ServerUrl + 'getnumberAssciatedlabels/' + company_uuid,number).pipe(
      catchError(this.handleError)
    );
  }  
 
  getTargetdata(uuid: string,company_uuid: string) {
    return this.http.get<Number>(this.ServerUrl + 'getTargetbyid/' + uuid + '/' +company_uuid).pipe(
      catchError(this.handleError)
    );
  }  

  getNumberForExport(company_uuid: string,labelname:string) {
    return this.http.post<any>(this.ServerUrl + 'getNumberForExport/'+company_uuid,labelname).pipe(
      catchError(this.handleError)
    );
  }  


  changeTargetStatus(activestatus:number,currentUseruuid: string, targetuuid: string) {
    return this.http.post<any>(this.ServerUrl + 'changeTargetStatus/' + activestatus + '/' +targetuuid, currentUseruuid)
    .pipe(
      catchError(this.handleError)
    );
  }
  
  updateTarget(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'updateTarget/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  addTarget(formData) {
    return this.http.post<any>(this.ServerUrl + 'addTarget/', formData)
    .pipe(
      catchError(this.handleError)
    );
  } 
  
  deleteSelectedNumbers(lblarr) {
    return this.http.post(this.ServerUrl + 'deleteSelectedNumbers/',lblarr).pipe(
      catchError(this.handleError)
    );
  } 
  
  importNumber(formData) {
    return this.http.post<any>(this.ServerUrl + 'importNumber/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }  
 
  batcheditnos(formData) {
    return this.http.post<any>(this.ServerUrl + 'batcheditnos/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }  
  addFSLRules(formData,uid: string) {
    return this.http.post<any>(this.ServerUrl + 'addFSLRules/'+uid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }  
  
  addWeekdays(formData,uid: string) {
    return this.http.post<any>(this.ServerUrl + 'addWeekdays/'+uid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }  

  deleteWeekday(uuid: string) {
    return this.http.get(this.ServerUrl + 'deleteWeekday/' + uuid).pipe(
      catchError(this.handleError)
    );
  }

  editWeekdays(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'editWeekdays/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  loadallweekdays(company_uuid:string) {
    return this.http.get<any>(this.ServerUrl + 'loadallweekdays/'+company_uuid).pipe(
      catchError(this.handleError)
    );
  } 

  // 

  getRecentRules(FSLRulesInsertID,company_uuid:string) {
    return this.http.post<Number>(this.ServerUrl + 'getRecentRules/'+company_uuid,FSLRulesInsertID).pipe(
      catchError(this.handleError)
    );
  }

  getRulesByName(name,company_uuid:string) {
    return this.http.post<Number>(this.ServerUrl + 'getRecentRules/'+company_uuid,{ name: name}).pipe(
      catchError(this.handleError)
    );
  } 
  
  getRulesByTargetuuid(target_uuid,company_uuid:string) {
    return this.http.post<Number>(this.ServerUrl + 'getRulesByTargetuuid/'+company_uuid,{ target_uuid: target_uuid}).pipe(
      catchError(this.handleError)
    );
  } 
  
  getAllRules(company_uuid:string) {
    return this.http.get<any>(this.ServerUrl + 'getAllRules/'+company_uuid).pipe(
      catchError(this.handleError)
    );
  } 

  deleteNumber(id: string,user_uuid: string) {
    return this.http.get(this.ServerUrl + 'deleteNumber/'+id+'/'+user_uuid).pipe(
      catchError(this.handleError)
    );
  }

  deleteFSLRules(uuid: string) {
    return this.http.get(this.ServerUrl + 'deleteFSLRules/' + uuid).pipe(
      catchError(this.handleError)
    );
  }
  deleteFSLRulesbyname(name: string) {
    return this.http.get(this.ServerUrl + 'deleteFSLRulesbyname/' + name).pipe(
      catchError(this.handleError)
    );
  }

  getFSLbyuuid(uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'getFSLbyuuid/', uuid).pipe(
      catchError(this.handleError)
    );
  }


  getselectednumbers(formData) {
    // console.log(uploadfile);return false;
    return this.http.post<Number>(this.ServerUrl + 'getselectednumbers/',formData).pipe(
      catchError(this.handleError)
    );
  }
  
  numberbatchedit(formData) {
    // console.log(uploadfile);return false;
    return this.http.post<any>(this.ServerUrl + 'numberbatchedit/',formData).pipe(
      catchError(this.handleError)
    );
  }
  
  editFSLRules(formData,uid: string) {
    return this.http.post<any>(this.ServerUrl + 'editFSLRules/'+uid, formData)
    .pipe(
      catchError(this.handleError)
    );
  } 

  // FSL Rule Name Validation
  validatename(name) {
    // alert(name);
    return this.http.post<any>(this.ServerUrl + 'validatename/',{name:name})
    .pipe(
      catchError(this.handleError)
    );
  }

  // Label Module Start

  addLabel(formData) {
    return this.http.post<any>(this.ServerUrl + 'addLabel/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }  

  deleteLabel(uuid: any,usert_uuid: any) {
    // alert(uuid);
    return this.http.get(this.ServerUrl + 'deleteLabel/' + uuid +'/'+ usert_uuid).pipe(
      catchError(this.handleError)
    );
  }

  deleteSelectedLabels(lblarr) {
    return this.http.post(this.ServerUrl + 'deleteSelectedLabels/',lblarr).pipe(
      catchError(this.handleError)
    );
  }

  editLabel(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'editLabel/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }
  getAllLabels(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getAllLabels/'+company_uuid).pipe(
      catchError(this.handleError)
    );
  }
  
  getLabelForExport(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getLabelForExport/'+company_uuid)
    .pipe(
      catchError(this.handleError)
    );
  }  


  validateLabelName(name) {
    // alert(name);
    return this.http.post<any>(this.ServerUrl + 'validateLabelName/',{name:name})
    .pipe(
      catchError(this.handleError)
    );
  }
  
  validateTargetName(name) {
    // alert(name);
    return this.http.post<any>(this.ServerUrl + 'validateTargetName/',{name:name})
    .pipe(
      catchError(this.handleError)
    );
  }
  
  validateCampaignName(name) {
    // alert(name);
    return this.http.post<any>(this.ServerUrl + 'validateCampaignName/',{name:name})
    .pipe(
      catchError(this.handleError)
    );
  }
  
  // Notes Module Start

  addNotes(formData, company_uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'addNotes/' + company_uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }
  getNotesdata(company_uuid: string) {
    return this.http.get<Number>(this.ServerUrl + 'getNotesdata/' + company_uuid).pipe(
      catchError(this.handleError)
    );
  }  

  // IVR Menu

  deleteIVRMenu(uuid: number,user_uuid:string) {
    return this.http.get(this.ServerUrl + 'deleteIVRMenu/' + uuid + '/' + user_uuid).pipe(
      catchError(this.handleError)
    );
  }

  deleteSelectedIVR(arr) {
    return this.http.post(this.ServerUrl + 'deleteSelectedIVR/',arr).pipe(
      catchError(this.handleError)
    );
  }

  getIVRMenus(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getIVRMenuname/'+company_uuid).pipe(
      catchError(this.handleError)
    );
  } 

  getIVRAudio(company_uuid: string,type:any) {
    return this.http.get<Number>(this.ServerUrl + 'getIVRAudio/'+company_uuid+'/'+type).pipe(
      catchError(this.handleError)
    );
  } 

  addIVR(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'addIVR/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }  

  getivrdata(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getivrbyuuid/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  

  // Rule Set
  addRuleSet(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'addRuleSet/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }  
  getruledata(uuid: string,company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getruledata/' + uuid + '/' +company_uuid).pipe(
      catchError(this.handleError)
    );
  }  

  editRuleSet(formData, company_uuid: string, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'editRuleSet/'+company_uuid+'/'+uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  deleteRuleset(uuid: string) {
    return this.http.get(this.ServerUrl + 'deleteRuleset/' + uuid).pipe(
      catchError(this.handleError)
    );
  }


   // Dynamic Parameter
   addDyPara(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'addDyPara/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  } 

  getDynParadata(uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getDynParadata/' + uuid).pipe(
      catchError(this.handleError)
    );
  }  

  // Verification Management
  getRules(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getRulesName/' + company_uuid).pipe(
      catchError(this.handleError)
    );
  } 

  GetIVRnamedata(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'GetIVRnamedata/' + company_uuid).pipe(
      catchError(this.handleError)
    );
  }

  GetEmails(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'GetEmails/' + company_uuid).pipe(
      catchError(this.handleError)
    );
  } 

  addEmail(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'addEmail/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }  
  addWildcard(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'addWildcard/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }  
  getWildcard(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getWildcard/' + company_uuid).pipe(
      catchError(this.handleError)
    );
  } 

  addVerificationRuleSet(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'addVerificationRuleSet/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  getverificationruledata(company_uuid: string) {
    return this.http.get<any>(this.ServerUrl + 'getverificationruledata/' +company_uuid).pipe(
      catchError(this.handleError)
    );
  }

  editWildcard(formData, company_uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'editWildcard/'+company_uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }
  editEmail(formData, company_uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'editEmail/'+company_uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }


  uplaoddata:any;
  resp:any;
  FOLDER = 'ivr-audio/';
  Obj = this; 
  uploadfile(file,uuid:any,type:any) {
    

    const bucket = new S3(
        {
          // accessKeyId: 'AKIAQFDXMD75FPYC76NN',
          // secretAccessKey: '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',
          accessKeyId: 'AKIA2FWTMXUGBA3Z5VYM',
          secretAccessKey: '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',
          region: 'us-west-2'
        }
      );

      const params = {
          Bucket: 'didbizrecordingnew',
          Key: this.FOLDER + file.name,
          Body: file,
          ACL: '',         
      };

      // let headers = new HttpHeaders();
      // headers = headers.set('Content-Type', 'application/json; charset=utf-8');
     
      const formData: FormData = new FormData();
      formData.append('fileKey',file.name);
      formData.append('Bucket','didbizrecording');
      formData.append('ETag','0c481e87f2774b1bd41a0a70d9b70d11');
      formData.append('Key','ivr-audio/'+file.name);
      formData.append('type',file.type);      
      formData.append('Folder','ivr-audio/');
      formData.append('Location','https://d2pktjhs7fqsvz.cloudfront.net/ivr-audio/'+file.name);
    
       return this.http.post<any>(this.ServerUrl + 'add_audio/'+ uuid + '/' + type, formData, {
          reportProgress: true,
          observe: 'events',
          // headers: headers
        }).pipe(map((event) => {
          // console.log(event); return false;

          bucket.upload(params, function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });
          
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round(100 * event.loaded / event.total);
              // console.log(progress);
              this.resp = { status: 'progress', message: progress };
              break;
            case HttpEventType.Response:
                this.resp = event;
              break;
            default:
                this.resp = `Unhandled event: ${event.type}`;
              break;
            
          }
          return this.resp;
        })
        );

     
}
  
 // add_audio(file){
//   // console.log(file);return false;
//     return this.http.post<any>(this.ServerUrl + 'add_audio/', file)
//     .pipe(
//       catchError(this.handleError)
//     );
// }

add_audio(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post<any>(this.ServerUrl + 'add_audio/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  updateIVR(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'updateIVR/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  // White and Black List Numbers
  deleteListNumber(uuid: string,type:number) {
    return this.http.get(this.ServerUrl + 'deleteListNumber/' + uuid+ '/' + type).pipe(
      catchError(this.handleError)
    );
  }
  
  deletewbSelectedNumbers(lblarr,type:number) {
    return this.http.post(this.ServerUrl + 'deletewbSelectedNumbers/'+type,lblarr).pipe(
      catchError(this.handleError)
    );
  }

  addNumberlist(formData) {
    return this.http.post<any>(this.ServerUrl + 'addNumberlist/', formData)
    .pipe(
      catchError(this.handleError)
    );
  } 
  
  editNumberList(formData, uuid: string) {
    return this.http.post<any>(this.ServerUrl + 'editNumberList/' + uuid, formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  // Audible Ring

  deleteAudibleRingNumber(uuid: string) {
    return this.http.get(this.ServerUrl + 'deleteAudibleRingNumber/' + uuid).pipe(
      catchError(this.handleError)
    );
  }

  getallnumbers(company_uuid: string) {
    return this.http.get<Number>(this.ServerUrl + 'getallnumbers/' + company_uuid).pipe(
      catchError(this.handleError)
    );
  }  

  addExceptionNumber(formData) {
    return this.http.post<any>(this.ServerUrl + 'addExceptionNumber/', formData)
    .pipe(
      catchError(this.handleError)
    );
  }  

 // Reports
 getCallsForExport(company_uuid: string) {
  return this.http.get<any>(this.ServerUrl + 'getCallsForExport/'+company_uuid)
  .pipe(
    catchError(this.handleError)
  );
}  

 getCalldetailsForExport(company_uuid: string,formData) {
  return this.http.post<any>(this.ServerUrl + 'getCalldetailsForExport/'+company_uuid, formData)
  .pipe(
    catchError(this.handleError)
  );
}  

 
getCallReports(formdata:any,company_uuid: string) {
  return this.http.post<any>(this.ServerUrl + 'getCallReports/'+company_uuid,formdata)
  .pipe(
    catchError(this.handleError)
  );
}  

getLabelReports(formdata:any,company_uuid: string) {
  return this.http.post<any>(this.ServerUrl + 'getLabelReports/'+company_uuid,formdata)
  .pipe(
    catchError(this.handleError)
  );
}  
 
// SMS
getsmsForExport(company_uuid: string) {
  return this.http.get<any>(this.ServerUrl + 'getsmsForExport/'+company_uuid)
  .pipe(
    catchError(this.handleError)
  );
}  


// NumberModule
getAllpublishers(company_uuid: string) {
  return this.http.get<Number>(this.ServerUrl + 'getAllpublishers/' +company_uuid).pipe(
    catchError(this.handleError)
  );
}  

getAllpublisherssetting(company_uuid: string) {
  return this.http.get<Number>(this.ServerUrl + 'getAllpublisherssetting/' +company_uuid).pipe(
    catchError(this.handleError)
  );
}  
  

addPublisherSettingdata(formData) {
  return this.http.post<any>(this.ServerUrl + 'addPublisherSettingdata/', formData)
  .pipe(
    catchError(this.handleError)
  );
}

purchase_number(formData) {
  return this.http.post<any>(this.ServerUrl + 'purchase_number/', formData)
  .pipe(
    catchError(this.handleError)
  );
}

// Number pool
addNumberpooldata(formData) {
  return this.http.post<any>(this.ServerUrl + 'addNumberpooldata/', formData)
  .pipe(
    catchError(this.handleError)
  );
}

deleteNumberpooldata(uuid: string,user_uuid: string) {
  return this.http.get(this.ServerUrl + 'deleteNumberpooldata/' + uuid +'/'+user_uuid).pipe(
    catchError(this.handleError)
  );
}

editNumberpooldata(formData) {
  return this.http.post<any>(this.ServerUrl + 'editNumberpooldata/', formData)
  .pipe(
    catchError(this.handleError)
  );
}


// Basic Settings
getAllbasicsetting(company_uuid: string) {
  return this.http.get<Number>(this.ServerUrl + 'getAllbasicsetting/' +company_uuid).pipe(
    catchError(this.handleError)
  );
}
addBasicSettingdata(formData) {
  return this.http.post<any>(this.ServerUrl + 'addBasicSettingdata/', formData)
  .pipe(
    catchError(this.handleError)
  );
}

addPublisherRegistration(formData) {
  return this.http.post<any>(this.ServerUrl + 'addPublisherRegistration/', formData)
  .pipe(
    catchError(this.handleError)
  );
}

verifyEmail(type,uuid) {
  return this.http.post<any>(this.ServerUrl + 'verifyEmail/'+type, uuid)
  .pipe(
    catchError(this.handleError)
  );
}



addNumberdata(formData) {
  return this.http.post<any>(this.ServerUrl + 'addNumberdata/', formData)
  .pipe(
    catchError(this.handleError)
  );
}

deleteNumberdata(id: any,user_uuid:string) {
  // alert(id);
  return this.http.get<any>(this.ServerUrl + 'deleteNumberdata/' + id+ '/' +user_uuid).pipe(
    catchError(this.handleError)
  );
}

getAlltarget(company_uuid: string) {
  return this.http.get<any>(this.ServerUrl + 'getAlltarget/' +company_uuid).pipe(
    catchError(this.handleError)
  );
}  

allivrroutingplan(company_uuid: string) {
  return this.http.get<any>(this.ServerUrl + 'allivrroutingplan/' +company_uuid).pipe(
    catchError(this.handleError)
  );
}  

getcitystatevalue(uuid: string,citystatetypeval: string) {
  return this.http.post<any>(this.ServerUrl + 'getcitystatevalue/' + uuid,citystatetypeval).pipe(
    catchError(this.handleError)
  );
}  

loadcitystate(type: string,datatype: string) {
  return this.http.post<any>(this.ServerUrl + 'loadcitystate/'+datatype,type).pipe(
    catchError(this.handleError)
  );
}  
loadcity(type: string) {
  return this.http.post<any>(this.ServerUrl + 'loadcity/',type).pipe(
    catchError(this.handleError)
  );
}  

// Blocked Numbers
deleteblckedno(uuid: any) {
  // alert(uuid);
  return this.http.get(this.ServerUrl + 'deleteblckedno/' + uuid).pipe(
    catchError(this.handleError)
  );
}

addblockedcalls(formData,company_uuid:any) {
  return this.http.post<any>(this.ServerUrl + 'addblockedcalls/'+company_uuid, formData)
  .pipe(
    catchError(this.handleError)
  );
} 

editblockednumbers(formData, uuid: string) {
  return this.http.post<any>(this.ServerUrl + 'editblockednumbers/' + uuid, formData)
  .pipe(
    catchError(this.handleError)
  );
}  

// Add User
addUser(formData) {
  return this.http.post<any>(this.ServerUrl + 'addUser/', formData)
  .pipe(
    catchError(this.handleError)
  );
}

deleteUser(id: number,user_uuid:string) {
  return this.http.get(this.ServerUrl + 'deleteUser/' + id +'/'+user_uuid).pipe(
    catchError(this.handleError)
  );
}


// Company Management
addCompany(formData) {
  return this.http.post<any>(this.ServerUrl + 'addCompany/', formData)
  .pipe(
    catchError(this.handleError)
  );
}

editCompany(formData,uuid:any) {
  return this.http.post<any>(this.ServerUrl + 'updateCompany/'+uuid, formData)
  .pipe(
    catchError(this.handleError)
  );
}

deleteCompany(id: number) {
  return this.http.get(this.ServerUrl + 'deleteCompany/' + id).pipe(
    catchError(this.handleError)
  );
}


getTagetdetailsdata(row_uuid: string,company_uuid: string) {
  return this.http.post<any>(this.ServerUrl + 'getTagetdetailsdata/'+row_uuid,company_uuid).pipe(
    catchError(this.handleError)
  );
}  


// Get Timezone
gettimezone() {
  return this.http.get<any>(this.ServerUrl + 'gettimezone/').pipe(
    catchError(this.handleError)
  );
}  

// SIP User
addSIPUser(formData) {
  return this.http.post<any>(this.ServerUrl + 'addSIPUser/', formData)
  .pipe(
    catchError(this.handleError)
  );
} 

deleteSIPUser(id: string,user_uuid: string) {
  return this.http.get(this.ServerUrl + 'deleteSIPUser/'+id+'/'+user_uuid).pipe(
    catchError(this.handleError)
  );
}

changeSIPUserStatus(activestatus:number,currentUseruuid: string, targetuuid: string) {
  return this.http.post<any>(this.ServerUrl + 'changeSIPUserStatus/' + activestatus + '/' +targetuuid, currentUseruuid)
  .pipe(
    catchError(this.handleError)
  );
}

getsipuserdata(uuid: string) {
  return this.http.post<any>(this.ServerUrl + 'getsipuserdata/',uuid).pipe(
    catchError(this.handleError)
  );
}  

updateSIPUser(formData,uuid: string) {
  return this.http.post<any>(this.ServerUrl + 'updateSIPUser/'+uuid,formData).pipe(
    catchError(this.handleError)
  );
}  

getChartbyfilterforcomparison(company_uuid: string,chartarg:any,daytype:any,timezone:any) {
  // if(val==''){
  //   val = 0;
  // }
  return this.http.post<any>(this.ServerUrl + 'getChartbyfilterforcomparison/'+company_uuid+'/'+daytype+'/'+timezone+'/',chartarg).pipe(
    catchError(this.handleError)
  );
} 


  // Error Handler Start  
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
  // Error Handler End
}
