import { FormGroup } from '@angular/forms';
import { ModuleService } from './../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})

export class NumberComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  public SiteUrl = GlobalVariable.BASE_URL;

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  number: Number;
  action: any;
  error: {};
  showLoadingIndicator: any;
  dtOptions: DataTables.Settings = {};
  numbers: any;
  that: Number;
  private http: HttpClient;
  currentCompany: any;
  ischecked: string = '1';

  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend
  ){ 
    this.http = new HttpClient(handler);
  }

  ngOnInit() {
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.loadTargets();
  }
  loadTargets(){
        // console.log('ok');
        // return false;
          if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
            $(this.table.nativeElement).dataTable().fnDestroy();
          }    
          this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
         this.dtOptions = {
            "pagingType": 'full_numbers',
            "processing": true,
            "serverSide": true,          
            "pageLength": 20,            
            "order": [[ 1, "asc" ]],
            ajax: (dataTablesParameters: any, callback) => {
              this.http.post<DataTablesResponse>(
                this.ServerUrl+'gettargets/'+this.currentCompany.uuid,
                dataTablesParameters, {}
              ).subscribe(resp => {
                        this.numbers = resp.data;   
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: [],
                        });
              });
            },
            columns: [
              {data:"details"},{data:"checkall"},{data:"name"},{data:"subid"},{data:"valtype"},{data:"typeval"},{data:"created_at"},{data:"active_status"},{data:"action"},
            ],
            columnDefs: [
              { 
                  "targets": [0], 
                  "orderable": false,
              },
              ],
        };
    
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.dataTable(this.dtOptions);
  }

  checkalldelete(event) {
    const checked = event.target.checked;
    this.numbers.forEach(chkbox => chkbox.selected = checked);    
  }

  checkboxes:any = [];  
  deleteselected(event:any){ 
    // $("#customCheck1").prop("checked", false);    
    if(event.target.checked==true)
    {
      if(event.target.value=='on'){
        this.checkboxes = [];
        this.numbers.forEach(lbl => {
          this.checkboxes.push(lbl.id);
        });
      }else{ 
        if(this.checkboxes.length+1==this.numbers.length){
          $( "#customCheck1" ).prop( "checked", true );
        }
        this.checkboxes.push(event.target.value);            }            
        this.ischecked = '0';
    }else{ 
      // console.log(this.checkboxes);      
      if(this.checkboxes.length!=0){
        $( "#customCheck1" ).prop( "checked", false );        
      }
      if(event.target.value!='on'){
        const index = this.checkboxes.indexOf(event.target.value);
        this.checkboxes.splice(index, 1);
      }else{
        this.checkboxes = [];
      }
      this.ischecked = '1';
    }
    // console.log(this.checkboxes); 
  }

  onDeleteSubmit(){    
    if(this.checkboxes.length==0){
      this.toastrService.error('Please Select Row To Delete');
    }else{
      // console.log(this.checkboxes);  
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Selected Record!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {

          this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
            res => {
                Swal.fire(
                  'Deleted!',
                  'Records Deleted Successfully.',
                  'success',                
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                this.loadTargets();
              },
              error => this.error = error
          );      
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
    
  }

  Delete(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.moduleservice.deleteNumber(id,currentUser.uuid).subscribe(
            res => {
              this.loadTargets();
              // this.toastrService.success('Number Deleted Successfully');
              Swal.fire(
                'Deleted!',
                'Record Deleted Successfully.',
                'success',                
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
              
              
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }


  exportdata(){
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      // showTitle: true,
      // title: 'Your title',
      useBom: true,
      // noDownload: true,
      headers: ["Number", "FSL Rule Name", "Rule Set","Note","Associated abel","Created At"]
    };

    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getNumberForExport(this.currentCompany.uuid,"all").subscribe(
      res => {
        console.log(res);
        new   ngxCsv(res, 'All Targets', options);
      },
      error => this.error = error
  );     

  }

  status:boolean;
  change_status(event:any,tagetUuid:any){
    this.status = event.target.checked;
    let activestatus, activestring;
    if(this.status == true){
      activestatus = 1;
      activestring = "Activate";
    }else{
      activestatus = 0;
      activestring = "De-activate";
    }
    // alert(activestatus); return
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to '+activestring+' Target!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, '+activestring+' it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.moduleservice.changeTargetStatus(activestatus,currentUser.uuid,tagetUuid).subscribe(
            res => {
              this.loadTargets();
              // this.toastrService.success('Number Deleted Successfully');
              Swal.fire(
                'Changed!',
                'Target '+activestring+' Successfully.',
                'success',                
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
              
              
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
        this.loadTargets();
        return;
      }
    });
  }  

  details(i,data){

    var tr = $('td.details-control').closest('tr').eq(i);
    var divClass = $(".detailrow_"+i).eq(0).attr('class'); 
    if (divClass.indexOf('shown') > -1) {
        $(".appendedtbl_"+i).remove();
          $(".detailrow_"+i).removeClass('shown');
      }else {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.moduleservice.getTagetdetailsdata(data.uuid,currentUser.uuid).subscribe(
          res => {
            var SiteUrl = this.SiteUrl;             
            var campaigns ='';  
            var campaignsdata ='';  
            if(res.data.length>0){
            for (let index = 0; index < res.data.length; index++) {
              const data =  res.data[index];
              // console.log(data.name);
              var count = index+1;
              campaignsdata +='<div class="activity">'+
                      '<div class="activity-icon bg-blue text-white">'+
                      '<i class="fas fa-info"></i>'+
                    '</div>'+
                    '<div class="activity-detail">'+
                      '<div class="mb-2">'+
                        '<span class="text-job text-primary">Assigned Campaign - '+count+'</span>'+'</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Campaign Name</div>'+
                          '<div class="col-md-3"><a class="expandlink" style="color:white;text-decoration:underline" target="_blank" href="'+SiteUrl+'/edit-campaign/'+data.uuid+'">'+data.name+'</a></div>'+
                        '</div>'+                           
                        '<div class="row">'+
                          '<div class="col-md-1">Country</div>'+
                          '<div class="col-md-3">'+
                          
                          data.country+'</div>'+
                        '</div>'+                           
                    '</div>'+
                  '</div>';
            }
            }else{
              campaignsdata +='<div class="activity">'+
                            '<div class="activity-icon bg-blue text-white">'+
                            '<i class="fas fa-info"></i>'+
                          '</div>'+
                          '<div class="activity-detail">'+
                            '<div class="mb-2">'+
                              '<span class="text-job text-primary">No Campaign Assigned</span>'+'</div>'+                              
                          '</div>'+
                        '</div>';


                        
            }
            campaigns += campaigns.concat(campaignsdata); 
            // console.log(campaigns);            

        $(".detailrow_"+i).after(
          '<div class="appendedtbl_'+i+'" style="width: 900%!important;padding-top: 10px;border-top: 1px solid #424a67;">'+
          // '<ul class="nav nav-tabs expandtabs">'+
          //   '<li id="eventtgl" style="padding: 6px 6px 0px 5px;border-radius: 3px;border: 1px solid #747e9a;margin-right: 2px;    margin-bottom: 3px;" class="active"><a class="active" data-toggle="tab" href="#home_'+i+'">Event</a></li>'+
            // '<li style="padding: 6px 6px 0px 5px;border-radius: 3px;border: 1px solid #747e9a;margin-right: 2px;    margin-bottom: 3px;"><a  data-toggle="tab" href="#menu_'+i+'">Record</a></li>'+
            // '<li style="padding: 6px 6px 0px 5px;border-radius: 3px;border: 1px solid #747e9a;margin-right: 2px;    margin-bottom: 3px;"><a data-toggle="tab" href="#tag_'+i+'">Tag</a></li>'+
          // '</ul>'+
        
          '<div class="tab-content" style="width: 241%">'+
            '<div id="home_'+i+'" class="tab-pane fade in active show">'+
              '<section class="section" style="width: 0px;">'+
              '<div class="section-body" style="width: 794%;">'+
                '<div class="row">'+
                  '<div class="col-12">'+
                    '<div class="activities">'+
                    // '<div class="activity">'+
                    //   '<div class="activity-icon bg-blue text-white">'+
                    //     '<i class="fas fa-info"></i>'+
                    //   '</div>'+
                    //   '<div class="activity-detail">'+
                    //     '<div class="mb-2">'+
                    //       '<span class="text-job text-primary">Assigned Campaigns</span>'+'</div>'+
                    //       '<div class="row">'+
                    //         '<div class="col-md-1">Campaign Name</div>'+
                    //         '<div class="col-md-3">'+name+'</div>'+
                    //       '</div>'+                           
                    //       '<div class="row">'+
                    //         '<div class="col-md-1">Country</div>'+
                    //         '<div class="col-md-3">'+
                            
                    //         country+'</div>'+
                    //       '</div>'+                           
                    //   '</div>'+
                    // '</div>'+

                    campaigns+
                  
                      
                    '</span>'+
                  '</div>'+
                '</div>'+
              '</div>'+
            '</section> '+
            '</div>'+

        '</div>'+               
        '</div>'               
          );
          tr.addClass('shown');
        },
        error => this.error = error
    );   
      }
      
}
}