import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component,ViewEncapsulation, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router,ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {RequestOptions } from '@angular/http';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import "datatables.net";
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Chart } from 'angular-highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
import 'moment-timezone';
HC_exporting(Highcharts);
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import 'rxjs/add/observable/interval';

import { DataTableDirective } from 'angular-datatables';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  totalarr:any[]
}

class filterarr
{
  filtertype: number;
  isorand: number;
  uuid: string;
  name: string;
  isdup: Number;
  duration: Number;
}


@Component({
  selector: 'app-report-bing',
  templateUrl: './report-bing.component.html',
  styleUrls: ['./report-bing.component.scss']
})
export class ReportBingComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
   
    labelval: any;
    selected: any;
    numbers: any = '';
    alwaysShowCalendars: boolean;
    showRangeLabelOnInput: boolean;
    keepCalendarOpeningWithRange: boolean;
    // maxDate: moment.Moment;
    // minDate: moment.Moment;
   
    // @ViewChild('dataTable', {static: true}) table: ElementRef;
    @ViewChild('dataTable1', {static: true}) table1: ElementRef;
    @ViewChild('myTable') table: any;
  
    dataTable: any;
    dataTable1: any;
    currentUser: any;
    currentCompany: any;
    label: Number;
    error: {};
    showLoadingIndicator: any;
    LoadingIndicatorlivecall: any;
    dtOptions: DataTables.Settings = {
     
    };
    dtTrigger: Subject<any> = new Subject<any>();
    dtOptionsdetails: DataTables.Settings = {};
    labels: any = 0; 
    callsdata: any; 
    private http: HttpClient;
    searchcallsform: FormGroup;
    submitted = false;
    event:any="";
    expanded: any = {};
    timeout: any;
    rows: any[] = [];
    selectednewdates:any;
    // subscriptions : Subscription[];
    
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private el: ElementRef,private ngxLoader: NgxUiLoaderService,
      private cdRef:ChangeDetectorRef
    ){ 
      $("#summarytbl").dataTable().fnDestroy();
      this.dtOptions = {
        "order": [],
        paging:false,
        searching:false,
        info : false,
        
      }
      
      this.http = new HttpClient(handler);
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.alwaysShowCalendars = true;
      this.keepCalendarOpeningWithRange = true;
      this.showRangeLabelOnInput = true;
      this.subscription = Observable.interval(15000)
      .subscribe((val) => { this.loadlivecalls(); });
      
      // var moment = require('moment-timezone');
      // moment().tz("America/Los_Angeles").format();
      // var converted = moment.tz(moment(), 'utc').format("YYYY-MM-DD HH:mm:ss");         
      // console.log(converted);
      // var full_format = "YYYY-MM-DD HH:mm:ss";
      // var start = converted + " 00:00:00";
      // var end = converted + " 23:59:59";
      // var utc_start_time = moment(start).add(-25200 * -1, 'hours').format(full_format);
      // var utc_end_time = moment(end).add(-25200 * -1, 'hours').format(full_format); 
      
      // this.selected = {startDate: moment().startOf('day').format("MM/DD/YYYY"), endDate: moment().endOf('day').format("MM/DD/YYYY")};    
      this.selected = {startDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY"), endDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY")};    
      // console.log(this.selected);
      // return
      // console.log(moment().startOf('day'));    `
      // console.log(moment.tz(new Date(), "PST").format("MM/DD/YYYY"));
  
      this.selected1 = this.selected;  
      
     
      // console.log(this.selected);
      // for the current day
      // var start = moment.tz("GMT").startOf('day');
      // var end = moment.tz(timezone).endOf('day').utc();
  
      // for a specific moment (m)
      // var start = m.clone().tz(timezone).startOf('day').utc();
      // var end = m.clone().tz(timezone).endOf('day').utc();
  
      // // for a specific calendar date
      // var m = moment.tz(date, format, timezone);
      // var start = m.clone().startOf('day').utc();
      // var end = m.clone().endOf('day').utc();
      
    }
  
  
    onPage(event) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        console.log('paged!', event);
      }, 100);
    }
    // @ViewChild('table123', {static: true}) table: any;
    // toggleExpandRow(row) {
    //   console.log('Toggled Expand Row!', row);
    //   this.table.rowDetail.toggleExpandRow(row);
    // }
  
    toggleExpandRow(rowIndex,row) {
      this.loadingIndicator = true;
      this.table.rowDetail.toggleExpandRow(row);    
      this.details(rowIndex,row);
    }
    toggleCollaspeRow(row) {
      this.table.rowDetail.collapseAllRows();
    }
  
      
    sendMenuUpdateMessage(): void {
      // send message to subscribers via observable subject
        this.moduleservice.sendMenuUpdateMessage('1');
    }
  
    ranges: any = {
      Today: [ moment.tz(moment(), "America/New_York").format("MM/DD/YYYY"),  moment.tz(moment(), "America/New_York").format("MM/DD/YYYY")],
      Yesterday: [moment.tz(moment().subtract(1, 'days'), "America/New_York").format("MM/DD/YYYY"),  moment.tz(moment().subtract(1, 'days'), "America/New_York").format("MM/DD/YYYY")],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
       moment()
        .subtract(1, 'month')
        .startOf('month')
        ,
       moment()
        .subtract(1, 'month')
        .endOf('month')
      ],   
    };
   
    ngxclass="dark";
    subscriptions : Subscription[];
    subscription: Subscription;
    intervalId: number;
    ismobile: number = 0;
    ngOnInit() {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      const uuid = this.route.snapshot.paramMap.get('id');
      // console.log(uuid);
      if(uuid!=null){      
        this.getsavedreports(uuid);
      }else{
        this.loadcalls("","");
        this.loadcallsdata("","",1);
        this.getChartNumberOfCalls(0,0,1);
        // this.getsavedreports();
      }
      if($("body").hasClass("theme-white")) {
        this.ngxclass='material';
      }
  
      // const source = interval(15000);
      // this.subscription = source.subscribe(val => this.loadlivecalls());    
      // this.subscriptions.push(this.subscription);
  
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        // console.log('is mobile.....');
        this.ismobile = 1;
      }
   
    
    }
  
    chart:any;
    date1:string;
    chartarg:any;
    bgcolorcode:any; 
    colorcode:any='#fff';   
           
    getChartNumberOfCalls(filterselected:any,date:any,daytype:any){
      this.ngxLoader.startLoader('loader-03');
      if ($("html").hasClass("theme-white")) {
        this.bgcolorcode='rgb(17,19,25)';          
        // this.colorcode='rgb(154,255,192)';          
      }
  
      if(date==0){
        date = this.selecteddate;
      }
      this.chartarg = {
        filterselected: filterselected,
        date:date
      };
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.moduleservice.getChartbyfilterbing(this.currentCompany.uuid,this.chartarg,daytype,this.currentUser.timezone).subscribe(
        res => {
          this.chart = new Chart({
            chart: {
              // polar: true,
              type: 'line',
              zoomType: 'x',
              backgroundColor:this.bgcolorcode,                      
            },
            exporting: {
              enabled: true
            },
            title: {
              text: ''+this.charttype,
              style: {
                color: this.colorcode
              },
            },
            subtitle: {
              text: document.ontouchstart === undefined ?
                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
                style: {
                  color: this.colorcode
              },
            },
            credits: {
              enabled: false
            },  
            tooltip: {
              shared: true,
            },  
            xAxis: {
                minRange: 0,
                gridLineWidth: 0,
                title: {
                  text: ''+this.charttype,
                  style: {
                    color: this.colorcode
                  },
                },categories: res.cattype
            },
            yAxis: {
              min:0,
              gridLineWidth: 1,
              title: {
                text: 'Number Of Calls',
                style: {
                  color: this.colorcode
              },
              }
            },
            colors: [
              'rgb(249,178,54)',            
              ],
            plotOptions: {
              
              column: {
                colorByPoint: true
              },
              line: {
                    dataLabels: {
                      useHTML: true,
                      enabled: true,
                      format: '{point.percentage:.1f} %',
                      color: 'white',                
                  },
                  enableMouseTracking: false,
              }
            },
            series: [{
              data: res.chartdata,
              type: 'column',      
              name:"Call Count",
              color: '#0066FF',
            }       
            ,{
              name: 'Converted',
              color: '#F9B236',
              type: 'spline',
              data: res.converted,   
            },{
              name: 'Connected',
              type: 'spline',
              data: res.connected,       
              color: '#20bf55',    
            }
            ,{
              name: 'No Answer',
              type: 'spline',
              data: res.noanswer,           
              color: 'red',    
            },{
              name: 'No Connect',
              type: 'spline',
              data: res.noconnect, 
              color: 'red',             
              }
            ]
          }); 
          this.ngxLoader.stopLoader('loader-03');
        },
        error => this.error = error
      );  
      
    }
  
    calls = []
    lists = [];
    ColumnMode = ColumnMode;
    loadcalls(filterselected:any,date:any): void {
      
      this.loadingIndicator = true;
      // this.ngxLoader.start();
      this.ngxLoader.startLoader('loader-01');
      // this.ngxLoader.start();
      let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const formData = new FormData();      
      formData.append('filterselected', JSON.stringify(filterselected));
      formData.append('timezone', currentUser.timezone);
      formData.append('date', JSON.stringify(date));
  
      this.http.post<any>(
        this.ServerUrl+'getcallsdatanew-bing/'+currentCompany.uuid,            
         formData,{
          headers: new HttpHeaders()
          // .set('Pragma', 'no-cache') 
          // .set('Cache-Control', 'no-cache') 
        }
      ).subscribe(resp => {
          this.calls = resp[0].Response.data;
          this.lists = resp[0].Response.data;
          this.loadingIndicator = false;
          // this.ngxLoader.stopBackground("loader-01");
          this.ngxLoader.stopLoader('loader-01');
          // console.log(this.blogPostsList);
      }, error => {
          // this.ngxLoader.stopBackground("loader-01");
          this.ngxLoader.stopLoader('loader-01');
          this.loadingIndicator = false;
      });
     
    }
  
    
    livecalls = []
    loadlivecalls(): void {   
      this.LoadingIndicatorlivecall = true;
      this.http.post<any>(
        this.ServerUrl+'livecallReport/',            
         {}
      ).subscribe(resp => {
        // console.log(resp.rows);
          this.livecalls = resp.rows;
          this.LoadingIndicatorlivecall = false;        
      }, error => {
        this.LoadingIndicatorlivecall = false;
      });
    }
  
    private loaddataonscroll(limit: number) {
      // this.ngxLoader.start();
      this.ngxLoader.startBackground('loader-01');
      this.loadingIndicator = true;
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const formData = new FormData();      
      formData.append('filterselected', JSON.stringify(this.filterselected));
      formData.append('timezone', currentUser.timezone);
      formData.append('date', JSON.stringify(this.selected1));
      let newlimit = limit;
      let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.http.post<any>(
        this.ServerUrl+'getcallsdatanew-bing/'+currentCompany.uuid+'/'+this.calls.length+'/'+newlimit,            
        formData
      ).subscribe(resp => {
          const rows = [...this.calls, ...resp[0].Response.data];
          this.calls = rows;
          this.lists = resp[0].Response.data;
         
          // this.ngxLoader.stop();
          this.loadingIndicator = false;
          this.ngxLoader.stopBackground('loader-01');
          // console.log(this.blogPostsList);
      }, error => {
          this.loadingIndicator = false;
          // this.ngxLoader.stop();        
          this.ngxLoader.stopBackground('loader-01');
      });
      
      
    }
  
    isLoading: boolean;
    readonly headerHeight = 40;
    readonly rowHeight = 40;
    readonly pageLimit = 100;
    loadingIndicator = false;
    onScroll(event:any) {
        let offsetY = event.offsetY;
        let offsetX = event.offsetX;
        // console.log(event.offsetY);return
        // if(offsetY != 125 || offsetY!=undefined){
          const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
  
            if (offsetX==0 && !this.loadingIndicator && offsetY + viewHeight >= this.calls.length * this.rowHeight) {
            // if (!this.loadingIndicator && offsetY + viewHeight >= this.rows.length * 100) {
            let limit = this.pageLimit;
            if (this.calls.length === 0) {
              const pageSize = Math.ceil(viewHeight / this.rowHeight);
              limit = Math.max(pageSize, this.pageLimit);
            }
            
            this.loaddataonscroll(limit);
          }
        // }
    }
  
  
  
  
    filterselectedforexport:any;
    dateforexport:any;
    // get f() { return this.searchcallsform.controls; }  
    // loadcalls(filterselected:any,date:any){
    //   if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
    //     $(this.table.nativeElement).dataTable().fnDestroy();
    //   } 
  
    //   this.filterselectedforexport = filterselected;
    //   this.dateforexport = date;
   
    //   this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  
    //   var that = this;
    //   this.dtOptionsdetails = {
    //     "pagingType": 'full_numbers',
    //     "processing": true,
    //     "serverSide": true,          
    //     "lengthMenu": [[-1, 50, 100, 500, 2000], ["All", 50, 100, 500, 2000]],
    //     "pageLength": 100,
    //     "order": [[ 2, "desc" ]],
    //     "searching":false,
    //     ajax: (dataTablesParameters: any, callback) => {
    //       that.http.post<DataTablesResponse>(
    //         this.ServerUrl+'getcalls/'+this.currentCompany.uuid, {'dataTablesParameters':dataTablesParameters,'filterselected':filterselected,date:date,'timezone':this.currentUser.timezone}
    //        , {}
    //       ).subscribe(resp => {
    //                 // console.log(resp); return false;
    //                 that.labels = resp.data;   
    //                 callback({
    //                     recordsTotal: resp.recordsTotal,
    //                     recordsFiltered: resp.recordsFiltered,
    //                     data: [],
    //                 });
    //             });
    //     },
    //     columns: [
    //       // 
    //       {data:"detail"},{data:"did"},{data:"incoming_number"},{data:"publisher"},{data:"target"},{data:"campaign"},{data:"routed_to"},{data:"call_duration"},{data:"ivr_duration"},{data:"billable_duration"},{data:"date_of_call"},{data:"assosciated_labels"},{data:"hangup_by"},{data:"revenue"},{data:"duplicate"},{data:"payout"},{data:"recording"}
    //     ],
    // };
  
    // this.dataTable = $(this.table.nativeElement);
    // this.dataTable.dataTable(this.dtOptionsdetails);
    // }  
    
    // get f() { return this.searchcallsform.controls; }  
    totaldata:any;
    totalcount:any;
  
    loadcallsdata(filterselected:any,date:any,groupby:any){
      // $("#datatable1").dataTable().fnDestroy();
  
      this.ngxLoader.startLoader('loader-02');
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      
      // this.ngxLoader.startLoader('loader-03');
    
      this.http.post<any>(
                this.ServerUrl+'getcallsdata-bing/'+this.currentCompany.uuid, {'filterselected':filterselected,'date':date,'timezone':this.currentUser.timezone,'groupby':groupby}
              , {}
              ).subscribe(resp => {
                    //                    
                    this.callsdata = resp.data; 
                    this.totalcount = resp.totalarr; 
                    this.totaldata  = resp.data.length ;
                    // this.dtTrigger.next();
                    this.ngxLoader.stopLoader('loader-02');
                  },
                  error => this.error = error
              );   
    }
  
  
    ngAfterViewInit(): void {
      this.dtTrigger.next();
    }
  
  
  
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  
    rerender(): void {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
         dtInstance.destroy();
         this.dtTrigger.next();     
      });
    }
  
    // loadcallsdata(filterselected:any,date:any,groupby:any){
     
    //    if ($.fn.DataTable.isDataTable(this.table1.nativeElement) ) {
    //     $(this.table1.nativeElement).dataTable().fnDestroy();
    //   } 
    //   // console.log(date);
    //   this.ngxLoader.startLoader('loader-02');
    //   this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    //   var that = this;
    //   this.dtOptions = {
    //     "dom": '<"top">rt<"bottom"flp><"clear">',
    //     "pagingType": 'full_numbers',
    //     "processing": true,
    //     "serverSide": true,          
    //     "lengthMenu": [[-1, 50, 100, 500, 2000, 5000], ["All", 50, 100, 500, 2000, 5000 ]],
    //     "pageLength": 100,
    //     "order": [[ 2, "asc" ]],
    //     "searching":false,
    //     ajax: (dataTablesParameters: any, callback) => {
    //       that.http.post<DataTablesResponse>(
    //         this.ServerUrl+'getcallsdata-bing/'+this.currentCompany.uuid, {'dataTablesParameters':dataTablesParameters,'filterselected':filterselected,'date':date,'timezone':this.currentUser.timezone,'groupby':groupby}
    //        , {}
    //       ).subscribe(resp => {
    //                 // console.log(resp); return false;
    //                 this.callsdata = resp.data; 
    //                 this.totalcount = resp.totalarr; 
    //                 this.totaldata  = resp.data.length ;
    //                 this.totaldata  = resp.data.length ;
    //                 callback({
    //                     recordsTotal: resp.recordsTotal,
    //                     recordsFiltered: resp.recordsFiltered,
    //                     data: [],
    //                 });
    //             });              
    //     },
    //     columns: [
    //       {data:"filter"},{data:"incoming"},{data:"uniquecalls"},{data:"uniquecalls"},{data:"ivr_agent"},{data:"connected"},{data:"qualified"},{data:"converted"},{data:"completed"},{data:"no_answer"},{data:"no_connect"},{data:"duplicate"},{data:"payout"},{data:"revenue"},{data:"profit"},{data:"cr_percent"},{data:"rpc"},{data:"acl"},{data:"tcl"},
    //     ],      
    // };
  
    // this.dataTable1 = $(this.table1.nativeElement);
    // this.dataTable1.dataTable(this.dtOptions);
    // this.ngxLoader.stopLoader('loader-02');
    // }
    
  
    
    // addActive(id){
    //   alert(id);
    // }
    
    details(i,data){
            // console.log(i); return
            // var tr = $('td.details-control').closest('tr').eq(i);
            // var divClass = $(".detailrow_"+i).eq(0).attr('class'); 
            // if (divClass.indexOf('shown') > -1) {
            //     $(".appendedtbl_"+i).remove();
            //       $(".detailrow_"+i).removeClass('shown');
            //   }else {
                this.ngxLoader.start();
                this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
                this.moduleservice.getReportdetailsdata(data.uuid,this.currentUser.uuid).subscribe(
                  res => {
                    // console.log(res); return
                    // Target
                    this.ngxLoader.stop();
                    
                    var targetid;
                    if(res.tagetdialed[0].targetid!=null){
                      targetid = res.tagetdialed[0].targetid;                  
                    }else{
                      targetid="-";
                     
                    }
                   
                    var targetname;
                    if(res.tagetdialed[0].targetname!=null){
                      targetname = res.tagetdialed[0].targetname;
                    }else{
                      targetname="-";
                    }
                   
                    var targetnumber;
                    if(res.tagetdialed[0].targetnumber!=null){
                      targetnumber = res.tagetdialed[0].targetnumber;
                    }else{
                      targetnumber="-";
                    }
                    
                    var payout;
                    if(res.tagetdialed[0].payout>0.0){
                      payout = "$"+res.tagetdialed[0].payout;
                    }else{
                      payout="-";
                    }                 
                    
                    var recording;
                    if(res.data[0].recording!=null){
                      let urltype;
                      if(res.data[0].date_of_call <= "2022-10-17"){                      
                        urltype = 'https://repuatationmanagement.s3.us-west-2.amazonaws.com/';
                      }else{
                        urltype = "https://d2pktjhs7fqsvz.cloudfront.net/"; 
                      }
                      // recording = "$"+res.tagetdialed[0].recording;
                      recording = '<a taget="_blank" style="color:black" href="'+urltype+res.data[0].recording+'">Recording URL</a>';
                    }else{
                      recording="-";
                    }                 
                    
                    var hangup_by;
                    if(res.data[0].hangup_by=='User'){
                      hangup_by = "Caller";
                    }else if(res.data[0].hangup_by=='System'){
                      hangup_by="System";
                    }else{
                      hangup_by="-";
                    }    
                    
                    var plan ='<table class="routingtbl" style="width:100%" class="table">'+
                              '<tr>'+
                                '<th>Eligibily</th>'+
                                '<th>Priority</th>'+
                                '<th>Weight</th>'+
                                '<th>Name</th>'+
                                '<th>Reason</th>'+
                                '</tr>';
                              var result="";
                              var plan1="";
                              var result1="";
                              for (let index = 0; index < res.routingplan.length; index++) {
                                  const data =  res.routingplan[index];
                                  plan1 = 
                                    '<tr>'+
                                    '<td>'+data.iseligible+'</td>'+
                                    '<td>'+data.priority+'</td>'+
                                    '<td>'+data.weight+'</td>'+
                                    '<td>'+data.name+'</td>'+
                                    '<td>'+data.reson+'</td>'+
                                  '</tr>';  
                                  result1 += result.concat(plan1);  
                              }
                              // console.log(plan); return
                    var finalresult = plan.concat(result1);                  
                    finalresult += result.concat('</table>');                  
                    
                    var ivrnode ='';
                    if(res.data[0].ivr_inputs!='' && res.data[0].ivr_inputs!=null){
                    ivrnode ='<div class="activity mt-2">'+
                                '<div class="activity-icon bg-blue text-white">'+
                                  '<i class="fas fa-info"></i>'+
                                '</div>'+
                                '<div class="activity-detail">'+
                                  '<div class="mb-2">'+
                                    '<span class="text-job text-primary">IVR Node Enter</span>'+'</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Timestamp</div>'+
                                    '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Node Type</div>'+
                                    '<div class="col-md-3">MenuNode</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Step Name</div>'+
                                    '<div class="col-md-3">Menu</div>'+
                                  '</div>'+                                            
                                '</div>'+
                              '</div>'+ 
                            
                              '<div class="activity">'+
                                '<div class="activity-icon bg-blue text-white">'+
                                  '<i class="fas fa-info"></i>'+
                                '</div>'+
                                '<div class="activity-detail">'+
                                  '<div class="mb-2">'+
                                    '<span class="text-job text-primary">User Input</span>'+'</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Timestamp</div>'+
                                    '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Digit</div>'+
                                    '<div class="col-md-3">'+res.data[0].ivr_inputs+'</div>'+
                                  '</div>'+                                                                
                                '</div>'+
                              '</div>'+ 
                          
                              '<div class="activity">'+
                                '<div class="activity-icon bg-blue text-white">'+
                                  '<i class="fas fa-info"></i>'+
                                '</div>'+
                                '<div class="activity-detail">'+
                                  '<div class="mb-2">'+
                                    '<span class="text-job text-primary">IVR Node Exit</span>'+'</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Timestamp</div>'+
                                    '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Node Type</div>'+
                                    '<div class="col-md-3">MenuNode</div>'+
                                  '</div>'+                                                                
                                  '<div class="row">'+
                                    '<div class="col-md-1">Step Name</div>'+
                                    '<div class="col-md-3">Menu</div>'+
                                  '</div>'+                                                                
                                '</div>'+
                              '</div>';          
                    }
                    
                    var routingplan ='';
                    if(res.routingplan.length>0){
                      routingplan='<div class="mt-2 activity">'+
                              '<div class="activity-icon bg-blue text-white">'+
                                '<i class="fas fa-info"></i>'+
                              '</div>'+
                              '<div class="activity-detail">'+
                                '<div class="mb-2">'+
                                  '<span class="text-job text-primary">Routing Plan</span>'+'</div>'+
                                  
                                  '<div class="row">'+
                                    '<div class="col-md-1">Timestamp</div>'+
                                    '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                                  '</div>'+
                                
                                  '<div class="row mt-2">'+
                                  '<div class="col-md-3">'+
                                    finalresult+
                                  '</div>'+
                                  '</div>'+
                              '</div>'+
                            '</div>';
                    }
  
                    var targetdialed ='';
                    if(res.data[0].billsec>0){
                      targetdialed='<div class="activity">'+
                      '<div class="activity-icon bg-success text-white">'+
                        '<i class="fas fa-check"></i>'+
                      '</div>'+
                      '<div class="activity-detail">'+
                        '<div class="mb-2">'+
                          '<span class="text-job text-primary">Target Dialed</span>'+'</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Timestamp</div>'+
                          '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Buyer</div>'+
                          '<div class="col-md-3">'+res.tagetdialed[0].buyer+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Buyer ID</div>'+
                          '<div class="col-md-3">'+res.tagetdialed[0].buyerid+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Target ID</div>'+
                          '<div class="col-md-3">'+targetid+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Target Name</div>'+
                          '<div class="col-md-3">'+targetname+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Target Number</div>'+
                          '<div class="col-md-3">'+targetnumber+'</div>'+
                        '</div>'+
                      '</div>'+
                    '</div>';
                    }          
              
                    var converteddata ='';
                    if(res.data[0].payout>0){
                      converteddata = '<div class="activity">'+
                          '<div class="activity-icon bg-success text-white">'+
                            '<i class="fas fa-check"></i>'+
                          '</div>'+
                          '<div class="activity-detail">'+
                            '<div class="mb-2">'+
                              '<span class="text-job text-primary">Converted</span>'+'</div>'+
                            '<div class="row">'+
                              '<div class="col-md-1">Timestamp</div>'+
                              '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            '</div>'+
                            '<div class="row">'+
                              '<div class="col-md-1">Buyer</div>'+
                              '<div class="col-md-3">'+res.tagetdialed[0].buyer+'</div>'+
                            '</div>'+
                            '<div class="row">'+
                              '<div class="col-md-1">Buyer ID</div>'+
                              '<div class="col-md-3">'+res.tagetdialed[0].buyerid+'</div>'+
                            '</div>'+
                            '<div class="row">'+
                              '<div class="col-md-1">Payout</div>'+
                              '<div class="col-md-3">'+payout+'</div>'+
                            '</div>'+
                            '<div class="row">'+
                              '<div class="col-md-1">Target ID</div>'+
                              '<div class="col-md-3">'+targetid+'</div>'+
                            '</div>'+
                            '<div class="row">'+
                              '<div class="col-md-1">Target Name</div>'+
                              '<div class="col-md-3">'+targetname+'</div>'+
                            '</div>'+
                            '<div class="row">'+
                              '<div class="col-md-1">Target Number</div>'+
                              '<div class="col-md-3">'+targetnumber+'</div>'+
                            '</div>'+
                          '</div>'+
                        '</div>';
                    }
  
                    var connecteddata ='';
                    if(res.data[0].billsec>0){
                      connecteddata =  '<div class="activity">'+
                      '<div class="activity-icon bg-success text-white">'+
                        '<i class="fas fa-check"></i>'+
                      '</div>'+
                      '<div class="activity-detail">'+
                        '<div class="mb-2">'+
                          '<span class="text-job text-primary">Connected</span>'+'</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Timestamp</div>'+
                          '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Buyer</div>'+
                          '<div class="col-md-3">'+res.tagetdialed[0].buyer+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Buyer ID</div>'+
                          '<div class="col-md-3">'+res.tagetdialed[0].buyerid+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Connection Time</div>'+
                          '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Target ID</div>'+
                          '<div class="col-md-3">'+targetid+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Target Name</div>'+
                          '<div class="col-md-3">'+targetname+'</div>'+
                        '</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Target Number</div>'+
                          '<div class="col-md-3">'+targetnumber+'</div>'+
                        '</div>'+
                      '</div>'+
                    '</div>';
                    }
  
                    var enddata;
                    // console.log(res.tagetdialed[0].end_target_id);
                    if(res.tagetdialed[0].end_target_id!='-'){
                      enddata = '<div class="row">'+
                      '<div class="col-md-1">End Target ID</div>'+
                      '<div class="col-md-3">'+res.tagetdialed[0].end_target_id+'</div>'+
                      '</div>'+       
                      '<div class="row">'+
                        '<div class="col-md-1">End Target Name</div>'+
                        '<div class="col-md-3">'+res.tagetdialed[0].endtargetname+'</div>'+
                      '</div>'+      
                      '<div class="row">'+
                        '<div class="col-md-1">End Buyer ID</div>'+
                        '<div class="col-md-3">'+res.tagetdialed[0].end_buyer_id+'</div>'+
                      '</div>'+       
                      '<div class="row">'+
                        '<div class="col-md-1">End Buyer Name</div>'+
                        '<div class="col-md-3">'+res.tagetdialed[0].end_buyername+'</div>'+
                      '</div>';
  
                    }else{
                      enddata="";
                    }  
  
                  var completeddata =  '<div class="activity">'+
                        '<div class="activity-icon bg-blue text-white">'+
                          '<i class="fas fa-info"></i>'+
                        '</div>'+
                        '<div class="activity-detail">'+
                          '<div class="mb-2">'+
                            '<span class="text-job text-primary">Completed</span>'+'</div>'+
                          '<div class="row">'+
                            '<div class="col-md-1">Timestamp</div>'+
                            '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                          '</div>'+
                          '<div class="row">'+
                            '<div class="col-md-1">Buyer</div>'+
                            '<div class="col-md-3">'+res.tagetdialed[0].buyer+'</div>'+
                          '</div>'+
                          '<div class="row">'+
                            '<div class="col-md-1">Buyer ID</div>'+
                            '<div class="col-md-3">'+res.tagetdialed[0].buyerid+'</div>'+
                          '</div>'+
                          '<div class="row">'+
                            '<div class="col-md-1">Call Length</div>'+
                            '<div class="col-md-3">'+res.data[0].duration+'</div>'+
                          '</div>'+
                          '<div class="row">'+
                            '<div class="col-md-1">Completion Time</div>'+
                            '<div class="col-md-3">'+res.data[0].end_stamp+'</div>'+
                          '</div>'+       
                           enddata+ 
                          '<div class="row">'+
                            '<div class="col-md-1">Customer IP</div>'+
                            '<div class="col-md-3">'+res.tagetdialed[0].customerip+'</div>'+
                          '</div>'+       
                                              
                        '</div>'+
                      '</div>';
                  
                var payoutcall ='';
                if(res.data[0].payout>0){
                  payoutcall =  '<div class="activity">'+
                    '<div class="activity-icon bg-success text-white">'+
                      '<i class="fas fa-check"></i>'+
                    '</div>'+
                    '<div class="activity-detail">'+
                      '<div class="mb-2">'+
                        '<span class="text-job text-white">Payout Call</span>'+'</div>'+
                        '<div class="row">'+
                          '<div class="col-md-1">Timestamp</div>'+
                          '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                        '</div>'+      
                        '<div class="row">'+
                          '<div class="col-md-1">Payout</div>'+
                          '<div class="col-md-3">'+payout+'</div>'+
                        '</div>'+                          
                    '</div>'+
                  '</div>';
                }
                // $(".appendrow").append(payoutcall);return
                // appendrow
                // console.log(payoutcall);
                // this.table.rowDetail.toggleExpandRow(payoutcall); return false
                // $(".appendrow"+i).after(
                  var finalresult = '<div class="appendedtbl_'+i+'" style="width: 900%!important;padding-top: 10px;border-top: 1px solid #424a67;">'+
                  '<ul class="nav nav-tabs expandtabs">'+
                    '<li id="eventtgl" style="padding: 6px 6px 0px 5px;border-radius: 3px;border: 1px solid #747e9a;margin-right: 2px;    margin-bottom: 3px;" class="active"><a class="active" data-toggle="tab" href="#home_'+i+'">Event</a></li>'+
                    '<li style="padding: 6px 6px 0px 5px;border-radius: 3px;border: 1px solid #747e9a;margin-right: 2px;    margin-bottom: 3px;"><a  data-toggle="tab" href="#menu_'+i+'">Record</a></li>'+
                    '<li style="padding: 6px 6px 0px 5px;border-radius: 3px;border: 1px solid #747e9a;margin-right: 2px;    margin-bottom: 3px;"><a data-toggle="tab" href="#tag_'+i+'">Tag</a></li>'+
                  '</ul>'+
                
                  '<div class="tab-content" style="width: 241%">'+
                    '<div id="home_'+i+'" class="tab-pane fade in active show">'+
                      '<section class="section" style="width: 0px;">'+
                      '<div class="section-body" style="width: 794%;">'+
                        '<div class="row">'+
                          '<div class="col-12">'+
                            '<div class="activities">'+
  
                              ivrnode+
                              routingplan+                          
                              targetdialed+                         
                              converteddata+         
                              connecteddata+                           
                              completeddata+                           
                              payoutcall+                           
                            
                            '<div class="activity">'+
                              '<div class="activity-icon bg-blue text-white">'+
                                '<i class="fas fa-info"></i>'+
                              '</div>'+
                              '<div class="activity-detail">'+
                                '<div class="mb-2">'+
                                  '<span class="text-job text-primary">End Call Source</span>'+'</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Timestamp</div>'+
                                    '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                                  '</div>'+      
                                  '<div class="row">'+
                                    '<div class="col-md-1">Source</div>'+
                                    '<div class="col-md-3">'+hangup_by+'</div>'+
                                  '</div>'+         
                              '</div>'+
                            '</div>'+
                          
                            '<div class="activity">'+
                              '<div class="activity-icon bg-blue text-white">'+
                                '<i class="fas fa-info"></i>'+
                              '</div>'+
                              '<div class="activity-detail">'+
                                '<div class="mb-2">'+
                                  '<span class="text-job text-primary">Recorded</span>'+'</div>'+
                                  '<div class="row">'+
                                  '<div class="col-md-1">Timestamp</div>'+
                                  '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Buyer</div>'+
                                    '<div class="col-md-3">'+res.tagetdialed[0].buyer+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Buyer ID</div>'+
                                    '<div class="col-md-3">'+res.tagetdialed[0].buyerid+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Recording URL</div>'+
                                    '<div class="col-md-5">'+recording+'</div>'+
                                  '</div>'+
                              '</div>'+
                            '</div>'+
                              
                            '</span>'+
                          '</div>'+
                        '</div>'+
                      '</div>'+
                    '</section> '+
                    '</div>'+
  
                    '<div id="menu_'+i+'" class="tab-pane fade recorded">'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Name</div>'+
                      '<div class="col-md-2">Values</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Campaign</div>'+
                      '<div class="col-md-2">'+res.data[0].campaign+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Publisher</div>'+
                      '<div class="col-md-2">'+res.data[0].publisher+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Target</div>'+
                      '<div class="col-md-2">'+targetname+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Target Number</div>'+
                      '<div class="col-md-2">'+targetnumber+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Buyer</div>'+
                      '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Campaign ID</div>'+
                      '<div class="col-md-2">'+res.data[0].campaign_id+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Publisher ID</div>'+
                      '<div class="col-md-2">'+res.data[0].publisher_id+'</div>'+
                    '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Publisher Sub ID</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Target ID</div>'+
                      '<div class="col-md-2">'+targetid+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Buyer ID</div>'+
                      '<div class="col-md-2">'+res.tagetdialed[0].buyerid+'</div>'+
                    '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Inbound Call ID</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Call Date</div>'+
                      '<div class="col-md-2">'+res.data[0].start_stamp+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Caller ID</div>'+
                      '<div class="col-md-2">'+res.data[0].uuid+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Dialed #</div>'+
                      '<div class="col-md-2">'+res.data[0].did+'</div>'+
                    '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Number ID</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Number Pool Used</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Number Pool ID</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Number Pool</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Time To Call</div>'+
                      '<div class="col-md-2">'+res.data[0].duration+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Call Complete Timestamp</div>'+
                      '<div class="col-md-2">'+res.data[0].end_stamp+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Call Connected Timestamp</div>'+
                      '<div class="col-md-2">'+res.data[0].start_stamp+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Duration</div>'+
                      '<div class="col-md-2">'+res.data[0].duration+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Connected Call Length</div>'+
                      '<div class="col-md-2">'+res.data[0].billsec+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Hangup</div>'+
                      '<div class="col-md-2">'+hangup_by+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Connected</div>'+
                      '<div class="col-md-2">'+res.data[0].connected+'</div>'+
                    '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Paid Out</div>'+
                      '<div class="col-md-2">'+res.data[0].paidout+'</div>'+
                    '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Previously Connected</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Has Recording</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Converted</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Is Live</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Revenue</div>'+
                      '<div class="col-md-2">'+res.data[0].revenue+'</div>'+
                    '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Profit Net Telco</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Profit</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Payout</div>'+
                      '<div class="col-md-2">'+res.tagetdialed[0].payout+'</div>'+
                    '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Total Cost</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Telco Cost</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                      '<div class="col-md-2">Recording</div>'+
                      '<div class="col-md-2">'+recording+'</div>'+
                    '</div>'+
                    // '<div class="row" style="border-bottom: 1px solid #424a67;">'+
                    //   '<div class="col-md-2">Time To Connect</div>'+
                    //   '<div class="col-md-2">'+res.tagetdialed[0].buyer+'</div>'+
                    // '</div>'+
                    
                    '</div>'+
  
  
                    '<div id="tag_'+i+'" class="tab-pane fade">'+
                    '<section class="section" style="width: 30px;">'+
                    '<div class="section-body" style="width: 794%;">'+
                      '<div class="row">'+
                        '<div class="col-12">'+
                          '<div class="activities">'+
                           '<div class="mt-2 activity">'+
                              '<div class="activity-icon bg-primary text-white">'+
                                '<i class="fas fa-tag"></i>'+
                              '</div>'+
                              '<div class="activity-detail">'+
                                '<div class="mb-2">'+
                                  '<span class="text-job text-primary">Time</span>'+'</div>'+
                                  
                                  '<div class="row">'+
                                    '<div class="col-md-1">UTC Hour</div>'+
                                    '<div class="col-md-3">'+res.tags[0].UTCHour+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">UTC Minute</div>'+
                                    '<div class="col-md-3">'+res.tags[0].UTCMinute+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">UTC Second</div>'+
                                    '<div class="col-md-3">'+res.tags[0].UTCSecond+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">UTC Offset</div>'+
                                    '<div class="col-md-3">'+res.tags[0].UTCOffset+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Hour</div>'+
                                    '<div class="col-md-3">'+res.tags[0].Hour+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Minute</div>'+
                                    '<div class="col-md-3">'+res.tags[0].Minute+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Second</div>'+
                                    '<div class="col-md-3">'+res.tags[0].Second+'</div>'+
                                  '</div>'+
                                                                
                              '</div>'+
                            '</div>'+
  
                            '<div class="mt-2 activity">'+
                              '<div class="activity-icon bg-primary text-white">'+
                                '<i class="fas fa-tag"></i>'+
                              '</div>'+
                              '<div class="activity-detail">'+
                                '<div class="mb-2">'+
                                  '<span class="text-job text-primary">Date</span>'+'</div>'+
                                  
                                  '<div class="row">'+
                                    '<div class="col-md-1">UTC Week Day</div>'+
                                    '<div class="col-md-3">'+res.tags[0].UTCWeekDay+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">UTC Day</div>'+
                                    '<div class="col-md-3">'+res.tags[0].UTCDay+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">UTC Month</div>'+
                                    '<div class="col-md-3">'+res.tags[0].UTCMonth+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">UTC Year</div>'+
                                    '<div class="col-md-3">'+res.tags[0].UTCYear+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Week Day</div>'+
                                    '<div class="col-md-3">'+res.tags[0].WeekDay+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Day</div>'+
                                    '<div class="col-md-3">'+res.tags[0].Day+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Month</div>'+
                                    '<div class="col-md-3">'+res.tags[0].Month+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Year</div>'+
                                    '<div class="col-md-3">'+res.tags[0].Year+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Time Zone</div>'+
                                    '<div class="col-md-3">'+res.tags[0].TimeZone+'</div>'+
                                  '</div>'+                                                              
                              '</div>'+
                            '</div>'+
                           
                            '<div class="mt-2 activity">'+
                              '<div class="activity-icon bg-primary text-white">'+
                                '<i class="fas fa-tag"></i>'+
                              '</div>'+
                              '<div class="activity-detail">'+
                                '<div class="mb-2">'+
                                  '<span class="text-job text-primary">Campaign</span>'+'</div>'+
                                  
                                  '<div class="row">'+
                                    '<div class="col-md-1">Name</div>'+
                                    '<div class="col-md-3">'+res.data[0].campaign+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Id</div>'+
                                    '<div class="col-md-3">'+res.data[0].campaign_id+'</div>'+
                                  '</div>'+
                                  // '<div class="row">'+
                                  //   '<div class="col-md-1">TrackingId</div>'+
                                  //   '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                                  // '</div>'+
                                '</div>'+
                            '</div>'+
                           
                            '<div class="mt-2 activity">'+
                              '<div class="activity-icon bg-primary text-white">'+
                                '<i class="fas fa-tag"></i>'+
                              '</div>'+
                              '<div class="activity-detail">'+
                                '<div class="mb-2">'+
                                  '<span class="text-job text-primary">Publisher</span>'+'</div>'+
                                  
                                  // '<div class="row">'+
                                  //   '<div class="col-md-1">Company</div>'+
                                  //   '<div class="col-md-3">'+res.data[0].publisher+'</div>'+
                                  // '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Name</div>'+
                                    '<div class="col-md-3">'+res.data[0].publisher+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Id</div>'+
                                    '<div class="col-md-3">'+res.data[0].publisher_id+'</div>'+
                                  '</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">SubId</div>'+
                                    '<div class="col-md-3">'+res.data[0].publisher_subid+'</div>'+
                                  '</div>'+
                                '</div>'+
                            '</div>'+
                            
                            // '<div class="mt-2 activity">'+
                            //   '<div class="activity-icon bg-primary text-white">'+
                            //     '<i class="fas fa-tag"></i>'+
                            //   '</div>'+
                            //   '<div class="activity-detail">'+
                            //     '<div class="mb-2">'+
                            //       '<span class="text-job text-primary">Inbound Number</span>'+'</div>'+
                                  
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Number</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Number No Plus</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Country Code</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Country Digits</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Area Code</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Prefix</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Suffix</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Region</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">State</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Is Phone Number Valid</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Number E 164</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+
                            //     '</div>'+
                            // '</div>'+
  
                            '<div class="mt-2 activity">'+
                              '<div class="activity-icon bg-primary text-white">'+
                                '<i class="fas fa-tag"></i>'+
                              '</div>'+
                              '<div class="activity-detail">'+
                                '<div class="mb-2">'+
                                  '<span class="text-job text-primary">Dialed Number</span>'+'</div>'+
                                  
                                  '<div class="row">'+
                                    '<div class="col-md-1">Number</div>'+
                                    '<div class="col-md-3">'+res.data[0].did+'</div>'+
                                  '</div>'+                              
                                '</div>'+
                            '</div>'+
                         
                            // '<div class="mt-2 activity">'+
                            //   '<div class="activity-icon bg-primary text-white">'+
                            //     '<i class="fas fa-tag"></i>'+
                            //   '</div>'+
                            //   '<div class="activity-detail">'+
                            //     '<div class="mb-2">'+
                            //       '<span class="text-job text-primary">Menu</span>'+'</div>'+
                                  
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">Hotel Booking Only IVR</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+                              
                            //       '<div class="row">'+
                            //         '<div class="col-md-1">menuNode_d59-c968-4b48-adac-092be402e62b</div>'+
                            //         '<div class="col-md-3">'+res.data[0].start_stamp+'</div>'+
                            //       '</div>'+                              
                            //     '</div>'+
                            // '</div>'+
                           
                            '<div class="mt-2 activity">'+
                              '<div class="activity-icon bg-primary text-white">'+
                                '<i class="fas fa-tag"></i>'+
                              '</div>'+
                              '<div class="activity-detail">'+
                                '<div class="mb-2">'+
                                  '<span class="text-job text-primary">Call Length</span>'+'</div>'+
                                  '<div class="row">'+
                                    '<div class="col-md-1">Total</div>'+
                                    '<div class="col-md-3">'+res.data[0].billsec+'</div>'+
                                  '</div>'+                                                                                     
                                '</div>'+
                            '</div>'+
                        
                          '</div>'+
                        '</div>'+
                        '</div>'+
                      '</div>'+
                    '</div>'+
                    '</section>'+                 
                  '</div>'+
                '</div>';               
                  // );
                  $('.appendData').append(finalresult);
                  // tr.addClass('shown');
                },
                error => this.error = error
            );   
              // }
          
    }
    
     noofrecords:any = 500;
     exportdata(){
      var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true, 
        useBom: true,
        headers: ["Website", "Brand", "Caller ID", "Call Number",  "Forwarded Number","Date of Call","Time of Call","Call Duration"]
      };
  
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  
      const formData = new FormData();      
      formData.append('user_uuid', currentUser.uuid);
      formData.append('timezone', this.currentUser.timezone);
      formData.append('filterselected', JSON.stringify(this.filterselected));
      formData.append('date',JSON.stringify(this.selecteddate));
      formData.append('totalrecords', this.noofrecords);
      this.showLoadingIndicator =true;
      this.moduleservice.getCalldetailsForExport(this.currentCompany.uuid,formData).subscribe(
        res => {
          new   ngxCsv(res.data, 'All Calls', options);
          this.showLoadingIndicator =false;
        },
        error => this.error = error
    );      
    }
  
    exportcalldata(){
      var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true, 
        useBom: true,
        headers: ["UUID","Name(Filter)", "Incoming","Connected","Qualified","Converted","Completed","No Answer","No Connect","Payout","Revenue","Profit","CR%","RPC","ACL","TCL"]
      };
      new   ngxCsv(this.callsdata, 'All Calls', options);
    }
  
    isclick:number = 0;
    istougle:number = 1;
  
    togglepanel(){
      if(this.isclick==1){
        this.istougle = 1;
        this.isclick = 0;
      }else{
        this.isclick = 1;
        this.istougle = 0;
      }
    }
  
    filter:number;
    filtertext:string;
    activeresultdata:any; 
    resultdata:any; 
    nonactiveresultdata:any; 
    filtertype:any = 1;   
    filterdata(type:number){
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.filter = 0;
      this.isselectElement = 0;
      if(type == 1){      
        this.activeresultdata=[];
        this.nonactiveresultdata=[];
        this.filter = 1;
        this.filtertype = 1;
        this.filtertext = 'Select Campaign';
        this.filtervalue="";
        this.moduleservice.getallcampain(this.currentCompany.uuid).subscribe(
          res => {
            this.activeresultdata = res.active;
            this.nonactiveresultdata = res.non_active;
          });
      }
      if(type == 2){
       this.resultdata=[];
        this.filtertype = 2;
        this.filter = 1;
        this.filtertext = 'Select Target';
        this.filtervalue="";
        this.moduleservice.getAlltarget(this.currentCompany.uuid).subscribe(
          res => {
            this.resultdata = res;
          });
      }
      if(type == 3){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 3;
        this.filtertext = 'Select Buyer';
        this.filtervalue="";
        this.moduleservice.getallbuyers(this.currentCompany.uuid).subscribe(
          res => {
            this.resultdata = res;
          });      
      }
      if(type == 4){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 4;
        this.filtertext = 'Select Publisher';
        this.filtervalue="";
        this.moduleservice.getAllpublishers(this.currentCompany.uuid).subscribe(
          res => {
            this.resultdata = res;
          });     
      }
     
      if(type == 5){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 5;
        this.filtertext = 'Enter Caller ID';
        this.filtervalue="";    
      }
    
      if(type == 6){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 6;
        this.filtertext = 'Enter Number Called';
        this.filtervalue="";    
      }
     
      if(type == 8){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 8;
        this.filtertext = 'Select IVR Inputs';
        this.filtervalue="";    
        this.resultdata = [{
          'id':0,
          'text':'No',
        },{
          'id':1,
          'text':'Yes',
        }];
      }
      
      if(type == 11){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 11;
        this.filtertext = 'Select Duration';
        this.filtervalue="";    
        this.resultdata = [{
          'id':0,
          'text':'Greater Than',
        },{
          'id':1,
          'text':'Less Than',
        }];
      }
  
    }
  
    clearfilter(index:any){
      // this.isselectElement = 0;
      this.filterselected.splice(index,1);
      
      
      this.loadcalls(this.filterselected,this.selecteddate);
      this.loadcallsdata(this.filterselected,this.selecteddate,1);
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval);
    }
  
    filtervalue: any = '';
    filterdate: any = '';
    startdate: any;
    enddate: any;
    diff: any;
    days: any;
    selected1: any;
    selecteddate: any;
    isadded: any=1;
    filterval(event:any){
      // console.log(this.selected1); return false;
      // this.filterselected=[];
      let customObj = new filterarr();
      customObj.filtertype = this.filtertype;
      customObj.uuid = this.filtervalue; 
      customObj.name = this.selectElementText; 
      customObj.duration = this.selectElementDuration; 
      customObj.isorand = 0; 
      // console.log(this.selectElementText);
      if(this.selectElementText!=undefined && this.filterselected?.length==0 && !event){
        this.filterselected.push(customObj);  
      }
  
      if(event && event.startDate==null && event.endDate==null)
      return false;
      if(event){
      var start = moment(event.startDate._d).format('MM/DD/YYYY HH:mm:ss');
      var end = moment(event.endDate._d).format('MM/DD/YYYY HH:mm:ss');
      // console.log(this.startdate);
      // console.log(this.enddate);return false;
      this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
      this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
      
      // this.diff = this.enddate.diff(this.startdate, 'days');
      // console.log(this.startdate);
      
        this.selected1 = {
          startDate: this.startdate,
          endDate: this.enddate,
        };
        this.selecteddate = this.selected1;
        // console.log(this.selected1);
        var a = moment(start);
        var b = moment(end);
        if(b.diff(a, 'days')>0){
          this.daytypeval = 2;      
        }else{
          this.daytypeval = 1;      
        }
      }else{
        // console.log(123);
        
        this.isselectElement = 1;
        this.isadded = 0;
        const uuid = this.route.snapshot.paramMap.get('id');
        if(uuid!=null){        
          var start = moment(this.selected.startDate).format('MM/DD/YYYY HH:mm:ss');
          var end = moment(this.selected.endDate).format('MM/DD/YYYY HH:mm:ss'); 
        }else{
          if(this.selected1.startDate._d == undefined){
            var start = moment(this.selected1.startDate).format('MM/DD/YYYY HH:mm:ss');
            var end = moment(this.selected1.endDate).format('MM/DD/YYYY HH:mm:ss');
          }else{
            var start = moment(this.selected1.startDate._d).format('MM/DD/YYYY HH:mm:ss');
            var end = moment(this.selected1.endDate._d).format('MM/DD/YYYY HH:mm:ss');
          }
        }   
        
        $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
        $("#tabs1").addClass("activetab");this.filtertype=1;
        
        // console.log(this.selected.startDate);
        // console.log(end);
        // console.log(this.enddate);return false;
        this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
        this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
        this.selected1 = {
          startDate: this.startdate,
          endDate: this.enddate,
        };
       
        // console.log(123);
        this.selecteddate = this.selected1;
        this.daytypeval = 2;
      }
  
     
      // console.log(this.selecteddate);
      // return false
      // this.diff = this.startdate.getTime() - this.enddate.getTime();
      //getTime() function used to convert a date into milliseconds. This is needed in order to perform calculations.
     
    //  this.days = Math.round(Math.abs(this.diff/(1000*60*60*24)))
  
      // console.log('ok');
      // console.log(this.filterselected);
      // console.log(this.selecteddate);
      // console.log(this.daytypeval);
      // return;
      // console.log(this.selectElementDuration); 
      // console.log(this.filterselected); return
      this.loadcalls(this.filterselected,this.selecteddate);
      this.loadcallsdata(this.filterselected,this.selecteddate,1);
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval);
     
    }
    
  
    calcelfilter(){
      this.isclick = 0; 
      // this.filter = 0; 
      // this.filtertype = 0; 
      this.isselectElement = 0; 
      this.filtertype = 1; 
      this.filterdata(1); 
      this.filtervalue = ''; 
      this.filterselected=[];
      this.isadded = 1;
      this.loadcalls("","");
      this.loadcallsdata("","",1);
      this.getChartNumberOfCalls(0,0,1);
    }
  
    isselectElement:any=0;
    selectElementText:any;
    selectElementDuration:any="";
    filterselected: Array<filterarr> = [];
    gettext(event:any,type){
      if(type==1){
       this.selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
      }else if(type==3){
       
      //  this.selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
       this.selectElementDuration = event.target.value;      
      //  console.log(this.selectElementDuration);
      }else{
       this.selectElementText = event.target.value
      }
      // console.log(event.target.value);   
    }
    addorrule(){
      let customObj = new filterarr();
      customObj.filtertype = this.filtertype;
      customObj.uuid = this.filtervalue; 
      customObj.name = this.selectElementText; 
      customObj.duration = this.selectElementDuration; 
      customObj.isorand = 0; 
      // console.log(this.selectElementText);
      if(this.selectElementText!=undefined){
        this.filterselected.push(customObj);  
      }
      // console.log(this.filterselected);
    }
  
    addandrule(){
      let customObj = new filterarr();
      customObj.filtertype = this.filtertype;
      customObj.uuid = this.filtervalue; 
      customObj.name = this.selectElementText; 
      customObj.duration = this.selectElementDuration; 
      customObj.isorand = 1; 
      // console.log(this.selectElementText);
      if(this.selectElementText!=undefined){
        this.filterselected.push(customObj);  
      }
    }
  
    editfilter(){
      this.isadded=1;
      this.isselectElement=0;
    }
  
    charttype:string = "Calls Per Hour";
    daytypeval:number = 1;
    chartby(type:number){
      this.filterdate="";
      if(type==1){
        this.daytypeval = 1;
        this.charttype = "Calls Per Hour";
        this.getChartNumberOfCalls(0,0,1);
      }
      if(type==2){
        this.daytypeval = 2;
        this.charttype = "Calls Per Day";
        this.getChartNumberOfCalls(0,0,2);
      }
      if(type==3){
        this.daytypeval = 3;
        this.charttype = "Calls Per Month";
        this.getChartNumberOfCalls(0,0,3);
      }    
    }
  
    savedreportsettings:any;
    selecteddates:any;
    isreport:any = 0;
    getsavedreports(uuid:string){
      this.moduleservice.getsavedreportsbyuuid(uuid).subscribe(
        res => {
          this.labelval = res.reportlabelname;
          this.daytypeval = 2;     
          if(res.filtertype!=''){
            this.isreport = 1;
            if(res.filtertype!=0){ this.isclick = 1; }
            this.filter = res.filtertype;
            let startday = res.start_date;
            let endday = res.end_date;
            const d = new Date(startday);
            const d1 = new Date(endday);
            moment(d).format('MMMM d, YYYY');
            moment(d1).format('MMMM d, YYYY');
            var start = moment(d).format('MM/DD/YYYY HH:mm:ss');
            var end = moment(d1).format('MM/DD/YYYY HH:mm:ss');
          
            this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
            this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
            // console.log(this.filter);
            // console.log(this.filtervalue);
            // console.log(this.selected);
            
            this.selected = {startDate: d, endDate: d1};
            this.selecteddates = {startDate: this.startdate, endDate: this.enddate};
            this.filterselected = JSON.parse(res.filterarr);
            var a = moment(d);
            var b = moment(d1);
            if(b.diff(a, 'days')>0){
                this.daytypeval = 2;
                this.charttype = "Calls Per Day";
            }else{
                this.daytypeval = 1;
                this.charttype = "Calls Per Hour";
            }
          
          }
          this.filterdata(this.filter);
          if(res.filtervalue!=''){
            this.isclick = 1;
            this.filtervalue = res.filtervalue;          
          }
          
          this.selectednewdates = this.selecteddates;
          // localStorage.setItem('selectedDates',JSON.stringify(this.selecteddates));
          // console.log(localStorage.getItem('selectedDates'));return
          // console.log(this.selectednewdates);
          this.loadcalls(this.filterselected,this.selecteddates);
          this.loadcallsdata(this.filterselected,this.selecteddates,1);
          this.getChartNumberOfCalls(this.filterselected,this.selecteddates,this.daytypeval);
          
        });
    }
  
    islabelvaltext:any;
    savelabel(){
      // console.log(this.labelval);
      // console.log(this.selected.startDate._d);
      // console.log(this.filtertype);
      // console.log(this.filtervalue);
      const uuid = this.route.snapshot.paramMap.get('id');
      // console.log(this.selected1);
      // return false;
     
      if(this.labelval==undefined){
        this.islabelvaltext='yes';
        return;
      }
      this.islabelvaltext='';
      const formData = new FormData();
      if(uuid!=null){
        formData.append('uuid', uuid);  
      }
      if(!this.selecteddate){
        this.selecteddate =this.selected1;
      }
      var start = moment(this.selecteddate.startDate).format('MM/DD/YYYY HH:mm:ss');
      var end = moment(this.selecteddate.endDate).format('MM/DD/YYYY HH:mm:ss');
    
      this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
      this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
      // console.log(this.filtertype);
      // console.log(this.startdate);
      // console.log(this.enddate);
      // return false;
      if(this.filtervalue==""){
        this.filtertype = 0;
      }    
      // console.log(this.filterselected);return false;
      formData.append('company_uuid', this.currentCompany.uuid);
      formData.append('user_uuid', this.currentUser.uuid);
      formData.append('reportlabelname', this.labelval);
      formData.append('start_date', this.startdate);
      formData.append('end_date', this.enddate);
      formData.append('filtertype', this.filtertype);
      formData.append('filtervalue', this.filtervalue);
      formData.append('filterarr', JSON.stringify(this.filterselected));
      this.moduleservice.savereport(formData).subscribe(
        res => {
          if (res.status !== 'error') {
            if(res.isavail!=1){
              this.labelval="";
            }
            this.sendMenuUpdateMessage();
            this.toastrService.success('Report Saved Successfully');         
          }else{
            this.toastrService.error('Failed To Add Label');
          }
        });
    }
  
    deletelabel(){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Selected Report Name!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const uuid = this.route.snapshot.paramMap.get('id');
          this.moduleservice.deleteSelectedSavedReport(uuid).subscribe(
            res => {
                Swal.fire(
                  'Deleted!',
                  'Records Deleted Successfully.',
                  'success',                
                )
                this.sendMenuUpdateMessage();
                setTimeout(() => {
                  Swal.close()                
                  this.router.navigate(['/reports']);
                }, 2000);
                
              },
              error => this.error = error
          );      
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
  
    // groupby:any=1;
    getSummary(type){
      // alert(type);
      // this.filterselected=[];
      // console.log(this.selecteddate); return
      if(this.selecteddate == undefined){
        this.selecteddate = this.selected1;
      }
      // this.selecteddate = this.selected1;
      $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
      
      if(type==1){
         $("#tabs1").addClass("activetab");this.filtertype=1;
         this.loadcallsdata(this.filterselected,this.selecteddate,1);
      }
      if(type==2){
         $("#tabs2").addClass("activetab");this.filtertype=4;
         this.loadcallsdata(this.filterselected,this.selecteddate,4);
      }
      if(type==3){
         $("#tabs3").addClass("activetab");this.filtertype=2;
         this.loadcallsdata(this.filterselected,this.selecteddate,2);
      }
      if(type==4){
         $("#tabs4").addClass("activetab");this.filtertype=3;
         this.loadcallsdata(this.filterselected,this.selecteddate,3);
      }
      if(type==5){
         $("#tabs5").addClass("activetab");this.filtertype=5;
         this.loadcallsdata(this.filterselected,this.selecteddate,5);
      }
      if(type==5){ $("#tabs5").addClass("activetab");this.filtertype=5;}
      if(type==6){ $("#tabs6").addClass("activetab");this.filtertype=6;}
      if(type==7){ $("#tabs7").addClass("activetab");this.filtertype=7;}
      if(type==8){ $("#tabs8").addClass("activetab");this.filtertype=8;}
      if(type==9){ $("#tabs9").addClass("activetab");this.filtertype=9;
      this.loadcallsdata(this.filterselected,this.selecteddate,9);
      }
      if(type==10){ $("#tabs10").addClass("activetab");this.filtertype=10;
      this.loadcallsdata(this.filterselected,this.selecteddate,10);
      }
    }
   
    islive:boolean=false;
    getcallDetails(type){
      // alert(type);
      this.filterselected=[];
      $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
      if(type==1){
         this.islive = false;
         $("#calltabs1").addClass("activetab");this.filtertype=1;
         this.loadcalls(this.filterselected,this.selecteddate);
      }
      if(type==2){
         this.islive = true;
         $("#calltabs2").addClass("activetab");this.filtertype=4;
         this.loadlivecalls();
      }    
    }
  
    
    scrolltoy(){
      $('datatable-body').scrollLeft(1000);
    // $('datatable-body').scrollLeft += 800;
      // this.bodyComponent.updateOffsetY(50);
      // document.getElementByClass('datatable-body').scrollLeft += 800;
    }
    scrolltox(){
      $('datatable-body').scrollLeft(0);
      // document.getElementById('datatable-body.datatable-body').scrollLeft -= 800;
    }
  
    filterbyvalue(uuid,value,type = 0){
      // console.log(uuid);
      // console.log(value); return false;
      // this.togglepanel();
      this.istougle = 1;
      this.isclick = 1;
      this.filterdata(this.filtertype);
      let customObj = new filterarr();
      customObj.filtertype = this.filtertype;
      customObj.uuid = uuid; 
      customObj.name = value; 
      customObj.isorand = 1; 
      customObj.isdup = type; 
      // console.log(this.selectElementText);
      if(value!=""){
        this.filterselected.push(customObj);  
      }
      // console.log(this.filterselected);return    
      this.filterval('');
    }
  
    
    ngOnDestroy(){
      this.dtTrigger.unsubscribe();
      this.subscription.unsubscribe();
      // console.log('Report method destroyed');    
    }  
  
  
    play(url: string, incomingnumber:any, date:any){
      // console.log(res.data[0]);
      let urltype;
      if(date <= "2022-10-17"){
        urltype = 'https://repuatationmanagement.s3.us-west-2.amazonaws.com/';
      }else{
        urltype = "https://d2pktjhs7fqsvz.cloudfront.net/"; 
      }
      
  
      this.toastrService.show(`<audio style="width: 130px;height: 30px;" controls><source src="${urltype}${url}" type="audio/mp3"></audio>`,
      'Inbound # - '+incomingnumber , {
        closeButton: true,
          extendedTimeOut: 0,
          timeOut: 0,
          
          tapToDismiss: false,
               enableHtml: true,
               positionClass: 'toast-bottom-right' ,
           });
    }
    copyName(val: string){
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);   
    }
  
    
    filtercalldata(filtertype,uuid:any, no:any){
    
      
      this.istougle = 1;
      this.isclick = 1;
      
      this.filterdata(filtertype);
      let customObj = new filterarr();
      customObj.filtertype = filtertype;
      customObj.uuid = uuid; 
      customObj.name = no; 
      customObj.isorand = 1; 
      // console.log(this.selectElementText);
      if(no!=""){
        this.filterselected.push(customObj);  
      }
      this.filter = 1;
      this.isselectElement = 1;
      // this.daytypeval=2;
      // console.log(this.filterselected);
      // console.log(this.selecteddate);
      
      this.loadcalls(this.filterselected,this.selecteddate);
      this.loadcallsdata(this.filterselected,this.selecteddate,1);
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval);    
      // this.filterval('');
    }
    
  
  }
  