import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from './../../../global';
declare var $;
const router = Router;

@Component({
  selector: 'app-basic-setting',
  templateUrl: './basic-setting.component.html',
  styleUrls: ['./basic-setting.component.scss']
})
export class BasicSettingComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
    error: {};
    showLoadingIndicator: any;
    private http: HttpClient;
    public settings: any;
    addForm: FormGroup;
    currentCompany: any;
    Companyuuid: any;
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
    ) {
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.Companyuuid = this.currentCompany.uuid;
      this.http = new HttpClient(handler);
     }
  
    ngOnInit(): void {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.getAllbasicsetting();
      this.addForm = this.fb.group({
        // , Validators.required
        googlesheetlink : [''],
        xyzrules : [''],   
       });
    }

  getAllbasicsetting(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentUser'));
    this.moduleservice.getAllbasicsetting(this.currentCompany.uuid).subscribe(
      res => {
        //  console.log(res); return false;  
        if(res['xyz_rules']==1){
          this.xyzrules = true;
        }else{
          this.xyzrules = false;
        }
       
        this.addForm.patchValue({
          googlesheetlink: res['google_sheet_link'],               
          xyzrules: this.xyzrules,               
        });             
      },
      error => this.error = error,
    );  
  }  
  
  
  xyzrules:any=true;
  xyzrules_enable(event:any){
    this.xyzrules = event.target.checked; 
  }  
  
  Submitted = false;
  //Add Publisher
  get addformcontrol() { return this.addForm.controls; }  
  onSubmit () {
      const formData = new FormData();      
      this.Submitted = true;
      if (this.addForm.invalid) {
          return;
      }
      
      this.currentCompany = JSON.parse(localStorage.getItem('currentUser'));
      formData.append('user_uuid', this.currentCompany.uuid);
      formData.append('google_sheet_link', this.addForm.get('googlesheetlink').value);
      formData.append('xyz_rules', this.xyzrules);
      
      // console.log(formData); return false;
  
      this.moduleservice.addBasicSettingdata(formData).subscribe(
        res => {
          // console.log(res.status); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Basic Setting Saved Successfully');  
            this.getAllbasicsetting();
            this.Submitted = false;
          }else{
            this.toastrService.error('Failed To Save Settings');
          }
        },
        error => this.error = error,
      );     
     }
  
  }
  