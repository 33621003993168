import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalVariable } from '../global';
import { ModuleService } from '../modules/module.service';
import { interval, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  subscription: Subscription;
  intervalId: number;

  constructor(
    private moduleservice: ModuleService,
    public router:Router,
    private toastrService:ToastrService,
  ) { }
  public userName:string;
  public firstName: string;
  public lastName: string;
  public role: number;
  public companies: any;
  showLoadingIndicator: any;
  timeIntevalSeconds:number = 0;
  ngOnInit() {
    var loggedinuser = JSON.parse(localStorage.getItem('currentUser'));
    if(loggedinuser){
    this.firstName = loggedinuser.first_name;
    this.lastName = loggedinuser.last_name;
    this.role = loggedinuser.role;
    if(this.role==2){
      this.companies = loggedinuser.companydetails;
      // console.log(this.companies);
    }
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.getcallvalue();
    const source = interval(30000);
    this.subscription = source.subscribe(val => this.getcallvalue());    
    }
  }



  currentUser:any;
  currentCompany:any;
  livecall:any;
  completedcalls:any;
  getcallvalue(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getcallvalue(this.currentCompany.uuid,this.currentUser.timezone).subscribe(
      res => {
        // console.log(res);return false;
        this.livecall = res.livecall;
        this.completedcalls = res.completedcall;
      }      
    );     
  }

  logout() {
    localStorage.clear();  
    this.router.navigate(['./login']);  
  }

  private jsonObj: any = {};
  switchcompany(uuid){
    this.moduleservice.getcompanydetailsbyuuid(uuid).subscribe(
      res => {
        var loggedinuser = JSON.parse(localStorage.getItem('currentUser'));
        // console.log(loggedinuser);return false;
        this.completedcalls = res.completedcall;
          this.jsonObj = {
            "user_id":  loggedinuser.id,
            "uuid": loggedinuser.uuid,
            "company_uuid": loggedinuser.company_uuid,
            "first_name": loggedinuser.first_name,
            "last_name": loggedinuser.last_name,
            "pic": loggedinuser.pic,
            "token": loggedinuser.token,
            "role": loggedinuser.role,
            "companydetails" : res.companies,
            "timezone" : loggedinuser.timezone
          }
      // console.log(this.jsonObj);return false;
        this.companies = res.companies;
        
        localStorage.setItem('currentCompany', JSON.stringify(res.companydetails));     
        localStorage.setItem('currentUser', JSON.stringify(this.jsonObj));     
        // this.showLoadingIndicator= true;
       
        // this.router.navigate(['/'])
        setTimeout(()=>{
          this.showLoadingIndicator= false; 
          window.location.reload();
        },1000);
        // this.ngOnInit();
        if (res) {
          this.toastrService.success('Welcome To '+res.companydetails.name);         
        }else{
          this.toastrService.error('Failed To Switch Company');
        }
        

      }      
    );     
  }

  clickcount:any=0;
  getSidebar(){
    if(this.clickcount==1){
      // $('body').removeClass("sidebar-mini");      
      $('body').addClass("sidebar");                 
      this.clickcount =0;
    }else{
      $('body').removeClass("sidebar");      
      // $('body').addClass("sidebar-mini");    
      $('body').removeClass("sidebar-gone");      
      this.clickcount =1;
    }

  }
}
