import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from '../../module.service';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
// import { int } from 'aws-sdk/clients/datapipeline';
declare var $;
import 'sweetalert2/src/sweetalert2.scss'
import { getDeferredAnimation } from 'highcharts';
// import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
    error: string;
    addForm: FormGroup;
    submitted = false;
    private http: HttpClient;
  
    public currentUser:any;
    public currentCompany:any;
  
    constructor(
      handler: HttpBackend,
      private fb: FormBuilder,
      private moduleService: ModuleService,
      private router: Router,
      private route: ActivatedRoute,
      private toastrService:ToastrService, 
      private cdRef: ChangeDetectorRef      
    ){
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.http = new HttpClient(handler);
    }  
  
    alltimezone:any;
    gettimezone(){
      this.moduleService.gettimezone().subscribe(
        res => {
          this.alltimezone = res;
          // console.log(res);          
        }
      );
    }  
  
    ngAfterViewChecked(): void {
      this.cdRef.detectChanges(); 
    }
  
  
  ngOnInit() {      
      this.addForm = this.fb.group({
        username: ['',Validators.required],      
        password: ['',Validators.required],      
        callerName: [''],      
        callerNumber: [''],      
        status: [''],      
        account: [''],      
        sip_profiles: [''],      
        enable: [''],      
        vmpassword: [''], 
        mailto: [''], 
        attach_file: [''], 
        local_after_email: [''], 
        send_all_message: [''],                 
      });
      // console.log(id);
      this.getData();   
    }

    sipdata:any;
    getData(){
      const id = this.route.snapshot.paramMap.get('id');
      this.moduleService.getsipuserdata(id).subscribe(
        res => {
          // this.sipdata = res;
        
          let params = JSON.parse(res.dir_params) 
          let vars = JSON.parse(res.dir_vars) 
          // console.log(params); 
          this.addForm.patchValue({
            username:res.username,
            password:params.password,
            callerName:vars['effective_caller_id_name'],
            callerNumber:vars['effective_caller_id_number'],
            status:res.status,
            account:res.accountid,
            sip_profiles:res.sip_profile_id,
            enable:params['vm-enabled'],
            vmpassword: params['vm-password'],
            mailto: params['vm-mailto'],
            attach_file:params['vm-attach-file'],
            local_after_email:params['vm-keep-local-after-email'],
            send_all_message:params['vm-keep-local-after-email'],
          });          
        }
      );
    }
  
    get f() { return this.addForm.controls; }  
    onSubmit () {
      const formData = new FormData();      
      this.submitted = true;
      if (this.addForm.invalid) {
          return;
      }
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     
    let arr = {};
    arr['password'] = this.addForm.get('password').value;			
		if(this.addForm.get('enable').value == ""){
			arr['vm-enabled'] = 'true';			
		}else{
			arr['vm-enabled'] = this.addForm.get('enable').value;			
		}
		if(this.addForm.get('vmpassword').value != ""){
			arr['vm-password'] = this.addForm.get('vmpassword').value;			
		}
		if(this.addForm.get('mailto').value != ""){
			arr['vm-mailto'] = this.addForm.get('mailto').value;			
		}
		if(this.addForm.get('attach_file').value == ""){
			arr['vm-attach-file'] = 'true';			
		}else{
			arr['vm-attach-file'] = this.addForm.get('attach_file').value;			
		}
		if(this.addForm.get('local_after_email').value == ""){
			arr['vm-keep-local-after-email'] = 'true';			
		}else{
			arr['vm-keep-local-after-email'] = this.addForm.get('local_after_email').value;			
		}
		if(this.addForm.get('send_all_message').value == ""){
			arr['vm-email-all-messages'] = 'true';			
		}else{
			arr['vm-email-all-messages'] = this.addForm.get('send_all_message').value;			
		}

    let dir_vars = {};
		
		if(this.addForm.get('callerName').value != ""){
			dir_vars['effective_caller_id_name'] = this.addForm.get('callerName').value;		
		}
		if(this.addForm.get('callerNumber').value != ""){
			dir_vars['effective_caller_id_number'] = this.addForm.get('callerNumber').value;			
		}
    
      formData.append('company_uuid', this.currentCompany.uuid);
      formData.append('user_uuid', this.currentUser.uuid);
      formData.append('username', this.addForm.get('username').value);
      formData.append('sip_profile_id', this.addForm.get('sip_profiles').value);  
      formData.append('accountid', this.addForm.get('account').value);
      formData.append('dir_params', JSON.stringify(arr));
      formData.append('dir_vars', JSON.stringify(dir_vars));
      formData.append('status', this.addForm.get('status').value);
      
      // formData.append('timezone', this.addForm.get('timezone').value);  
      const id = this.route.snapshot.paramMap.get('id');
      this.moduleService.updateSIPUser(formData,id).subscribe(
        res => {
          // console.log(res); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Sip User Updated Successfully');
            // this.isTargetName = false;
            // localStorage.removeItem('FSLRulesInsertID');
            this.router.navigate(['/sip-users']);
            // this.router.navigate(['/numbers']);
          }else{
            this.toastrService.error('Failed To Add');
          }
        },
        error => this.error = error,
      );     
    }
  
 
  
  }
