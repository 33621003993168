import { Component, OnInit } from '@angular/core';
import { ModuleService } from './../../modules/module.service';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    profileForm: FormGroup;
    error: any;
    currentCompany: any;
    submitted = false;
    showLoadingIndicator: boolean;
    constructor(
      private fb: FormBuilder,
      private moduleService: ModuleService,
      private router: Router,
      private route: ActivatedRoute,
      private toastrService:ToastrService,
    ) {
      this.currentCompany = JSON.parse(localStorage.getItem('currentUser'));
     }
    recordingval:number;
    ngOnInit() {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      
      this.profileForm = this.fb.group({
        firstname: ['',Validators.required],       
        lastname: ['',Validators.required],       
        password: ['',''],       
        timezone: [''],       
      });    
    const company_uuid = this.currentCompany.uuid;
    // console.log(this.currentCompany);
        if (company_uuid) {
          this.moduleService.getProfiledata(company_uuid).subscribe(
            res => {
              this.recordingval = res.is_recording;
              // console.log(res);
              this.profileForm.patchValue({
                firstname: res.first_name,               
                lastname: res.last_name,                               
                timezone: res.timezone,                               
              });              
            }
          );
      } 

      this.gettimezone();
    }
  
    alltimezone:any;
    gettimezone(){
      this.moduleService.gettimezone().subscribe(
        res => {
          this.alltimezone = res;
          // console.log(res);          
        }
      );
    }  

    recording:string;
    get_recordingstatus(event:any){
      this.recording = event.target.checked;
    }

    private jsonObj: any = {};
    get f() { return this.profileForm.controls; }
    onSubmit () {
      const formData = new FormData();      
      this.submitted = true;
      if (this.profileForm.invalid) {
          return;
      }
      formData.append('firstname', this.profileForm.get('firstname').value);
      formData.append('lastname', this.profileForm.get('lastname').value);
      formData.append('password', this.profileForm.get('password').value);
      formData.append('timezone', this.profileForm.get('timezone').value);
      formData.append('is_recording', this.recording);
      
      

      const company_uuid = this.currentCompany.uuid;
      if (company_uuid) {
        this.moduleService.editProfile(formData, company_uuid).subscribe(
          res => {
            // console.log(res); return false;
            if (res.status !== 'error') {
              var userdata = JSON.parse(localStorage.getItem('currentUser'));
              // console.log(this.profileForm.get('timezone').value);return false;
              userdata.timezone = this.profileForm.get('timezone').value;
              // console.log(userdata);return false;
              localStorage.setItem('currentUser', JSON.stringify(userdata));
              this.toastrService.success('Profile Updated Successfully');            
            }else{
              this.toastrService.success('Failed To Save! Please Try Again Later..');
            }
          },
          error => this.error = error
        );
      }   
    }
}
