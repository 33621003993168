import { FormGroup } from '@angular/forms';
import { ModuleService } from '../../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-label-view',
  templateUrl: './label-view.component.html',
  styleUrls: ['./label-view.component.scss']
})

export class LabelViewComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    number: Number;
    action: any;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    numbers: any[];
    that: Number;
    private http: HttpClient;
    currentCompany: any;
    ischecked: string = '1';
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private route: ActivatedRoute,
      private ngxLoader: NgxUiLoaderService
    ){ 
      this.http = new HttpClient(handler);      
    }
  
    ngOnInit() {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      const labelname = this.route.snapshot.paramMap.get('labelname');
      this.loadNumbers(labelname);  
      // console.log(labelname);
    }
    loadNumbers(labelname:string){
      if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
        $(this.table.nativeElement).dataTable().fnDestroy();
      }    
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.dtOptions = {
        "pagingType": 'full_numbers',
        "processing": true,
        "serverSide": true,          
        "pageLength": 50,        
        "lengthMenu": [50,100,500,1000,2000,5000],
        "order": [[ 1, "asc" ]],
        ajax: (dataTablesParameters: any, callback) => {
          // this.http.post<DataTablesResponse>(
          //   this.ServerUrl+'getnumbersdata/'+this.currentCompany.uuid,
          //   dataTablesParameters, {}
          this.http.post<DataTablesResponse>(
            this.ServerUrl+'getnumbersdata/'+this.currentCompany.uuid+'/'+labelname, {'dataTablesParameters':dataTablesParameters,'number':"",'campaign':"",'labels':""}
           , {}
          ).subscribe(resp => {
                    this.numbers = resp.data;   
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
                });
        },
        columns: [
          // ,{data:"target"}
          {data:"checkall"},{data:"number"},{data:"campaign"},{data:"publisher"},{data:"status"},{data:"created_at"},{data:"assosciated_label"},{data:"action"},
        ],
        columnDefs: [
          { 
              "targets": [0], 
              "orderable": false,
          },
          ],
    };

  this.dataTable = $(this.table.nativeElement);
  this.dataTable.dataTable(this.dtOptions);
     
    }
  
    Delete(uuid: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Record!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.moduleservice.deleteNumberdata(uuid,currentUser.uuid).subscribe(
              res => {
                // this.toastrService.success('Number Deleted Successfully');
                Swal.fire(
                  'Deleted!',
                  'Record Deleted Successfully.',
                  'success',                
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                
                const labelname = this.route.snapshot.paramMap.get('labelname');
                this.loadNumbers(labelname);  
              },
              error => this.error = error
          );       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
  
  
    exportNumber:any = {};
    finalexportNumber:any = [];
    countNo:any =0;
    exportdata(){
      var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true, 
        // showTitle: true,
        // title: 'Your title',
        useBom: true,
        // noDownload: true,
        headers: ["Number", "Country", "Campaign","Publisher","Status","Allocated"]
      };

      
      // for (let index = 0; index < this.numbers.length; index++) {
        
      //   const element = this.numbers[index];

      //   // this.finalexportNumber.push({[index]:element.number});
      //   // this.finalexportNumber.push({[index]:element.country});
      //   this.exportNumber[index] = element.number;
      //   this.exportNumber[index+1] = element.country;
      //   this.exportNumber[index+2] = element.campaign;
      //   this.exportNumber[index+3] = element.publisher;
      //   this.exportNumber[index+4] = element.target;
      //   this.exportNumber[index+5] = element.created_at;
        
      //   // this.finalexportNumber[this.countNo] = this.exportNumber;
      //   this.finalexportNumber.push(this.exportNumber);
      //   // console.log(this.exportNumber);
      //   // this.countNo++;
      // }
      // console.log(this.finalexportNumber);
      // return
      // new   ngxCsv(this.finalexportNumber, 'Label-Numbers', options);
    //   this.numbers.forEach((element,index)=>{        
    //     this.exportNumber.number = element.number;
    //     this.exportNumber.country = element.country;
    //     this.exportNumber.campaign = element.campaign;
    //     this.exportNumber.publisher = element.publisher;
    //     this.exportNumber.target = element.target;
    //     // this.exportNumber.assosciated_labels = element.assosciated_labels;
    //     this.exportNumber.created_at = element.created_at;
    //     // console.log(index);
    //     this.finalexportNumber[index] = this.exportNumber;
        
    //  });
    //  console.log(this.finalexportNumber);
    //  return false; 
      // this.ngxLoader.start();
      this.showLoadingIndicator = true;
      // console.log('ok');
      // return false; 
      const labelname = this.route.snapshot.paramMap.get('labelname');
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleservice.getNumberForExport(this.currentCompany.uuid,labelname).subscribe(
        res => {
          // console.log(res);return
          // this.ngxLoader.stop();
          new ngxCsv(res, 'Label-Numbers', options);
          this.showLoadingIndicator = false;
        },
        error => this.error = error
    );    
  
    }

    checkalldelete(event) {
      const checked = event.target.checked;
      this.numbers.forEach(chkbox => chkbox.selected = checked);    
    }
  
    checkboxes:any = [];  
    deleteselected(event:any){ 
      // $("#customCheck1").prop("checked", false);    
      if(event.target.checked==true)
      {
        if(event.target.value=='on'){
          this.checkboxes = [];
          this.numbers.forEach(lbl => {
            this.checkboxes.push(lbl.id);
          });
        }else{ 
          if(this.checkboxes.length+1==this.numbers.length){
            $( "#customCheck1" ).prop( "checked", true );
          }
          this.checkboxes.push(event.target.value);            }            
          this.ischecked = '0';
      }else{ 
        // console.log(this.checkboxes);      
        if(this.checkboxes.length!=0){
          $( "#customCheck1" ).prop( "checked", false );        
        }
        if(event.target.value!='on'){
          const index = this.checkboxes.indexOf(event.target.value);
          this.checkboxes.splice(index, 1);
        }else{
          this.checkboxes = [];
        }
        this.ischecked = '1';
      }
      // console.log(this.checkboxes); 
    }
  
    onDeleteSubmit(){    
      if(this.checkboxes.length==0){
        this.toastrService.error('Please Select Row To Delete');
      }else{
        // console.log(this.checkboxes);  
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to Delete Selected Record!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
  
            this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
              res => {
                  Swal.fire(
                    'Deleted!',
                    'Records Deleted Successfully.',
                    'success',                
                  )
                  setTimeout(() => {
                    Swal.close()
                  }, 2000);
                  const labelname = this.route.snapshot.paramMap.get('labelname');
                  this.loadNumbers(labelname);  
                },
                error => this.error = error
            );      
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your Record is safe :)',
              'error'
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
          }
        });
      }
      
    }
  
}
