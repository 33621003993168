import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModuleService } from '../../module.service';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from './../../../global';
declare var $;
const router = Router;

@Component({
  selector: 'app-alert-configuration',
  templateUrl: './alert-configuration.component.html',
  styleUrls: ['./alert-configuration.component.scss']
})
export class AlertConfigurationComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
    private BaseUrl = GlobalVariable.BASE_URL;
    error: {};
    showLoadingIndicator: any;
    numbers: any;
    private http: HttpClient;
    public settings: any;
    addForm: FormGroup;
    currentCompany: any;
    Companyuuid: any;
    publicregistration:boolean;
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private cdRef: ChangeDetectorRef 
    ) {
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.Companyuuid = this.currentCompany.uuid;
      this.http = new HttpClient(handler);
      this.publicregistration =true;
     }
  
    ngAfterViewChecked(): void {
      this.cdRef.detectChanges(); 
    }

    ngOnInit(): void {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.getAllLabels();
      this.addForm = this.fb.group({
        // , Validators.required
        user_uuid : [''],
        nameofalert : [''],
        labelname : [''],
        calllength: [''],      
        onetimealert: [''],      
        systemalert: [''],      
       });
    }
    
    dropdownSettings = {};
    alllabels:any;
    getAllLabels(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getAllLabels(this.currentCompany.uuid).subscribe(
      res => {
        this.dropdownSettings = { 
          singleSelection: false, 
          text:"Select Label Name",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: true,
          classes:"myclass custom-class"
        };
        this.alllabels = res;
        //  console.log(res); return false;                    
      },
      error => this.error = error,
    );  
  }  
  
  isdontuselabel:any=false;
  dontuselabel(event:any){
    this.isdontuselabel = event.target.checked; 
  }  

  Submitted = false;
  //Add Publisher
  get addformcontrol() { return this.addForm.controls; }  
  onSubmit () {
      const formData = new FormData();      
      // this.Submitted = true;
      // if (this.addForm.invalid) {
      //     return;
      // }
      
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      formData.append('user_uuid', currentUser.uuid);
      formData.append('uuid', this.currentCompany.uuid);
      formData.append('public_registration',"");
      // formData.append('intro', this.addForm.get('intro').value);
      // formData.append('email', this.addForm.get('email').value);    
      formData.append('terms_cond', "");
      formData.append('tc_url', this.addForm.get('tc_url').value);
      
      // console.log(formData); return false;
  
      this.moduleservice.addPublisherSettingdata(formData).subscribe(
        res => {
          // console.log(res.status); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Publisher Setting Saved Successfully');  
            this.getAllLabels();
            this.Submitted = false;
          }else{
            this.toastrService.error('Failed To Add Label');
          }
        },
        error => this.error = error,
      );     
     }
  
  }
