import { FormGroup,Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from './../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-publishers',
  templateUrl: './publishers.component.html',
  styleUrls: ['./publishers.component.scss']
})
export class PublishersComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    number: Number;
    action: any;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    numbers: any;
    that: Number;
    private http: HttpClient;
    currentCompany: any;
    ischecked: string = '1';
  
    addForm: FormGroup;
    editForm: FormGroup;

  
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
    ){ 
      this.http = new HttpClient(handler);
    }
  
    ngOnInit() {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.loadpublishers();
      // this.loadNumbers();

      this.addForm = this.fb.group({
        name : ['', Validators.required],
        sub_id: [''],      
        number_creation: [''],      
        settingToggleBlockCallsIfCapped: [''],      
        settingToggleAccessToRecordings: [''],             
        user_uuid: [''],             
      });
     
      this.editForm = this.fb.group({
        uuid: [''],      
        name : ['', Validators.required],
        sub_id: [''],      
        number_creation: [''],      
        settingToggleBlockCallsIfCapped: [''],      
        settingToggleAccessToRecordings: [''],             
        user_uuid: [''],             
      });
    }

    // numberdata:any;
    // loadNumbers(){
    //   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    //   this.moduleservice.loadNumbersfordropdown(this.currentCompany.uuid).subscribe(
    //     res => {
    //       this.numberdata= res
    //       },
    //       error => this.error = error
    //   );
    // }
    
    loadpublishers(){
          // console.log('ok');
          // return false;
            if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
              $(this.table.nativeElement).dataTable().fnDestroy();
            }    
            this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
           this.dtOptions = {
              "pagingType": 'full_numbers',
              "processing": true,
              "serverSide": true,          
              "pageLength": 25,            
              "order": [[ 0, "asc" ]],
              ajax: (dataTablesParameters: any, callback) => {
                this.http.post<DataTablesResponse>(
                  this.ServerUrl+'getpublisher/'+this.currentCompany.uuid,
                  dataTablesParameters, {}
                ).subscribe(resp => {
                          this.numbers = resp.data;   
                          callback({
                              recordsTotal: resp.recordsTotal,
                              recordsFiltered: resp.recordsFiltered,
                              data: [],
                          });
                      });
              },
              columns: [
                {data:"name"},{data:"sub_id"},{data:"create_number"},{data:"settingToggleBlockCallsIfCapped"},{data:"settingToggleAccessToRecordings"},{data:"created_at"},{data:"action"},
              ],
              columnDefs: [
                { 
                    "targets": [0], 
                    "orderable": false,
                },
                ],
          };
      
        this.dataTable = $(this.table.nativeElement);
        this.dataTable.dataTable(this.dtOptions);
    }

  settingToggleAccessToRecordingsvaldata:number=0;  
  settingToggleBlockCallsIfCappeddata:number=0;  
  editdata(uuid:string,name:string,sub_id:string,create_number:number,settingToggleBlockCallsIfCapped:number,settingToggleAccessToRecordings:number){
    this.settingToggleAccessToRecordingsvaldata = settingToggleAccessToRecordings;
    this.settingToggleBlockCallsIfCappeddata = settingToggleBlockCallsIfCapped;
    this.editForm.patchValue({
      uuid : uuid,
      name : name,
      sub_id: sub_id,      
      number_creation: create_number,      
      settingToggleBlockCallsIfCapped: settingToggleBlockCallsIfCapped,      
      settingToggleAccessToRecordings: settingToggleAccessToRecordings,        
    });    
  }
  
  
    checkalldelete(event) {
      const checked = event.target.checked;
      this.numbers.forEach(chkbox => chkbox.selected = checked);    
    }
  
    checkboxes:any = [];  
    deleteselected(event:any){ 
      // $("#customCheck1").prop("checked", false);    
      if(event.target.checked==true)
      {
        if(event.target.value=='on'){
          this.checkboxes = [];
          this.numbers.forEach(lbl => {
            this.checkboxes.push(lbl.id);
          });
        }else{ 
          if(this.checkboxes.length+1==this.numbers.length){
            $( "#customCheck1" ).prop( "checked", true );
          }
          this.checkboxes.push(event.target.value);            }            
          this.ischecked = '0';
      }else{ 
        // console.log(this.checkboxes);      
        if(this.checkboxes.length!=0){
          $( "#customCheck1" ).prop( "checked", false );        
        }
        if(event.target.value!='on'){
          const index = this.checkboxes.indexOf(event.target.value);
          this.checkboxes.splice(index, 1);
        }else{
          this.checkboxes = [];
        }
        this.ischecked = '1';
      }
      // console.log(this.checkboxes); 
    }
  
    onDeleteSubmit(){    
      if(this.checkboxes.length==0){
        this.toastrService.error('Please Select Row To Delete');
      }else{
        // console.log(this.checkboxes);  
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to Delete Selected Record!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
  
            this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
              res => {
                  Swal.fire(
                    'Deleted!',
                    'Records Deleted Successfully.',
                    'success',                
                  )
                  setTimeout(() => {
                    Swal.close()
                  }, 2000);
                  this.loadpublishers();
                },
                error => this.error = error
            );      
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your Record is safe :)',
              'error'
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
          }
        });
      }
      
    }
  
    Delete(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Record!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.moduleservice.deletePublisher(+id,currentUser.uuid).subscribe(
              res => {
                this.loadpublishers();
                // this.toastrService.success('Number Deleted Successfully');
                Swal.fire(
                  'Deleted!',
                  'Record Deleted Successfully.',
                  'success',                
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                
                
              },
              error => this.error = error
          );       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
  

settingToggleBlockCallsIfCappedval(val:number) {
  this.addForm.get('settingToggleBlockCallsIfCapped').setValue(val); 
} 
settingToggleAccessToRecordingsval(val:number) {
  this.addForm.get('settingToggleAccessToRecordings').setValue(val); 
}  

settingToggleBlockCallsIfCappededit:number = 0;
settingToggleBlockCallsIfCappedvaledit(val:number) {
  this.settingToggleBlockCallsIfCappededit = val;
  this.editForm.get('settingToggleBlockCallsIfCapped').setValue(val); 
} 
settingToggleAccessToRecordingsedit:number = 0;
settingToggleAccessToRecordingsvaledit(val:number) {
  this.settingToggleAccessToRecordingsedit = val;
  this.editForm.get('settingToggleAccessToRecordings').setValue(val); 
}    

Submitted = false;
//Add Publisher
get addformcontrol() { return this.addForm.controls; }  
onSubmit () {
    const formData = new FormData();      
    this.Submitted = true;
    if (this.addForm.invalid) {
        return;
    }
    
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('name', this.addForm.get('name').value);
    formData.append('sub_id', this.addForm.get('sub_id').value);    
    formData.append('number_creation', this.addForm.get('number_creation').value);
    formData.append('settingToggleBlockCallsIfCapped', this.addForm.get('settingToggleBlockCallsIfCapped').value);
    formData.append('settingToggleAccessToRecordings', this.addForm.get('settingToggleAccessToRecordings').value);
 
    // console.log(formData); return false;

    this.moduleservice.addPublisher(formData).subscribe(
      res => {
        // console.log(res.status); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Publisher Added Successfully');  
          this.loadpublishers();
          this.Submitted = false;          
  
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );     
   }

editSubmitted = false;
//Add Buyer
get editFormcontrol() { return this.editForm.controls; }  
onEditSubmit () {
  // alert('ok');return false;
    const formData = new FormData();      
    this.editSubmitted = true;
    if (this.editForm.invalid) {
        return;
    }
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('uuid', this.editForm.get('uuid').value);
    formData.append('name', this.editForm.get('name').value);
    formData.append('sub_id', this.editForm.get('sub_id').value);    
    formData.append('number_creation', this.editForm.get('number_creation').value);
    formData.append('settingToggleBlockCallsIfCapped', this.editForm.get('settingToggleBlockCallsIfCapped').value);
    formData.append('settingToggleAccessToRecordings', this.editForm.get('settingToggleAccessToRecordings').value);
    // console.log(formData); 
  
    this.moduleservice.editPublisher(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Publisher Updated Successfully');  
          this.loadpublishers();
          this.editSubmitted = false;       
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );     
   }


   closeModal() {    
    if(this.addForm.get('name').value!='')
    {
      $("#addLabels").modal("hide");         
      // $(".custom-switch-input").prop('checked',false);
      this.Submitted = false;
      setTimeout(() => { this.addForm.reset();}, 2000);    
    }
  }
   closeEditModal() {    
    if(this.editForm.get('name').value!='')
    {
      $("#editLabels").modal("hide");         
      this.editSubmitted = false;
      setTimeout(() => { this.editForm.reset();}, 2000);    
    }
  }

}