import { ModuleService } from './../module.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss'

@Component({
  selector: 'app-mail-verification',
  templateUrl: './mail-verification.component.html',
  styleUrls: ['./mail-verification.component.scss']
})
export class MailVerificationComponent implements OnInit {
  error: {};
  currentCompany: any;
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    private route: ActivatedRoute,
    private router: Router,
  ){ 
    // this.http = new HttpClient(handler);
  }

  ngOnInit(): void {
  }

  uuid:any;

  //verify Publisher
onSubmit () {
    const formData = new FormData();      
    this.uuid = this.route.snapshot.paramMap.get('uuid');     
    this.moduleservice.verifyEmail(1,this.uuid).subscribe(
      res => {
        // console.log(res.status); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Publisher Accounts Verified Successfully! Please Login to continue..');
          setTimeout(()=>{this.router.navigate(['/login']); },2000);             
        }else{
          this.toastrService.error('Failed To Verify Accounts or Already Verified..');
        }
      },
      error => this.error = error,
    );     
   }

}
