
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router,private route: ActivatedRoute) {}
  user:any;
  id:any;
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): boolean {
  //   const url: string = state.url;
  //   return this.checkLogin(url);
  // }
  

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    // console.log(url.split("/"));
    // console.log(route.params.id);

    
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.id = this.route.snapshot.paramMap.get('id');

    this.checkLogin(url);
    
    // if(url != '/buyer' && url != '/profile' && url != '/add-target' && url != '/edit-numbers/' && url != '/campaign-wizard' && url != '/edit-campaign/this.id' && url != '/new-ivr-menu' && url != '/edit-ivr/'){
    // this.authService.get_permission(url,this.user.role).subscribe(
    //   res => {
    //     // console.log(res);
    //     if(res == 'no'){
    //       // if(this.user.user_type == 1){
    //         // this.router.navigate(['/']);
    //       // }         
    //     }
    //   }
    // );
    // }

    if(this.user.role==1){
      if(url != '/notes' && url != '/reports' && url != '/'  && url != '/profile'  && url != '/labels'  && url != '/rules'  && url != '/reports-details' && url != '/comparison-reports' && url!='/all-calls-reports-details' && route.params.id==undefined){
        this.router.navigate(['/']);
      }
    }
   
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string) {
    // console.log(localStorage.getItem('currentUser'));
    
    if (this.authService.isLoggedIn()) {
      return true;
    }

    this.authService.redirectUrl = url;

    this.router.navigate(['/login']);
  }
}
