import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {RequestOptions } from '@angular/http';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';

let requestOptions = new RequestOptions({ headers:null, withCredentials: 
  true });

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

const ParseHeaders = {
  headers: new HttpHeaders({
   'Content-Type'  : 'application/x-www-form-urlencoded'
  })
 };

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  rule: Number;
  action: any;
  error: {};
  showLoadingIndicator: any;
  dtOptions: DataTables.Settings = {};
  // numbers: Number[];
  rules: any;
  that: Number;
  private http: HttpClient;
  currentCompany: any;
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend
  ){ 
    this.http = new HttpClient(handler);
  }

  ngOnInit() {
     this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.loadRules();
  }
  loadRules(){
          if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
            $(this.table.nativeElement).dataTable().fnDestroy();
          }      
          this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
          var that = this;
          this.dtOptions = {
            "pagingType": 'full_numbers',
            "processing": true,
            "serverSide": true,          
            "pageLength": 20,
            "order": [[ 2, "desc" ]],
            ajax: (dataTablesParameters: any, callback) => {
              that.http.post<DataTablesResponse>(
                this.ServerUrl+'getrules/'+this.currentCompany.uuid,                
                dataTablesParameters, {}
              ).subscribe(resp => {
                        that.rules = resp.data;   
                        // console.log(that.numbers);
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
              {data:"name"},{data:"created_at"},{data:"action"},
            ],
        };
    
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.dataTable(this.dtOptions);
  }

  Delete(uuid: any) {
    // alert(uuid);return false;
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Record!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.moduleservice.deleteRuleset(uuid).subscribe(
              res => {
                // console.log(res);return false;
                Swal.fire(
                  'Deleted!',
                  'Record Deleted Successfully.',
                  'success',                
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
  
                this.loadRules();
              },
              error => this.error = error
          );       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }

}
