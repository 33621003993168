import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from '../../module.service';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss'
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { int } from 'aws-sdk/clients/datapipeline';
declare var $;
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';


class FSLClass {
  forlocation: [];
  forwarding: [];  
}

@Component({
  selector: 'app-numberadd',
  templateUrl: './numberadd.component.html',
  styleUrls: ['./numberadd.component.scss'],  
})

export class NumberaddComponent implements OnInit {
  error: string;
  addForm: FormGroup;
  addBuyerForm: FormGroup;
  submitted = false;
  private http: HttpClient;

  addFSLRules: FormGroup;
  editFSLRules: FormGroup;
  FSLRulesSubmitted = false;
  FSLRulesEditSubmitted = false;

  addWeekDaysForm: FormGroup;
  editWeekDaysForm: FormGroup;
  daysSubmitted = false;
  daysEditSubmitted = false;
  
  noofforwarding: number; 
  noofforwardinglocation_1: number; 
  noofforwardinglocation_2: number; 
  noofforwardinglocation_3: number; 
  noofforwardinglocation_4: number; 
  noofforwardinglocation_5: number; 
  noofforwardinglocation_6: number; 
  noofforwardinglocation_7: number; 
  public fslrule: any; 
  public currentUser:any;
  public currentCompany:any;
  rootNode: any;

  dropdownSettingss:IDropdownSettings;
  ivrdropdownSettingss:IDropdownSettings;
  dropdownList = [];
  selectedItems = [];
  // dropdownSettings: { singleSelection: boolean; text: string; selectAllText: string; unSelectAllText: string; enableSearchFilter: boolean; classes: string; };
  dropdownSettings = {};
  ivrdropdownSettings = {};
  // citystatedropdownSettings = {};
  citydropdownSettings = {};


  constructor(
    handler: HttpBackend,
    private fb: FormBuilder,
    private moduleService: ModuleService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService:ToastrService, 
    private cdRef: ChangeDetectorRef      
  ){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.http = new HttpClient(handler);
  }  

  alltimezone:any;
  gettimezone(){
    this.moduleService.gettimezone().subscribe(
      res => {
        this.alltimezone = res;
        // console.log(res);          
      }
    );
  }  

  forwardingLocationdata : any;
   forwarding : any;
   forwardingtimeframe : any;
   daytype : number = 0;
   flag : int = 0;

  closeModal() {
    if(this.noofforwarding)
    {
      $("#addFSLRules").modal("hide");    
      setTimeout(() => {
        this.addFSLRules.reset({
          'name': ' ',
          'numberoftimeframe': ' ',         
         });      
        this.getAllRules();
      }, 2000);    
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges(); 
  }

  editcloseModal() {
    if(this.noofforwarding)
    {
      $("#editFSLRules").modal("hide");    
      this.FSLRulesEditSubmitted=false;
      setTimeout(() => {        
        this.getAllRules();
        this.getRulesByName(this.addForm.get('fslrulesname').value);
      }, 2000);    
    }
  }

  public rulesData: any;
  public allRulesData: any;
  public allBuyers: any;
  public allRulesbyname: any;
  public FSLRulesInsertID: any;
  public ruletext: any;

  getAllRules(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getAllRules(this.currentCompany.uuid).subscribe(
      res => {
        //  console.log(res); return false;  
        this.allRulesData = res.allrules;
        this.allBuyers = res.buyers;
      },
      error => this.error = error,
    );  
  }

  getAllLabels(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getAllLabels(this.currentCompany.uuid).subscribe(
      res => {
        this.dropdownList = res
      },
      error => this.error = error,
    );  
  }


  getRulesByName(name: string){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getRulesByName(name,this.currentCompany.uuid).subscribe(
      res => {
        this.allRulesbyname = res;            
      },
      error => this.error = error,
    );  
  }

  _keyPress(event: any) {
    const isNumericInput = (event) => {
      const key = event.keyCode;
      return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
      );
    };
    
    const isModifierKey = (event) => {
      const key = event.keyCode;
      return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
          // Allow Ctrl/Command + A,C,V,X,Z
          (event.ctrlKey === true || event.metaKey === true) &&
          (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
    };
    
    const enforceFormat = (event) => {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
      }
    };
    
    const formatToPhone = (event) => {
      if(isModifierKey(event)) {return;}
    
      const target = event.target;
      const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
      const zip = input.substring(0,3);
      const middle = input.substring(3,6);
      const last = input.substring(6,10);
    
      if(input.length > 6){target.value = `(${zip}) ${middle}-${last}`;}
      else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
      else if(input.length > 0){target.value = `(${zip}`;}
    };
    const inputElement = document.getElementById('number');
    const number_0_day0 = document.getElementById('number_0_day0');
    const number_1_day0 = document.getElementById('number_1_day0');
    const number_2_day0 = document.getElementById('number_2_day0');
    const number_3_day0 = document.getElementById('number_3_day0');
    const number_4_day0 = document.getElementById('number_4_day0');
    const number_5_day0 = document.getElementById('number_5_day0');
    const number_6_day0 = document.getElementById('number_6_day0');
    const number_7_day0 = document.getElementById('number_7_day0');
    const number_8_day0 = document.getElementById('number_8_day0');
    const number_9_day0 = document.getElementById('number_9_day0');

    const number_0_day1 = document.getElementById('number_0_day1');
    const number_1_day1 = document.getElementById('number_1_day1');
    const number_2_day1 = document.getElementById('number_2_day1');
    const number_3_day1 = document.getElementById('number_3_day1');
    const number_4_day1 = document.getElementById('number_4_day1');
    const number_5_day1 = document.getElementById('number_5_day1');
    const number_6_day1 = document.getElementById('number_6_day1');
    const number_7_day1 = document.getElementById('number_7_day1');
    const number_8_day1 = document.getElementById('number_8_day1');
    const number_9_day1 = document.getElementById('number_9_day1');

    const number_0_day2 = document.getElementById('number_0_day2');
    const number_1_day2 = document.getElementById('number_1_day2');
    const number_2_day2 = document.getElementById('number_2_day2');
    const number_3_day2 = document.getElementById('number_3_day2');
    const number_4_day2 = document.getElementById('number_4_day2');
    const number_5_day2 = document.getElementById('number_5_day2');
    const number_6_day2 = document.getElementById('number_6_day2');
    const number_7_day2 = document.getElementById('number_7_day2');
    const number_8_day2 = document.getElementById('number_8_day2');
    const number_9_day2 = document.getElementById('number_9_day2');

    const number_0_day3 = document.getElementById('number_0_day3');
    const number_1_day3 = document.getElementById('number_1_day3');
    const number_2_day3 = document.getElementById('number_2_day3');
    const number_3_day3 = document.getElementById('number_3_day3');
    const number_4_day3 = document.getElementById('number_4_day3');
    const number_5_day3 = document.getElementById('number_5_day3');
    const number_6_day3 = document.getElementById('number_6_day3');
    const number_7_day3 = document.getElementById('number_7_day3');
    const number_8_day3 = document.getElementById('number_8_day3');
    const number_9_day3 = document.getElementById('number_9_day3');

    const number_0_day4 = document.getElementById('number_0_day4');
    const number_1_day4 = document.getElementById('number_1_day4');
    const number_2_day4 = document.getElementById('number_2_day4');
    const number_3_day4 = document.getElementById('number_3_day4');
    const number_4_day4 = document.getElementById('number_4_day4');
    const number_5_day4 = document.getElementById('number_5_day4');
    const number_6_day4 = document.getElementById('number_6_day4');
    const number_7_day4 = document.getElementById('number_7_day4');
    const number_8_day4 = document.getElementById('number_8_day4');
    const number_9_day4 = document.getElementById('number_9_day4');
    
    const number_0_day5 = document.getElementById('number_0_day5');
    const number_1_day5 = document.getElementById('number_1_day5');
    const number_2_day5 = document.getElementById('number_2_day5');
    const number_3_day5 = document.getElementById('number_3_day5');
    const number_4_day5 = document.getElementById('number_4_day5');
    const number_5_day5 = document.getElementById('number_5_day5');
    const number_6_day5 = document.getElementById('number_6_day5');
    const number_7_day5 = document.getElementById('number_7_day5');
    const number_8_day5 = document.getElementById('number_8_day5');
    const number_9_day5 = document.getElementById('number_9_day5');
    
    const number_0_day6 = document.getElementById('number_0_day6');
    const number_1_day6 = document.getElementById('number_1_day6');
    const number_2_day6 = document.getElementById('number_2_day6');
    const number_3_day6 = document.getElementById('number_3_day6');
    const number_4_day6 = document.getElementById('number_4_day6');
    const number_5_day6 = document.getElementById('number_5_day6');
    const number_6_day6 = document.getElementById('number_6_day6');
    const number_7_day6 = document.getElementById('number_7_day6');
    const number_8_day6 = document.getElementById('number_8_day6');
    const number_9_day6 = document.getElementById('number_9_day6');
   
      if(inputElement){
      inputElement.addEventListener('keydown',enforceFormat);
      inputElement.addEventListener('keyup',formatToPhone);    
      }  
      if(number_0_day0){
        number_0_day0.addEventListener('keydown',enforceFormat);
        number_0_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day0){
        number_1_day0.addEventListener('keydown',enforceFormat);
        number_1_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day0){
        number_2_day0.addEventListener('keydown',enforceFormat);
        number_2_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day0){
        number_3_day0.addEventListener('keydown',enforceFormat);
        number_3_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day0){
        number_4_day0.addEventListener('keydown',enforceFormat);
        number_4_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day0){
        number_5_day0.addEventListener('keydown',enforceFormat);
        number_5_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day0){
        number_6_day0.addEventListener('keydown',enforceFormat);
        number_6_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day0){
        number_7_day0.addEventListener('keydown',enforceFormat);
        number_7_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day0){
        number_8_day0.addEventListener('keydown',enforceFormat);
        number_8_day0.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day0){
        number_9_day0.addEventListener('keydown',enforceFormat);
        number_9_day0.addEventListener('keyup',formatToPhone);
      }
      

      if(number_0_day1){
        number_0_day1.addEventListener('keydown',enforceFormat);
        number_0_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day1){
        number_1_day1.addEventListener('keydown',enforceFormat);
        number_1_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day1){
        number_2_day1.addEventListener('keydown',enforceFormat);
        number_2_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day1){
        number_3_day1.addEventListener('keydown',enforceFormat);
        number_3_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day1){
        number_4_day1.addEventListener('keydown',enforceFormat);
        number_4_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day1){
        number_5_day1.addEventListener('keydown',enforceFormat);
        number_5_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day1){
        number_6_day1.addEventListener('keydown',enforceFormat);
        number_6_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day1){
        number_7_day1.addEventListener('keydown',enforceFormat);
        number_7_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day1){
        number_8_day1.addEventListener('keydown',enforceFormat);
        number_8_day1.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day1){
        number_9_day1.addEventListener('keydown',enforceFormat);
        number_9_day1.addEventListener('keyup',formatToPhone);
      }

      if(number_0_day2){
        number_0_day2.addEventListener('keydown',enforceFormat);
        number_0_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day2){
        number_1_day2.addEventListener('keydown',enforceFormat);
        number_1_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day2){
        number_2_day2.addEventListener('keydown',enforceFormat);
        number_2_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day2){
        number_3_day2.addEventListener('keydown',enforceFormat);
        number_3_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day2){
        number_4_day2.addEventListener('keydown',enforceFormat);
        number_4_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day2){
        number_5_day2.addEventListener('keydown',enforceFormat);
        number_5_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day2){
        number_6_day2.addEventListener('keydown',enforceFormat);
        number_6_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day2){
        number_7_day2.addEventListener('keydown',enforceFormat);
        number_7_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day2){
        number_8_day2.addEventListener('keydown',enforceFormat);
        number_8_day2.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day2){
        number_9_day2.addEventListener('keydown',enforceFormat);
        number_9_day2.addEventListener('keyup',formatToPhone);
      }

      if(number_0_day3){
        number_0_day3.addEventListener('keydown',enforceFormat);
        number_0_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day3){
        number_1_day3.addEventListener('keydown',enforceFormat);
        number_1_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day3){
        number_2_day3.addEventListener('keydown',enforceFormat);
        number_2_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day3){
        number_3_day3.addEventListener('keydown',enforceFormat);
        number_3_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day3){
        number_4_day3.addEventListener('keydown',enforceFormat);
        number_4_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day3){
        number_5_day3.addEventListener('keydown',enforceFormat);
        number_5_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day3){
        number_6_day3.addEventListener('keydown',enforceFormat);
        number_6_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day3){
        number_7_day3.addEventListener('keydown',enforceFormat);
        number_7_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day3){
        number_8_day3.addEventListener('keydown',enforceFormat);
        number_8_day3.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day3){
        number_9_day3.addEventListener('keydown',enforceFormat);
        number_9_day3.addEventListener('keyup',formatToPhone);
      }

      if(number_0_day4){
        number_0_day4.addEventListener('keydown',enforceFormat);
        number_0_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day4){
        number_1_day4.addEventListener('keydown',enforceFormat);
        number_1_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day4){
        number_2_day4.addEventListener('keydown',enforceFormat);
        number_2_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day4){
        number_3_day4.addEventListener('keydown',enforceFormat);
        number_3_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day4){
        number_4_day4.addEventListener('keydown',enforceFormat);
        number_4_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day4){
        number_5_day4.addEventListener('keydown',enforceFormat);
        number_5_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day4){
        number_6_day4.addEventListener('keydown',enforceFormat);
        number_6_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day4){
        number_7_day4.addEventListener('keydown',enforceFormat);
        number_7_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day4){
        number_8_day4.addEventListener('keydown',enforceFormat);
        number_8_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day4){
        number_9_day4.addEventListener('keydown',enforceFormat);
        number_9_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_0_day4){
        number_0_day4.addEventListener('keydown',enforceFormat);
        number_0_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day4){
        number_1_day4.addEventListener('keydown',enforceFormat);
        number_1_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day4){
        number_2_day4.addEventListener('keydown',enforceFormat);
        number_2_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day4){
        number_3_day4.addEventListener('keydown',enforceFormat);
        number_3_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day4){
        number_4_day4.addEventListener('keydown',enforceFormat);
        number_4_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day4){
        number_5_day4.addEventListener('keydown',enforceFormat);
        number_5_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day4){
        number_6_day4.addEventListener('keydown',enforceFormat);
        number_6_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day4){
        number_7_day4.addEventListener('keydown',enforceFormat);
        number_7_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day4){
        number_8_day4.addEventListener('keydown',enforceFormat);
        number_8_day4.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day4){
        number_9_day4.addEventListener('keydown',enforceFormat);
        number_9_day4.addEventListener('keyup',formatToPhone);
      }
     
      if(number_0_day5){
        number_0_day5.addEventListener('keydown',enforceFormat);
        number_0_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day5){
        number_1_day5.addEventListener('keydown',enforceFormat);
        number_1_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day5){
        number_2_day5.addEventListener('keydown',enforceFormat);
        number_2_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day5){
        number_3_day5.addEventListener('keydown',enforceFormat);
        number_3_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day5){
        number_4_day5.addEventListener('keydown',enforceFormat);
        number_4_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day5){
        number_5_day5.addEventListener('keydown',enforceFormat);
        number_5_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day5){
        number_6_day5.addEventListener('keydown',enforceFormat);
        number_6_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day5){
        number_7_day5.addEventListener('keydown',enforceFormat);
        number_7_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day5){
        number_8_day5.addEventListener('keydown',enforceFormat);
        number_8_day5.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day5){
        number_9_day5.addEventListener('keydown',enforceFormat);
        number_9_day5.addEventListener('keyup',formatToPhone);
      }
      
      if(number_0_day6){
        number_0_day6.addEventListener('keydown',enforceFormat);
        number_0_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_1_day6){
        number_1_day6.addEventListener('keydown',enforceFormat);
        number_1_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_2_day6){
        number_2_day6.addEventListener('keydown',enforceFormat);
        number_2_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_3_day6){
        number_3_day6.addEventListener('keydown',enforceFormat);
        number_3_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_4_day6){
        number_4_day6.addEventListener('keydown',enforceFormat);
        number_4_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_5_day6){
        number_5_day6.addEventListener('keydown',enforceFormat);
        number_5_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_6_day6){
        number_6_day6.addEventListener('keydown',enforceFormat);
        number_6_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_7_day6){
        number_7_day6.addEventListener('keydown',enforceFormat);
        number_7_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_8_day6){
        number_8_day6.addEventListener('keydown',enforceFormat);
        number_8_day6.addEventListener('keyup',formatToPhone);
      }
      if(number_9_day6){
        number_9_day6.addEventListener('keydown',enforceFormat);
        number_9_day6.addEventListener('keyup',formatToPhone);
      }


      const editnumber_0_day0 = document.getElementById('editnumber_0_day0');
      const editnumber_1_day0 = document.getElementById('editnumber_1_day0');
      const editnumber_2_day0 = document.getElementById('editnumber_2_day0');
      const editnumber_3_day0 = document.getElementById('editnumber_3_day0');
      const editnumber_4_day0 = document.getElementById('editnumber_4_day0');
      const editnumber_5_day0 = document.getElementById('editnumber_5_day0');
      const editnumber_6_day0 = document.getElementById('editnumber_6_day0');
      const editnumber_7_day0 = document.getElementById('editnumber_7_day0');
      const editnumber_8_day0 = document.getElementById('editnumber_8_day0');        
      const editnumber_9_day0 = document.getElementById('editnumber_9_day0');        
      
      const editnumber_0_day1 = document.getElementById('editnumber_0_day1');
      const editnumber_1_day1 = document.getElementById('editnumber_1_day1');
      const editnumber_2_day1 = document.getElementById('editnumber_2_day1');
      const editnumber_3_day1 = document.getElementById('editnumber_3_day1');
      const editnumber_4_day1 = document.getElementById('editnumber_4_day1');
      const editnumber_5_day1 = document.getElementById('editnumber_5_day1');
      const editnumber_6_day1 = document.getElementById('editnumber_6_day1');
      const editnumber_7_day1 = document.getElementById('editnumber_7_day1');
      const editnumber_8_day1 = document.getElementById('editnumber_8_day1');        
      const editnumber_9_day1 = document.getElementById('editnumber_9_day1');        
      
      const editnumber_0_day2 = document.getElementById('editnumber_0_day2');
      const editnumber_1_day2 = document.getElementById('editnumber_1_day2');
      const editnumber_2_day2 = document.getElementById('editnumber_2_day2');
      const editnumber_3_day2 = document.getElementById('editnumber_3_day2');
      const editnumber_4_day2 = document.getElementById('editnumber_4_day2');
      const editnumber_5_day2 = document.getElementById('editnumber_5_day2');
      const editnumber_6_day2 = document.getElementById('editnumber_6_day2');
      const editnumber_7_day2 = document.getElementById('editnumber_7_day2');
      const editnumber_8_day2 = document.getElementById('editnumber_8_day2');        
      const editnumber_9_day2 = document.getElementById('editnumber_9_day2');        
      
      const editnumber_0_day3 = document.getElementById('editnumber_0_day3');
      const editnumber_1_day3 = document.getElementById('editnumber_1_day3');
      const editnumber_2_day3 = document.getElementById('editnumber_2_day3');
      const editnumber_3_day3 = document.getElementById('editnumber_3_day3');
      const editnumber_4_day3 = document.getElementById('editnumber_4_day3');
      const editnumber_5_day3 = document.getElementById('editnumber_5_day3');
      const editnumber_6_day3 = document.getElementById('editnumber_6_day3');
      const editnumber_7_day3 = document.getElementById('editnumber_7_day3');
      const editnumber_8_day3 = document.getElementById('editnumber_8_day3');        
      const editnumber_9_day3 = document.getElementById('editnumber_9_day3');        
      
      const editnumber_0_day4 = document.getElementById('editnumber_0_day4');
      const editnumber_1_day4 = document.getElementById('editnumber_1_day4');
      const editnumber_2_day4 = document.getElementById('editnumber_2_day4');
      const editnumber_3_day4 = document.getElementById('editnumber_3_day4');
      const editnumber_4_day4 = document.getElementById('editnumber_4_day4');
      const editnumber_5_day4 = document.getElementById('editnumber_5_day4');
      const editnumber_6_day4 = document.getElementById('editnumber_6_day4');
      const editnumber_7_day4 = document.getElementById('editnumber_7_day4');
      const editnumber_8_day4 = document.getElementById('editnumber_8_day4');        
      const editnumber_9_day4 = document.getElementById('editnumber_9_day4');        
      
      const editnumber_0_day5 = document.getElementById('editnumber_0_day5');
      const editnumber_1_day5 = document.getElementById('editnumber_1_day5');
      const editnumber_2_day5 = document.getElementById('editnumber_2_day5');
      const editnumber_3_day5 = document.getElementById('editnumber_3_day5');
      const editnumber_4_day5 = document.getElementById('editnumber_4_day5');
      const editnumber_5_day5 = document.getElementById('editnumber_5_day5');
      const editnumber_6_day5 = document.getElementById('editnumber_6_day5');
      const editnumber_7_day5 = document.getElementById('editnumber_7_day5');
      const editnumber_8_day5 = document.getElementById('editnumber_8_day5');        
      const editnumber_9_day5 = document.getElementById('editnumber_9_day5');        
      
      const editnumber_0_day6 = document.getElementById('editnumber_0_day6');
      const editnumber_1_day6 = document.getElementById('editnumber_1_day6');
      const editnumber_2_day6 = document.getElementById('editnumber_2_day6');
      const editnumber_3_day6 = document.getElementById('editnumber_3_day6');
      const editnumber_4_day6 = document.getElementById('editnumber_4_day6');
      const editnumber_5_day6 = document.getElementById('editnumber_5_day6');
      const editnumber_6_day6 = document.getElementById('editnumber_6_day6');
      const editnumber_7_day6 = document.getElementById('editnumber_7_day6');
      const editnumber_8_day6 = document.getElementById('editnumber_8_day6');        
      const editnumber_9_day6 = document.getElementById('editnumber_9_day6');        
    
      if(editnumber_0_day0){
        editnumber_0_day0.addEventListener('keydown',enforceFormat);
        editnumber_0_day0.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_1_day0){
        editnumber_1_day0.addEventListener('keydown',enforceFormat);
        editnumber_1_day0.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_2_day0){
        editnumber_2_day0.addEventListener('keydown',enforceFormat);
        editnumber_2_day0.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_3_day0){
        editnumber_3_day0.addEventListener('keydown',enforceFormat);
        editnumber_3_day0.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_4_day0){
        editnumber_4_day0.addEventListener('keydown',enforceFormat);
        editnumber_4_day0.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_5_day0){
        editnumber_5_day0.addEventListener('keydown',enforceFormat);
        editnumber_5_day0.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_6_day0){
        editnumber_6_day0.addEventListener('keydown',enforceFormat);
        editnumber_6_day0.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_7_day0){
        editnumber_7_day0.addEventListener('keydown',enforceFormat);
        editnumber_7_day0.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_8_day0){
        editnumber_8_day0.addEventListener('keydown',enforceFormat);
        editnumber_8_day0.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_9_day0){
        editnumber_9_day0.addEventListener('keydown',enforceFormat);
        editnumber_9_day0.addEventListener('keyup',formatToPhone);    
      }  
      
      if(editnumber_0_day1){
        editnumber_0_day1.addEventListener('keydown',enforceFormat);
        editnumber_0_day1.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_1_day1){
        editnumber_1_day1.addEventListener('keydown',enforceFormat);
        editnumber_1_day1.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_2_day1){
        editnumber_2_day1.addEventListener('keydown',enforceFormat);
        editnumber_2_day1.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_3_day1){
        editnumber_3_day1.addEventListener('keydown',enforceFormat);
        editnumber_3_day1.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_4_day1){
        editnumber_4_day1.addEventListener('keydown',enforceFormat);
        editnumber_4_day1.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_5_day1){
        editnumber_5_day1.addEventListener('keydown',enforceFormat);
        editnumber_5_day1.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_6_day1){
        editnumber_6_day1.addEventListener('keydown',enforceFormat);
        editnumber_6_day1.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_7_day1){
        editnumber_7_day1.addEventListener('keydown',enforceFormat);
        editnumber_7_day1.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_8_day1){
        editnumber_8_day1.addEventListener('keydown',enforceFormat);
        editnumber_8_day1.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_9_day1){
        editnumber_9_day1.addEventListener('keydown',enforceFormat);
        editnumber_9_day1.addEventListener('keyup',formatToPhone);    
      }  
      
      if(editnumber_0_day2){
        editnumber_0_day2.addEventListener('keydown',enforceFormat);
        editnumber_0_day2.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_1_day2){
        editnumber_1_day2.addEventListener('keydown',enforceFormat);
        editnumber_1_day2.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_2_day2){
        editnumber_2_day2.addEventListener('keydown',enforceFormat);
        editnumber_2_day2.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_3_day2){
        editnumber_3_day2.addEventListener('keydown',enforceFormat);
        editnumber_3_day2.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_4_day2){
        editnumber_4_day2.addEventListener('keydown',enforceFormat);
        editnumber_4_day2.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_5_day2){
        editnumber_5_day2.addEventListener('keydown',enforceFormat);
        editnumber_5_day2.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_6_day2){
        editnumber_6_day2.addEventListener('keydown',enforceFormat);
        editnumber_6_day2.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_7_day2){
        editnumber_7_day2.addEventListener('keydown',enforceFormat);
        editnumber_7_day2.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_8_day2){
        editnumber_8_day2.addEventListener('keydown',enforceFormat);
        editnumber_8_day2.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_9_day2){
        editnumber_9_day2.addEventListener('keydown',enforceFormat);
        editnumber_9_day2.addEventListener('keyup',formatToPhone);    
      }  
      
      if(editnumber_0_day3){
        editnumber_0_day3.addEventListener('keydown',enforceFormat);
        editnumber_0_day3.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_1_day3){
        editnumber_1_day3.addEventListener('keydown',enforceFormat);
        editnumber_1_day3.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_2_day3){
        editnumber_2_day3.addEventListener('keydown',enforceFormat);
        editnumber_2_day3.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_3_day3){
        editnumber_3_day3.addEventListener('keydown',enforceFormat);
        editnumber_3_day3.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_4_day3){
        editnumber_4_day3.addEventListener('keydown',enforceFormat);
        editnumber_4_day3.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_5_day3){
        editnumber_5_day3.addEventListener('keydown',enforceFormat);
        editnumber_5_day3.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_6_day3){
        editnumber_6_day3.addEventListener('keydown',enforceFormat);
        editnumber_6_day3.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_7_day3){
        editnumber_7_day3.addEventListener('keydown',enforceFormat);
        editnumber_7_day3.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_8_day3){
        editnumber_8_day3.addEventListener('keydown',enforceFormat);
        editnumber_8_day3.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_9_day3){
        editnumber_9_day3.addEventListener('keydown',enforceFormat);
        editnumber_9_day3.addEventListener('keyup',formatToPhone);    
      }  
      
      if(editnumber_0_day4){
        editnumber_0_day4.addEventListener('keydown',enforceFormat);
        editnumber_0_day4.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_1_day4){
        editnumber_1_day4.addEventListener('keydown',enforceFormat);
        editnumber_1_day4.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_2_day4){
        editnumber_2_day4.addEventListener('keydown',enforceFormat);
        editnumber_2_day4.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_3_day4){
        editnumber_3_day4.addEventListener('keydown',enforceFormat);
        editnumber_3_day4.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_4_day4){
        editnumber_4_day4.addEventListener('keydown',enforceFormat);
        editnumber_4_day4.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_5_day4){
        editnumber_5_day4.addEventListener('keydown',enforceFormat);
        editnumber_5_day4.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_6_day4){
        editnumber_6_day4.addEventListener('keydown',enforceFormat);
        editnumber_6_day4.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_7_day4){
        editnumber_7_day4.addEventListener('keydown',enforceFormat);
        editnumber_7_day4.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_8_day4){
        editnumber_8_day4.addEventListener('keydown',enforceFormat);
        editnumber_8_day4.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_9_day4){
        editnumber_9_day4.addEventListener('keydown',enforceFormat);
        editnumber_9_day4.addEventListener('keyup',formatToPhone);    
      }  
      
      if(editnumber_0_day5){
        editnumber_0_day5.addEventListener('keydown',enforceFormat);
        editnumber_0_day5.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_1_day5){
        editnumber_1_day5.addEventListener('keydown',enforceFormat);
        editnumber_1_day5.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_2_day5){
        editnumber_2_day5.addEventListener('keydown',enforceFormat);
        editnumber_2_day5.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_3_day5){
        editnumber_3_day5.addEventListener('keydown',enforceFormat);
        editnumber_3_day5.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_4_day5){
        editnumber_4_day5.addEventListener('keydown',enforceFormat);
        editnumber_4_day5.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_5_day5){
        editnumber_5_day5.addEventListener('keydown',enforceFormat);
        editnumber_5_day5.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_6_day5){
        editnumber_6_day5.addEventListener('keydown',enforceFormat);
        editnumber_6_day5.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_7_day5){
        editnumber_7_day5.addEventListener('keydown',enforceFormat);
        editnumber_7_day5.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_8_day5){
        editnumber_8_day5.addEventListener('keydown',enforceFormat);
        editnumber_8_day5.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_9_day5){
        editnumber_9_day5.addEventListener('keydown',enforceFormat);
        editnumber_9_day5.addEventListener('keyup',formatToPhone);    
      }  
      
      if(editnumber_0_day6){
        editnumber_0_day6.addEventListener('keydown',enforceFormat);
        editnumber_0_day6.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_1_day6){
        editnumber_1_day6.addEventListener('keydown',enforceFormat);
        editnumber_1_day6.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_2_day6){
        editnumber_2_day6.addEventListener('keydown',enforceFormat);
        editnumber_2_day6.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_3_day6){
        editnumber_3_day6.addEventListener('keydown',enforceFormat);
        editnumber_3_day6.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_4_day6){
        editnumber_4_day6.addEventListener('keydown',enforceFormat);
        editnumber_4_day6.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_5_day6){
        editnumber_5_day6.addEventListener('keydown',enforceFormat);
        editnumber_5_day6.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_6_day6){
        editnumber_6_day6.addEventListener('keydown',enforceFormat);
        editnumber_6_day6.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_7_day6){
        editnumber_7_day6.addEventListener('keydown',enforceFormat);
        editnumber_7_day6.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_8_day6){
        editnumber_8_day6.addEventListener('keydown',enforceFormat);
        editnumber_8_day6.addEventListener('keyup',formatToPhone);    
      }  
      if(editnumber_9_day6){
        editnumber_9_day6.addEventListener('keydown',enforceFormat);
        editnumber_9_day6.addEventListener('keyup',formatToPhone);    
      }  
  }

allrules : any; 
getAllRuleset(){
  this.moduleService.getRules(this.currentCompany.uuid).subscribe(
    res =>{
      // console.log(res);        
      this.allrules = res.rule_data;     
    }  
  ); 
}


  ivrmenus : any;
  getIVRMenu(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getIVRMenus(this.currentCompany.uuid).subscribe(
      res => { 
        this.ivrmenus = res;
        // console.log(this.ivrmenus);
      },
    error => this.error = error,
    );      
  }
 
  get rules() { return this.addFSLRules.controls; }
  onFSLSubmit () {
    this.FSLRulesSubmitted = true;
    // stop here if form is invalid
    if (this.addFSLRules.invalid) {
        return;
    }  
    // console.log(this.addFSLRules.get('start_time_0').value._d);
    //  return false;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    // console.log(this.addFSLRules.value); return false;     
    this.moduleService.addFSLRules(this.addFSLRules.value,this.currentCompany.uuid).subscribe(
      res => {      
        // console.log(res);
        // return false;  
        // localStorage.setItem('FSLRulesInsertID', JSON.stringify(res.insertid));
        this.addFSL();
        if (res.status !== 'error') {
          this.toastrService.success('FSL Rules Added Successfully');          
        }else{
          this.toastrService.error('Failed To Add');
        }
      },
      error => this.error = error,
    );     
    
  }
 
  get editrules() { return this.editFSLRules.controls; }
  onFSLEditSubmit () {
    this.FSLRulesEditSubmitted = true;
    // stop here if form is invalid
    if (this.editFSLRules.invalid) {
        return;
    }  
    this.editFSLRules.get('rulename').setValue(this.forwarding)
    // console.log(this.editFSLRules.value);
    // return false;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    // console.log(this.currentUser.uuid); return false;     
    this.moduleService.editFSLRules(this.editFSLRules.value,this.currentCompany.uuid).subscribe(
      res => {      
        // console.log(res);
        // return false;  
        // localStorage.setItem('FSLRulesInsertID', JSON.stringify(res.insertid));
        if (res.status !== 'error') {
          this.toastrService.success('FSL Rules Updated Successfully');          
        }else{
          this.toastrService.error('Failed To Add');
        }
      },
      error => this.error = error,
    );     
    
  }


  getRules(){
    // alert('ok');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.FSLRulesInsertID = localStorage.getItem('FSLRulesInsertID');
    // console.log(this.FSLRulesInsertID);return false;
    this.moduleService.getRecentRules(this.FSLRulesInsertID,this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res.noofforwardinglocation);
        this.rulesData = res;            
      },
      error => this.error = error,
    );  
  }

 

  jklrules:string;
  get_jklrules(event:any){
    this.jklrules = event.target.checked;
  }
 
  global_revenuecap:any;
  global_revenue_cap(event:any){
    this.global_revenuecap = event.target.checked;    
  }
 
  dtmf_auto:any;
  dtmf_auto_toggle(event:any){
    this.dtmf_auto = event.target.checked;    
  }
  
  dtmf_zipcode_enable:any;
  dtmf_zipcode_enable_toggle(event:any){
    this.dtmf_zipcode_enable = event.target.checked;    
  }
  
  monthly_revenuecap:any;
  monthly_revenue_cap(event:any){
    this.monthly_revenuecap = event.target.checked;    
  }

  daily_revenuecap:any;
  daily_revenue_cap(event:any){
    this.daily_revenuecap = event.target.checked;    
  }
  
  hourly_revenuecap:any;
  hourly_revenue_cap(event:any){
    this.hourly_revenuecap = event.target.checked;    
  }
  
  maxconcurrency:any;
  get_maxcon(event:any){
    this.maxconcurrency = event.target.checked;    
  }
 
  disableRecordings:any;
  get_disableRecordings(event:any){
    this.disableRecordings = event.target.checked;    
  }
  
  hourlyconcurrency:any;
  get_hourlyconcurrency(event:any){
    this.hourlyconcurrency = event.target.checked;    
  }


  isName : any; 
  validatename() {    
  this.moduleService.validatename(this.addFSLRules.get('name').value).subscribe(
    res =>{
        // console.log(res); return false;  
      this.isName = res;
      if (this.isName.status == 'success') {
        this.isName = "success";
      }
      else{
        this.isName = "fail";
      }
      // console.log(this.isName); return false;  
    }
  // }
  );
}


getFSLbyuuid(uuid:string){
  // alert(uuid);
  // console.log(uuid);

  this.moduleService.getFSLbyuuid(uuid).subscribe(
    res => {
      
      this.forwarding = res.forwarding[0].name; 
      this.forwardingtimeframe = res.forwarding[0].nooftimeframe;            
      this.noofforwarding = res.forwarding[0].nooftimeframe;            
      for (let index = 0; index < res.forwarding.length; index++) {
        var start = res.forwarding[index].start_time.split(':');
        var end = res.forwarding[index].end_time.split(':');
        var starthrs ='start_hrs_'+index;
        var startmin ='start_min_'+index;
        var endhrs ='end_hrs_'+index;
        var endmin ='end_min_'+index;
        // var endkey ='end_time_'+index;
        var daykey ='day_'+index;
        // console.log(start[0]);
        this.editFSLRules.patchValue({
          [daykey]: res.forwarding[index].timeofday,
          // [startkey]: new Date(new Date().setHours(start[0], start[1])),
          [starthrs]: start[0],
          [startmin]: start[1],
          [endhrs]: end[0],
          [endmin]: end[1],

          // [endkey]: new Date(new Date().setHours(end[0], end[1])),               
        });   

        for (let j = 0; j < res.forwarding[index].locations.length; j++) {
          var inc = j + 1;
          // var forlocationkey ='noofforwardinglocation_'+inc;
          var PrimaryforwardType ='PrimaryforwardType_'+j+'_day'+index;
          var number_value ='number_'+j+'_day'+index;
          var ivr_value ='ivrMenu_'+j+'_day'+index;
          var sip_value ='sipAdd_'+j+'_day'+index;
          // this.flag =res.forwarding[index].locations[j].primary_forwarding_type;
          // console.log(res.forwarding[index].locations[j].primary_forwarding_type);
          if(res.forwarding[index].locations[j].primary_forwarding_type ==0){
            this.setPrimaryforward(0,j,index);
            this.flag = 0;
          }
          if(res.forwarding[index].locations[j].primary_forwarding_type ==1){
            this.setPrimaryforward(1,j,index);
            this.flag = 1;
          }
          if(res.forwarding[index].locations[j].primary_forwarding_type ==2){
            this.flag = 2;
            this.setPrimaryforward(2,j,index);
          }
         
          if(res.forwarding[index].locations[j].primary_forwarding_number!=''){
            var number = res.forwarding[index].locations[j].primary_forwarding_number
            var formatted = '('+ number.substr(0, 3) + ') ' + number.substr(3, 3) + '-' + number.substr(6,4)  
          }else{
            var formatted="";
          }
          
          

           if(index == 0){                
            this.noofforwardinglocation_1 = res.forwarding[index].locations[j].noofforwardinglocation; 
            // if(this.noofforwardinglocation_1!=1)  {
            // this.noofforwardinglocation_1++;                             
            // }
           }  
          //  console.log(res.forwarding[index].locations[j].noofforwardinglocation);
           if(index == 1){                
            this.noofforwardinglocation_2 = res.forwarding[index].locations[j].noofforwardinglocation;        
            // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
            //   this.noofforwardinglocation_2++;                             
            //   }
           }  
           if(index == 2){                
            this.noofforwardinglocation_3 = res.forwarding[index].locations[j].noofforwardinglocation; 
            // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
            //   this.noofforwardinglocation_3++;                             
            // }                              
           }  
           if(index == 3){                
            this.noofforwardinglocation_4 = res.forwarding[index].locations[j].noofforwardinglocation;  
            // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
            //   this.noofforwardinglocation_4++;                             
            // }                              
           }  
           if(index == 4){                
            this.noofforwardinglocation_5 = res.forwarding[index].locations[j].noofforwardinglocation; 
            // if(res.forwarding[index].locations[j].noofforwardinglocation!=1)  {
            //   this.noofforwardinglocation_5++;                             
            // }                                
           }                
           if(index == 5){                
            this.noofforwardinglocation_6 = res.forwarding[index].locations[j].noofforwardinglocation; 
           }                
           if(index == 6){                
            this.noofforwardinglocation_7 = res.forwarding[index].locations[j].noofforwardinglocation; 
           }                
          // console.log(index);
          this.editFSLRules.patchValue({
            // [forlocationkey]: res.forwarding[index].locations[j].noofforwardinglocation,
            [PrimaryforwardType]: this.flag,
            [number_value]: formatted,
            [ivr_value]: res.forwarding[index].locations[j].primary_forwarding_ivr,
            [sip_value]: res.forwarding[index].locations[j].primary_forwarding_sip,
          });  
        }               
      }   
        
    },
    error => this.error = error,
  );  
}

addFSL(){
    this.FSLRulesSubmitted = false;
    this.addFSLRules.reset();    
}  
getWeekbyuuid(uuid:any,start_day:any,end_day:any){
    this.editWeekDaysForm.patchValue({
      'uuid':uuid,
      'start_day':start_day,
      'end_day':end_day,
    });
}

allweekdays:any;
loadallweekdays(){
  this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleService.loadallweekdays(this.currentCompany.uuid).subscribe(
    res => {
      //  console.log(res); return false;  
      this.allweekdays = res.data;
    },
    error => this.error = error,
  );  
}

isfilter:any =0;
isfilterselect(){
  this.isfilter=1;
  this.addForm.get('isfilter').setValue(this.isfilter);
}
isfilterclear(){
  this.isfilter=0;
  this.addForm.get('citystatetype').setValue(1);
  this.addForm.get('citystate').setValue('');
  this.addForm.get('cityval').setValue('');
}

ngOnInit() {    
  // this.getAllLabels();
  // this.getIVRMenu();
  // this.getAllLabels();
  // this.getAllRuleset();
  this.getIVRMenu();
  this.getAllRules();
  this.gettimezone();

  // this.loadNumbers();
  this.loadallweekdays();
  // this.loadweekdays();

  this.dropdownSettings = { 
    singleSelection: false, 
    text:"Select Associated Label",
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true,
    classes:"myclass custom-class"
};   

  this.addWeekDaysForm = this.fb.group({
    start_day: ['', Validators.required],
    end_day: ['', Validators.required],
  });

  this.editWeekDaysForm = this.fb.group({
    uuid: ['', ''],
    start_day: ['', Validators.required],
    end_day: ['', Validators.required],
  });

  this.addFSLRules = this.fb.group({
    name: ['', Validators.required],   
    numberoftimeframe: ['', Validators.required],   
    noofforwardinglocation_1: ['', ''],   
    noofforwardinglocation_2: ['', ''],   
    noofforwardinglocation_3: ['', ''],   
    noofforwardinglocation_4: ['', ''],   
    noofforwardinglocation_5: ['', ''],   
    noofforwardinglocation_6: ['', ''],   
    noofforwardinglocation_7: ['', ''],   
  
    // Day 1
    day_0: ['', ''],   
    start_hrs_0: ['', ''],   
    start_min_0: ['', ''],   
    end_hrs_0: ['', ''],  
    end_min_0: ['', ''],  

    PrimaryforwardType_0_day0: ['', ''],         
    number_0_day0: ['', ''],         
    ivrMenu_0_day0: ['', ''],         
    sipAdd_0_day0: ['', ''],

    PrimaryforwardType_1_day0: ['', ''],         
    number_1_day0: ['', ''],         
    ivrMenu_1_day0: ['', ''],         
    sipAdd_1_day0: ['', ''],

    PrimaryforwardType_2_day0: ['', ''],         
    number_2_day0: ['', ''],         
    ivrMenu_2_day0: ['', ''],         
    sipAdd_2_day0: ['', ''],

    PrimaryforwardType_3_day0: ['', ''],         
    number_3_day0: ['', ''],         
    ivrMenu_3_day0: ['', ''],         
    sipAdd_3_day0: ['', ''],

    PrimaryforwardType_4_day0: ['', ''],         
    number_4_day0: ['', ''],         
    ivrMenu_4_day0: ['', ''],         
    sipAdd_4_day0: ['', ''],

    PrimaryforwardType_5_day0: ['', ''],         
    number_5_day0: ['', ''],         
    ivrMenu_5_day0: ['', ''],         
    sipAdd_5_day0: ['', ''],

    PrimaryforwardType_6_day0: ['', ''],         
    number_6_day0: ['', ''],         
    ivrMenu_6_day0: ['', ''],         
    sipAdd_6_day0: ['', ''],

    PrimaryforwardType_7_day0: ['', ''],         
    number_7_day0: ['', ''],         
    ivrMenu_7_day0: ['', ''],         
    sipAdd_7_day0: ['', ''],

    PrimaryforwardType_8_day0: ['', ''],         
    number_8_day0: ['', ''],         
    ivrMenu_8_day0: ['', ''],         
    sipAdd_8_day0: ['', ''],

    PrimaryforwardType_9_day0: ['', ''],         
    number_9_day0: ['', ''],         
    ivrMenu_9_day0: ['', ''],         
    sipAdd_9_day0: ['', ''],
        
    // Day2
    day_1: ['', ''],   
    start_hrs_1: ['', ''],   
    start_min_1: ['', ''],   
    end_hrs_1: ['', ''],  
    end_min_1: ['', ''],  
  
    PrimaryforwardType_0_day1: ['', ''],         
    number_0_day1: ['', ''],         
    ivrMenu_0_day1: ['', ''],         
    sipAdd_0_day1: ['', ''],

    PrimaryforwardType_1_day1: ['', ''],         
    number_1_day1: ['', ''],         
    ivrMenu_1_day1: ['', ''],         
    sipAdd_1_day1: ['', ''],

    PrimaryforwardType_2_day1: ['', ''],         
    number_2_day1: ['', ''],         
    ivrMenu_2_day1: ['', ''],         
    sipAdd_2_day1: ['', ''],

    PrimaryforwardType_3_day1: ['', ''],         
    number_3_day1: ['', ''],         
    ivrMenu_3_day1: ['', ''],         
    sipAdd_3_day1: ['', ''],

    PrimaryforwardType_4_day1: ['', ''],         
    number_4_day1: ['', ''],         
    ivrMenu_4_day1: ['', ''],         
    sipAdd_4_day1: ['', ''],

    PrimaryforwardType_5_day1: ['', ''],         
    number_5_day1: ['', ''],         
    ivrMenu_5_day1: ['', ''],         
    sipAdd_5_day1: ['', ''],

    PrimaryforwardType_6_day1: ['', ''],         
    number_6_day1: ['', ''],         
    ivrMenu_6_day1: ['', ''],         
    sipAdd_6_day1: ['', ''],

    PrimaryforwardType_7_day1: ['', ''],         
    number_7_day1: ['', ''],         
    ivrMenu_7_day1: ['', ''],         
    sipAdd_7_day1: ['', ''],

    PrimaryforwardType_8_day1: ['', ''],         
    number_8_day1: ['', ''],         
    ivrMenu_8_day1: ['', ''],         
    sipAdd_8_day1: ['', ''],

    PrimaryforwardType_9_day1: ['', ''],         
    number_9_day1: ['', ''],         
    ivrMenu_9_day1: ['', ''],         
    sipAdd_9_day1: ['', ''],

    // Day3
    day_2: ['', ''],   
    start_hrs_2: ['', ''],   
    start_min_2: ['', ''],   
    end_hrs_2: ['', ''],  
    end_min_2: ['', ''],  
  
    PrimaryforwardType_0_day2: ['', ''],         
    number_0_day2: ['', ''],         
    ivrMenu_0_day2: ['', ''],         
    sipAdd_0_day2: ['', ''],

    PrimaryforwardType_1_day2: ['', ''],         
    number_1_day2: ['', ''],         
    ivrMenu_1_day2: ['', ''],         
    sipAdd_1_day2: ['', ''],

    PrimaryforwardType_2_day2: ['', ''],         
    number_2_day2: ['', ''],         
    ivrMenu_2_day2: ['', ''],         
    sipAdd_2_day2: ['', ''],

    PrimaryforwardType_3_day2: ['', ''],         
    number_3_day2: ['', ''],         
    ivrMenu_3_day2: ['', ''],         
    sipAdd_3_day2: ['', ''],

    PrimaryforwardType_4_day2: ['', ''],         
    number_4_day2: ['', ''],         
    ivrMenu_4_day2: ['', ''],         
    sipAdd_4_day2: ['', ''],

    PrimaryforwardType_5_day2: ['', ''],         
    number_5_day2: ['', ''],         
    ivrMenu_5_day2: ['', ''],         
    sipAdd_5_day2: ['', ''],

    PrimaryforwardType_6_day2: ['', ''],         
    number_6_day2: ['', ''],         
    ivrMenu_6_day2: ['', ''],         
    sipAdd_6_day2: ['', ''],

    PrimaryforwardType_7_day2: ['', ''],         
    number_7_day2: ['', ''],         
    ivrMenu_7_day2: ['', ''],         
    sipAdd_7_day2: ['', ''],

    PrimaryforwardType_8_day2: ['', ''],         
    number_8_day2: ['', ''],         
    ivrMenu_8_day2: ['', ''],         
    sipAdd_8_day2: ['', ''],

    PrimaryforwardType_9_day2: ['', ''],         
    number_9_day2: ['', ''],         
    ivrMenu_9_day2: ['', ''],         
    sipAdd_9_day2: ['', ''],

    // Day4
    day_3: ['', ''],   
    start_hrs_3: ['', ''],   
    start_min_3: ['', ''],   
    end_hrs_3: ['', ''],  
    end_min_3: ['', ''],  
  
    PrimaryforwardType_0_day3: ['', ''],         
    number_0_day3: ['', ''],         
    ivrMenu_0_day3: ['', ''],         
    sipAdd_0_day3: ['', ''],

    PrimaryforwardType_1_day3: ['', ''],         
    number_1_day3: ['', ''],         
    ivrMenu_1_day3: ['', ''],         
    sipAdd_1_day3: ['', ''],

    PrimaryforwardType_2_day3: ['', ''],         
    number_2_day3: ['', ''],         
    ivrMenu_2_day3: ['', ''],         
    sipAdd_2_day3: ['', ''],

    PrimaryforwardType_3_day3: ['', ''],         
    number_3_day3: ['', ''],         
    ivrMenu_3_day3: ['', ''],         
    sipAdd_3_day3: ['', ''],

    PrimaryforwardType_4_day3: ['', ''],         
    number_4_day3: ['', ''],         
    ivrMenu_4_day3: ['', ''],         
    sipAdd_4_day3: ['', ''],

    PrimaryforwardType_5_day3: ['', ''],         
    number_5_day3: ['', ''],         
    ivrMenu_5_day3: ['', ''],         
    sipAdd_5_day3: ['', ''],

    PrimaryforwardType_6_day3: ['', ''],         
    number_6_day3: ['', ''],         
    ivrMenu_6_day3: ['', ''],         
    sipAdd_6_day3: ['', ''],

    PrimaryforwardType_7_day3: ['', ''],         
    number_7_day3: ['', ''],         
    ivrMenu_7_day3: ['', ''],         
    sipAdd_7_day3: ['', ''],

    PrimaryforwardType_8_day3: ['', ''],         
    number_8_day3: ['', ''],         
    ivrMenu_8_day3: ['', ''],         
    sipAdd_8_day3: ['', ''],

    PrimaryforwardType_9_day3: ['', ''],         
    number_9_day3: ['', ''],         
    ivrMenu_9_day3: ['', ''],         
    sipAdd_9_day3: ['', ''],

    // Day5
    day_4: ['', ''],   
    start_hrs_4: ['', ''],   
    start_min_4: ['', ''],   
    end_hrs_4: ['', ''],  
    end_min_4: ['', ''],  

    PrimaryforwardType_0_day4: ['', ''],         
    number_0_day4: ['', ''],         
    ivrMenu_0_day4: ['', ''],         
    sipAdd_0_day4: ['', ''],

    PrimaryforwardType_1_day4: ['', ''],         
    number_1_day4: ['', ''],         
    ivrMenu_1_day4: ['', ''],         
    sipAdd_1_day4: ['', ''],

    PrimaryforwardType_2_day4: ['', ''],         
    number_2_day4: ['', ''],         
    ivrMenu_2_day4: ['', ''],         
    sipAdd_2_day4: ['', ''],

    PrimaryforwardType_3_day4: ['', ''],         
    number_3_day4: ['', ''],         
    ivrMenu_3_day4: ['', ''],         
    sipAdd_3_day4: ['', ''],

    PrimaryforwardType_4_day4: ['', ''],         
    number_4_day4: ['', ''],         
    ivrMenu_4_day4: ['', ''],         
    sipAdd_4_day4: ['', ''],

    PrimaryforwardType_5_day4: ['', ''],         
    number_5_day4: ['', ''],         
    ivrMenu_5_day4: ['', ''],         
    sipAdd_5_day4: ['', ''],

    PrimaryforwardType_6_day4: ['', ''],         
    number_6_day4: ['', ''],         
    ivrMenu_6_day4: ['', ''],         
    sipAdd_6_day4: ['', ''],

    PrimaryforwardType_7_day4: ['', ''],         
    number_7_day4: ['', ''],         
    ivrMenu_7_day4: ['', ''],         
    sipAdd_7_day4: ['', ''],

    PrimaryforwardType_8_day4: ['', ''],         
    number_8_day4: ['', ''],         
    ivrMenu_8_day4: ['', ''],         
    sipAdd_8_day4: ['', ''],

    PrimaryforwardType_9_day4: ['', ''],         
    number_9_day4: ['', ''],         
    ivrMenu_9_day4: ['', ''],         
    sipAdd_9_day4: ['', ''],

    // Day6
    day_5: ['', ''],   
    start_hrs_5: ['', ''],   
    start_min_5: ['', ''],   
    end_hrs_5: ['', ''],  
    end_min_5: ['', ''],  
  
    PrimaryforwardType_0_day5: ['', ''],         
    number_0_day5: ['', ''],         
    ivrMenu_0_day5: ['', ''],         
    sipAdd_0_day5: ['', ''],

    PrimaryforwardType_1_day5: ['', ''],         
    number_1_day5: ['', ''],         
    ivrMenu_1_day5: ['', ''],         
    sipAdd_1_day5: ['', ''],

    PrimaryforwardType_2_day5: ['', ''],         
    number_2_day5: ['', ''],         
    ivrMenu_2_day5: ['', ''],         
    sipAdd_2_day5: ['', ''],

    PrimaryforwardType_3_day5: ['', ''],         
    number_3_day5: ['', ''],         
    ivrMenu_3_day5: ['', ''],         
    sipAdd_3_day5: ['', ''],

    PrimaryforwardType_4_day5: ['', ''],         
    number_4_day5: ['', ''],         
    ivrMenu_4_day5: ['', ''],         
    sipAdd_4_day5: ['', ''],

    PrimaryforwardType_5_day5: ['', ''],         
    number_5_day5: ['', ''],         
    ivrMenu_5_day5: ['', ''],         
    sipAdd_5_day5: ['', ''],

    PrimaryforwardType_6_day5: ['', ''],         
    number_6_day5: ['', ''],         
    ivrMenu_6_day5: ['', ''],         
    sipAdd_6_day5: ['', ''],

    PrimaryforwardType_7_day5: ['', ''],         
    number_7_day5: ['', ''],         
    ivrMenu_7_day5: ['', ''],         
    sipAdd_7_day5: ['', ''],

    PrimaryforwardType_8_day5: ['', ''],         
    number_8_day5: ['', ''],         
    ivrMenu_8_day5: ['', ''],         
    sipAdd_8_day5: ['', ''],

    PrimaryforwardType_9_day5: ['', ''],         
    number_9_day5: ['', ''],         
    ivrMenu_9_day5: ['', ''],         
    sipAdd_9_day5: ['', ''],


    // Day7
    day_6: ['', ''],   
    start_hrs_6: ['', ''],   
    start_min_6: ['', ''],   
    end_hrs_6: ['', ''],  
    end_min_6: ['', ''],  
  
    PrimaryforwardType_0_day6: ['', ''],         
    number_0_day6: ['', ''],         
    ivrMenu_0_day6: ['', ''],         
    sipAdd_0_day6: ['', ''],

    PrimaryforwardType_1_day6: ['', ''],         
    number_1_day6: ['', ''],         
    ivrMenu_1_day6: ['', ''],         
    sipAdd_1_day6: ['', ''],

    PrimaryforwardType_2_day6: ['', ''],         
    number_2_day6: ['', ''],         
    ivrMenu_2_day6: ['', ''],         
    sipAdd_2_day6: ['', ''],

    PrimaryforwardType_3_day6: ['', ''],         
    number_3_day6: ['', ''],         
    ivrMenu_3_day6: ['', ''],         
    sipAdd_3_day6: ['', ''],

    PrimaryforwardType_4_day6: ['', ''],         
    number_4_day6: ['', ''],         
    ivrMenu_4_day6: ['', ''],         
    sipAdd_4_day6: ['', ''],

    PrimaryforwardType_5_day6: ['', ''],         
    number_5_day6: ['', ''],         
    ivrMenu_5_day6: ['', ''],         
    sipAdd_5_day6: ['', ''],

    PrimaryforwardType_6_day6: ['', ''],         
    number_6_day6: ['', ''],         
    ivrMenu_6_day6: ['', ''],         
    sipAdd_6_day6: ['', ''],

    PrimaryforwardType_7_day6: ['', ''],         
    number_7_day6: ['', ''],         
    ivrMenu_7_day6: ['', ''],         
    sipAdd_7_day6: ['', ''],

    PrimaryforwardType_8_day6: ['', ''],         
    number_8_day6: ['', ''],         
    ivrMenu_8_day6: ['', ''],         
    sipAdd_8_day6: ['', ''],

    PrimaryforwardType_9_day6: ['', ''],         
    number_9_day6: ['', ''],         
    ivrMenu_9_day6: ['', ''],         
    sipAdd_9_day6: ['', ''],
  }); 

  this.editFSLRules = this.fb.group({
    rulename: ['', ''],   
    numberoftimeframe: ['', Validators.required],   
    noofforwardinglocation_1: ['', ''],   
    noofforwardinglocation_2: ['', ''],   
    noofforwardinglocation_3: ['', ''],   
    noofforwardinglocation_4: ['', ''],   
    noofforwardinglocation_5: ['', ''],   
    noofforwardinglocation_6: ['', ''],   
    noofforwardinglocation_7: ['', ''],   
  
    // Day 1
    day_0: ['', ''],   
    start_hrs_0: ['', ''],   
    start_min_0: ['', ''],   
    end_hrs_0: ['', ''],  
    end_min_0: ['', ''],  

    PrimaryforwardType_0_day0: ['', ''],         
    number_0_day0: ['', ''],         
    ivrMenu_0_day0: ['', ''],         
    sipAdd_0_day0: ['', ''],

    PrimaryforwardType_1_day0: ['', ''],         
    number_1_day0: ['', ''],         
    ivrMenu_1_day0: ['', ''],         
    sipAdd_1_day0: ['', ''],

    PrimaryforwardType_2_day0: ['', ''],         
    number_2_day0: ['', ''],         
    ivrMenu_2_day0: ['', ''],         
    sipAdd_2_day0: ['', ''],

    PrimaryforwardType_3_day0: ['', ''],         
    number_3_day0: ['', ''],         
    ivrMenu_3_day0: ['', ''],         
    sipAdd_3_day0: ['', ''],

    PrimaryforwardType_4_day0: ['', ''],         
    number_4_day0: ['', ''],         
    ivrMenu_4_day0: ['', ''],         
    sipAdd_4_day0: ['', ''],

    PrimaryforwardType_5_day0: ['', ''],         
    number_5_day0: ['', ''],         
    ivrMenu_5_day0: ['', ''],         
    sipAdd_5_day0: ['', ''],

    PrimaryforwardType_6_day0: ['', ''],         
    number_6_day0: ['', ''],         
    ivrMenu_6_day0: ['', ''],         
    sipAdd_6_day0: ['', ''],

    PrimaryforwardType_7_day0: ['', ''],         
    number_7_day0: ['', ''],         
    ivrMenu_7_day0: ['', ''],         
    sipAdd_7_day0: ['', ''],

    PrimaryforwardType_8_day0: ['', ''],         
    number_8_day0: ['', ''],         
    ivrMenu_8_day0: ['', ''],         
    sipAdd_8_day0: ['', ''],

    PrimaryforwardType_9_day0: ['', ''],         
    number_9_day0: ['', ''],         
    ivrMenu_9_day0: ['', ''],         
    sipAdd_9_day0: ['', ''],
        
    // Day2
    day_1: ['', ''],   
    start_hrs_1: ['', ''],   
    start_min_1: ['', ''],   
    end_hrs_1: ['', ''],  
    end_min_1: ['', ''],  
  
    PrimaryforwardType_0_day1: ['', ''],         
    number_0_day1: ['', ''],         
    ivrMenu_0_day1: ['', ''],         
    sipAdd_0_day1: ['', ''],

    PrimaryforwardType_1_day1: ['', ''],         
    number_1_day1: ['', ''],         
    ivrMenu_1_day1: ['', ''],         
    sipAdd_1_day1: ['', ''],

    PrimaryforwardType_2_day1: ['', ''],         
    number_2_day1: ['', ''],         
    ivrMenu_2_day1: ['', ''],         
    sipAdd_2_day1: ['', ''],

    PrimaryforwardType_3_day1: ['', ''],         
    number_3_day1: ['', ''],         
    ivrMenu_3_day1: ['', ''],         
    sipAdd_3_day1: ['', ''],

    PrimaryforwardType_4_day1: ['', ''],         
    number_4_day1: ['', ''],         
    ivrMenu_4_day1: ['', ''],         
    sipAdd_4_day1: ['', ''],

    PrimaryforwardType_5_day1: ['', ''],         
    number_5_day1: ['', ''],         
    ivrMenu_5_day1: ['', ''],         
    sipAdd_5_day1: ['', ''],

    PrimaryforwardType_6_day1: ['', ''],         
    number_6_day1: ['', ''],         
    ivrMenu_6_day1: ['', ''],         
    sipAdd_6_day1: ['', ''],

    PrimaryforwardType_7_day1: ['', ''],         
    number_7_day1: ['', ''],         
    ivrMenu_7_day1: ['', ''],         
    sipAdd_7_day1: ['', ''],

    PrimaryforwardType_8_day1: ['', ''],         
    number_8_day1: ['', ''],         
    ivrMenu_8_day1: ['', ''],         
    sipAdd_8_day1: ['', ''],

    PrimaryforwardType_9_day1: ['', ''],         
    number_9_day1: ['', ''],         
    ivrMenu_9_day1: ['', ''],         
    sipAdd_9_day1: ['', ''],

    // Day3
    day_2: ['', ''],   
    start_hrs_2: ['', ''],   
    start_min_2: ['', ''],   
    end_hrs_2: ['', ''],  
    end_min_2: ['', ''],  

    PrimaryforwardType_0_day2: ['', ''],         
    number_0_day2: ['', ''],         
    ivrMenu_0_day2: ['', ''],         
    sipAdd_0_day2: ['', ''],

    PrimaryforwardType_1_day2: ['', ''],         
    number_1_day2: ['', ''],         
    ivrMenu_1_day2: ['', ''],         
    sipAdd_1_day2: ['', ''],

    PrimaryforwardType_2_day2: ['', ''],         
    number_2_day2: ['', ''],         
    ivrMenu_2_day2: ['', ''],         
    sipAdd_2_day2: ['', ''],

    PrimaryforwardType_3_day2: ['', ''],         
    number_3_day2: ['', ''],         
    ivrMenu_3_day2: ['', ''],         
    sipAdd_3_day2: ['', ''],

    PrimaryforwardType_4_day2: ['', ''],         
    number_4_day2: ['', ''],         
    ivrMenu_4_day2: ['', ''],         
    sipAdd_4_day2: ['', ''],

    PrimaryforwardType_5_day2: ['', ''],         
    number_5_day2: ['', ''],         
    ivrMenu_5_day2: ['', ''],         
    sipAdd_5_day2: ['', ''],

    PrimaryforwardType_6_day2: ['', ''],         
    number_6_day2: ['', ''],         
    ivrMenu_6_day2: ['', ''],         
    sipAdd_6_day2: ['', ''],

    PrimaryforwardType_7_day2: ['', ''],         
    number_7_day2: ['', ''],         
    ivrMenu_7_day2: ['', ''],         
    sipAdd_7_day2: ['', ''],

    PrimaryforwardType_8_day2: ['', ''],         
    number_8_day2: ['', ''],         
    ivrMenu_8_day2: ['', ''],         
    sipAdd_8_day2: ['', ''],

    PrimaryforwardType_9_day2: ['', ''],         
    number_9_day2: ['', ''],         
    ivrMenu_9_day2: ['', ''],         
    sipAdd_9_day2: ['', ''],

    // Day4
    day_3: ['', ''],   
    start_hrs_3: ['', ''],   
    start_min_3: ['', ''],   
    end_hrs_3: ['', ''],  
    end_min_3: ['', ''],  
  
    PrimaryforwardType_0_day3: ['', ''],         
    number_0_day3: ['', ''],         
    ivrMenu_0_day3: ['', ''],         
    sipAdd_0_day3: ['', ''],

    PrimaryforwardType_1_day3: ['', ''],         
    number_1_day3: ['', ''],         
    ivrMenu_1_day3: ['', ''],         
    sipAdd_1_day3: ['', ''],

    PrimaryforwardType_2_day3: ['', ''],         
    number_2_day3: ['', ''],         
    ivrMenu_2_day3: ['', ''],         
    sipAdd_2_day3: ['', ''],

    PrimaryforwardType_3_day3: ['', ''],         
    number_3_day3: ['', ''],         
    ivrMenu_3_day3: ['', ''],         
    sipAdd_3_day3: ['', ''],

    PrimaryforwardType_4_day3: ['', ''],         
    number_4_day3: ['', ''],         
    ivrMenu_4_day3: ['', ''],         
    sipAdd_4_day3: ['', ''],

    PrimaryforwardType_5_day3: ['', ''],         
    number_5_day3: ['', ''],         
    ivrMenu_5_day3: ['', ''],         
    sipAdd_5_day3: ['', ''],

    PrimaryforwardType_6_day3: ['', ''],         
    number_6_day3: ['', ''],         
    ivrMenu_6_day3: ['', ''],         
    sipAdd_6_day3: ['', ''],

    PrimaryforwardType_7_day3: ['', ''],         
    number_7_day3: ['', ''],         
    ivrMenu_7_day3: ['', ''],         
    sipAdd_7_day3: ['', ''],

    PrimaryforwardType_8_day3: ['', ''],         
    number_8_day3: ['', ''],         
    ivrMenu_8_day3: ['', ''],         
    sipAdd_8_day3: ['', ''],

    PrimaryforwardType_9_day3: ['', ''],         
    number_9_day3: ['', ''],         
    ivrMenu_9_day3: ['', ''],         
    sipAdd_9_day3: ['', ''],

    // Day5
    day_4: ['', ''],   
    start_hrs_4: ['', ''],   
    start_min_4: ['', ''],   
    end_hrs_4: ['', ''],  
    end_min_4: ['', ''],  
  
    PrimaryforwardType_0_day4: ['', ''],         
    number_0_day4: ['', ''],         
    ivrMenu_0_day4: ['', ''],         
    sipAdd_0_day4: ['', ''],

    PrimaryforwardType_1_day4: ['', ''],         
    number_1_day4: ['', ''],         
    ivrMenu_1_day4: ['', ''],         
    sipAdd_1_day4: ['', ''],

    PrimaryforwardType_2_day4: ['', ''],         
    number_2_day4: ['', ''],         
    ivrMenu_2_day4: ['', ''],         
    sipAdd_2_day4: ['', ''],

    PrimaryforwardType_3_day4: ['', ''],         
    number_3_day4: ['', ''],         
    ivrMenu_3_day4: ['', ''],         
    sipAdd_3_day4: ['', ''],

    PrimaryforwardType_4_day4: ['', ''],         
    number_4_day4: ['', ''],         
    ivrMenu_4_day4: ['', ''],         
    sipAdd_4_day4: ['', ''],

    PrimaryforwardType_5_day4: ['', ''],         
    number_5_day4: ['', ''],         
    ivrMenu_5_day4: ['', ''],         
    sipAdd_5_day4: ['', ''],

    PrimaryforwardType_6_day4: ['', ''],         
    number_6_day4: ['', ''],         
    ivrMenu_6_day4: ['', ''],         
    sipAdd_6_day4: ['', ''],

    PrimaryforwardType_7_day4: ['', ''],         
    number_7_day4: ['', ''],         
    ivrMenu_7_day4: ['', ''],         
    sipAdd_7_day4: ['', ''],

    PrimaryforwardType_8_day4: ['', ''],         
    number_8_day4: ['', ''],         
    ivrMenu_8_day4: ['', ''],         
    sipAdd_8_day4: ['', ''],

    PrimaryforwardType_9_day4: ['', ''],         
    number_9_day4: ['', ''],         
    ivrMenu_9_day4: ['', ''],         
    sipAdd_9_day4: ['', ''],

    // Day6
    day_5: ['', ''],   
    start_hrs_5: ['', ''],   
    start_min_5: ['', ''],   
    end_hrs_5: ['', ''],  
    end_min_5: ['', ''],  
  
    PrimaryforwardType_0_day5: ['', ''],         
    number_0_day5: ['', ''],         
    ivrMenu_0_day5: ['', ''],         
    sipAdd_0_day5: ['', ''],

    PrimaryforwardType_1_day5: ['', ''],         
    number_1_day5: ['', ''],         
    ivrMenu_1_day5: ['', ''],         
    sipAdd_1_day5: ['', ''],

    PrimaryforwardType_2_day5: ['', ''],         
    number_2_day5: ['', ''],         
    ivrMenu_2_day5: ['', ''],         
    sipAdd_2_day5: ['', ''],

    PrimaryforwardType_3_day5: ['', ''],         
    number_3_day5: ['', ''],         
    ivrMenu_3_day5: ['', ''],         
    sipAdd_3_day5: ['', ''],

    PrimaryforwardType_4_day5: ['', ''],         
    number_4_day5: ['', ''],         
    ivrMenu_4_day5: ['', ''],         
    sipAdd_4_day5: ['', ''],

    PrimaryforwardType_5_day5: ['', ''],         
    number_5_day5: ['', ''],         
    ivrMenu_5_day5: ['', ''],         
    sipAdd_5_day5: ['', ''],

    PrimaryforwardType_6_day5: ['', ''],         
    number_6_day5: ['', ''],         
    ivrMenu_6_day5: ['', ''],         
    sipAdd_6_day5: ['', ''],

    PrimaryforwardType_7_day5: ['', ''],         
    number_7_day5: ['', ''],         
    ivrMenu_7_day5: ['', ''],         
    sipAdd_7_day5: ['', ''],

    PrimaryforwardType_8_day5: ['', ''],         
    number_8_day5: ['', ''],         
    ivrMenu_8_day5: ['', ''],         
    sipAdd_8_day5: ['', ''],

    PrimaryforwardType_9_day5: ['', ''],         
    number_9_day5: ['', ''],         
    ivrMenu_9_day5: ['', ''],         
    sipAdd_9_day5: ['', ''],


    // Day7
    day_6: ['', ''],   
    start_hrs_6: ['', ''],   
    start_min_6: ['', ''],   
    end_hrs_6: ['', ''],  
    end_min_6: ['', ''],  
  
    PrimaryforwardType_0_day6: ['', ''],         
    number_0_day6: ['', ''],         
    ivrMenu_0_day6: ['', ''],         
    sipAdd_0_day6: ['', ''],

    PrimaryforwardType_1_day6: ['', ''],         
    number_1_day6: ['', ''],         
    ivrMenu_1_day6: ['', ''],         
    sipAdd_1_day6: ['', ''],

    PrimaryforwardType_2_day6: ['', ''],         
    number_2_day6: ['', ''],         
    ivrMenu_2_day6: ['', ''],         
    sipAdd_2_day6: ['', ''],

    PrimaryforwardType_3_day6: ['', ''],         
    number_3_day6: ['', ''],         
    ivrMenu_3_day6: ['', ''],         
    sipAdd_3_day6: ['', ''],

    PrimaryforwardType_4_day6: ['', ''],         
    number_4_day6: ['', ''],         
    ivrMenu_4_day6: ['', ''],         
    sipAdd_4_day6: ['', ''],

    PrimaryforwardType_5_day6: ['', ''],         
    number_5_day6: ['', ''],         
    ivrMenu_5_day6: ['', ''],         
    sipAdd_5_day6: ['', ''],

    PrimaryforwardType_6_day6: ['', ''],         
    number_6_day6: ['', ''],         
    ivrMenu_6_day6: ['', ''],         
    sipAdd_6_day6: ['', ''],

    PrimaryforwardType_7_day6: ['', ''],         
    number_7_day6: ['', ''],         
    ivrMenu_7_day6: ['', ''],         
    sipAdd_7_day6: ['', ''],

    PrimaryforwardType_8_day6: ['', ''],         
    number_8_day6: ['', ''],         
    ivrMenu_8_day6: ['', ''],         
    sipAdd_8_day6: ['', ''],

    PrimaryforwardType_9_day6: ['', ''],         
    number_9_day6: ['', ''],         
    ivrMenu_9_day6: ['', ''],         
    sipAdd_9_day6: ['', ''],
  }); 

 
    this.dropdownSettings = { 
        singleSelection: false, 
        text:"Select Associated Label",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
      };   
    
    // this.citystatedropdownSettings = { 
    //     singleSelection: false, 
    //     text:"Select State",
    //     selectAllText:'Select All',
    //     unSelectAllText:'UnSelect All',
    //     enableSearchFilter: true,
    //     classes:"myclass custom-class"
    //   };   
   
      this.citydropdownSettings = { 
        singleSelection: false, 
        text:"Select City",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
      };   

      this.ivrdropdownSettings = { 
        singleSelection: true, 
        text:"Select IVR Menu",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
      };

    // if(localStorage.getItem('FSLRulesInsertID')){
    // this.getRules();
    // }
    // this.getAllLabels();
    this.addForm = this.fb.group({
      name: ['',Validators.required],      
      subid: [''],      
      buyer: [''],      
      settingToggleBlockCallsIfCapped: [''],      
      settingToggleType: [''],      
      number: [''],      
      ivrMenu: [''],      
      sip_address: [''],      
      
      // sip_endpoint: [''], 
      // sip_username: [''], 
      // sip_password: [''], 
      
      // connection_timeout: [''], 
      // dialivroption: [''], 
      
      // timezone: ['',Validators.required], 
      hoursofoperation_start: [''], 
      hoursofoperation_end: [''], 
      start_hrs: [''], 
      start_min: [''], 
      end_hrs: [''], 
      end_min: [''], 
      timezone: [''], 
     
      hoursofoperation_start_1: [''], 
      hoursofoperation_end_1: [''], 
      start_hrs_1: [''], 
      start_min_1: [''], 
      end_hrs_1: [''], 
      end_min_1: [''], 
      timezone1: [''], 
     
      capon: [''], 
      
      global_revenuecapvalue: [''], 
      monthly_revenuecapvalue: [''], 
      daily_revenuecapvalue: [''], 
      hourly_revenuecapvalue: [''], 

      dtmf_value: [''], 
      dtmf_interval: [''], 
      dtmf_percent: [''], 

      maxconvalue: [''], 
      
      restrictdup: [''], 
      restrictafter: [''], 
      days: [''], 
      settingToggleDurationval: [''],      
      settingToggleDuration: [''],      

      hourlyconcurrencyvalue: [''],            
      isfilter: [''],            
      citystate: [''],            
      cityval: [''],            
      ivr_agent_success: [''],            
      citystatetype: [''],            
      iscontains: [''],            
    });

    this.addForm.patchValue({
      citystatetype:1,
      iscontains:0,
      citystate:"",
      restrictafter:0,
      restrictdup:2,
      capon:0,
    });

    this.addBuyerForm = this.fb.group({
      company : ['', Validators.required],
      sub_id: [''],      
      allow_buyer_to_pause_target: [''],      
      allow_buyer_to_set_target_concurrency: [''],      
      allow_buyer_to_dispute_call_conversions: [''],      
      limit_revenue: [''],           
    });
    this.getcitystate(1,1)
  }

  allcity:any;
  getdata(){
      this.moduleService.loadcity(this.addForm.get('citystate').value).subscribe(
        res => {
          //  console.log(res); return false;  
          this.allcity = res;
        },
        error => this.error = error,
      );  
   }
 
  allcitystate:any = [];
  getcitystate(type:any,datatype:any){
    this.moduleService.loadcitystate(type,datatype).subscribe(
      res => {
        //  console.log(res); return false;  
        this.allcitystate = res;
      },
      error => this.error = error,
    );  
  }

  settingToggleTypeval:number = 0;
  settingToggleType(val:number) {
    this.settingToggleTypeval = val;
    this.addForm.get('settingToggleType').setValue(val); 
  } 
 
  settingToggleDurationvalue:any = 0;
  settingToggleDuration(val:number) {
    this.settingToggleDurationvalue = val;
    this.addForm.get('settingToggleDurationval').setValue(val); 
  } 

  get f() { return this.addForm.controls; }  
  onSubmit () {
    const formData = new FormData();      
    this.submitted = true;
    if (this.addForm.invalid) {
        return;
    }
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('user_uuid', this.currentUser.uuid);
    formData.append('name', this.addForm.get('name').value);
    formData.append('subid', this.addForm.get('subid').value);
    formData.append('buyer', this.addForm.get('buyer').value);
    // formData.append('settingToggleBlockCallsIfCapped', this.addForm.get('settingToggleBlockCallsIfCapped').value);
    formData.append('settingToggleType', this.addForm.get('settingToggleType').value);
    if(this.settingToggleTypeval==0){
      formData.append('number', this.addForm.get('number').value);
    }
    if(this.settingToggleTypeval==1){
      formData.append('ivrMenu', JSON.stringify(this.addForm.get('ivrMenu').value));        
    }
    if(this.settingToggleTypeval==2){
      formData.append('sip_address', this.addForm.get('sip_address').value);        
    }
    // formData.append('connection_timeout', this.addForm.get('connection_timeout').value);  
    // formData.append('disableRecordings', this.disableRecordings);  
    // formData.append('dialivroption', this.addForm.get('dialivroption').value);  
    // formData.append('timezone', this.addForm.get('timezone').value);  
    formData.append('start_day', this.addForm.get('hoursofoperation_start').value);  
    formData.append('end_day', this.addForm.get('hoursofoperation_end').value);  
    formData.append('timezone', this.addForm.get('timezone').value);  
    
    var starttime =  this.addForm.get('start_hrs').value+':'+this.addForm.get('start_min').value;
    var endtime =  this.addForm.get('end_hrs').value+':'+this.addForm.get('end_min').value;

    formData.append('start_time',starttime);  
    formData.append('end_time',endtime);  

    formData.append('isadded', this.isadded);  
    if(this.isadded==1){
      formData.append('start_day_1', this.addForm.get('hoursofoperation_start_1').value);  
      formData.append('end_day_1', this.addForm.get('hoursofoperation_end_1').value);  

      var starttime_1 =  this.addForm.get('start_hrs_1').value+':'+this.addForm.get('start_min_1').value;
      var endtime_1 =  this.addForm.get('end_hrs_1').value+':'+this.addForm.get('end_min_1').value;

      formData.append('start_time_1',starttime_1);  
      formData.append('end_time_1',endtime_1);  
      formData.append('timezone1', this.addForm.get('timezone1').value);  
    }

    formData.append('capon',  this.addForm.get('capon').value);  
    
    formData.append('is_autodtmf', this.dtmf_auto);
    formData.append('dtmf_zipcode_enable', this.dtmf_zipcode_enable);
    formData.append('dtmf_value',  this.addForm.get('dtmf_value').value);  
    formData.append('dtmf_interval',  this.addForm.get('dtmf_interval').value);  
    formData.append('dtmf_percent',  this.addForm.get('dtmf_percent').value);  

    formData.append('global_revenuecaptoggle', this.global_revenuecap);
    formData.append('global_revenuecapvalue',  this.addForm.get('global_revenuecapvalue').value);  
    formData.append('monthly_revenuecaptoggle', this.monthly_revenuecap);
    formData.append('monthly_revenuecapvalue',  this.addForm.get('monthly_revenuecapvalue').value);  
    formData.append('daily_revenuecaptoggle', this.daily_revenuecap);
    formData.append('daily_revenuecapvalue',  this.addForm.get('daily_revenuecapvalue').value);  
    formData.append('hourly_revenuecaptoggle', this.hourly_revenuecap);
    formData.append('hourly_revenuecapvalue',  this.addForm.get('hourly_revenuecapvalue').value);  

    formData.append('addcontoggle', this.maxconcurrency);
    formData.append('maxconvalue', this.addForm.get('maxconvalue').value);

    formData.append('restrictdup', this.addForm.get('restrictdup').value);
    formData.append('restrictafter', this.addForm.get('restrictafter').value);
    formData.append('settingToggleDurationval', this.settingToggleDurationvalue);
    formData.append('days', this.addForm.get('days').value);
    

    if(this.isfilter==1){
    formData.append('isfilter', this.addForm.get('isfilter').value);    
    formData.append('citystatetype', this.addForm.get('citystatetype').value);    
    formData.append('iscontains', this.addForm.get('iscontains').value);    
    formData.append('citystate', JSON.stringify(this.addForm.get('citystate').value));    
    formData.append('cityval', JSON.stringify(this.addForm.get('cityval').value));    
    }

    formData.append('ivr_agent_success', this.addForm.get('ivr_agent_success').value);

    const id = this.route.snapshot.paramMap.get('id');
    this.moduleService.addTarget(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Target Added Successfully');
          this.isTargetName = false;
          // localStorage.removeItem('FSLRulesInsertID');
          this.router.navigate(['/target']);
          // this.router.navigate(['/numbers']);
        }else{
          this.toastrService.error('Failed To Add');
        }
      },
      error => this.error = error,
    );     
  }

  isTargetName : any; 
  validateTargetname() {
  if(this.addForm.get('name').value!=''){     
    this.moduleService.validateTargetName(this.addForm.get('name').value).subscribe(
      res =>{
          // console.log(res); return false;  
        this.isTargetName = res;
        if (this.isTargetName.status == 'success') {
          this.isTargetName = "success";
        }
        else{
          this.isTargetName = "fail";
        }
      }    
    );
  }else{
    this.isTargetName="";
  }
}


BuyerSubmitted = false;
//Add Buyer
get buyercontrol() { return this.addBuyerForm.controls; }  
onBuyerSubmit () {
    const formData = new FormData();      
    this.BuyerSubmitted = true;
    if (this.addBuyerForm.invalid) {
        return;
    }

    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('company', this.addBuyerForm.get('company').value);
    formData.append('sub_id', this.addBuyerForm.get('sub_id').value);    
    formData.append('allow_buyer_to_pause_target', this.addBuyerForm.get('allow_buyer_to_pause_target').value);
    formData.append('allow_buyer_to_set_target_concurrency', this.addBuyerForm.get('allow_buyer_to_set_target_concurrency').value);
    formData.append('allow_buyer_to_dispute_call_conversions', this.addBuyerForm.get('allow_buyer_to_dispute_call_conversions').value);
    formData.append('limit_revenue', this.addBuyerForm.get('limit_revenue').value);

    // console.log(formData); return false;

    this.moduleService.addBuyer(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Buyer Added Successfully');  
          this.getAllRules();
          this.BuyerSubmitted = false;          
  
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );     
   }

   closeBuyerModal() {    
    if(this.addBuyerForm.get('company').value)
    {
      $("#addBuyer").modal("hide");         
      $(".custom-switch-input").prop('checked',false);
      this.BuyerSubmitted = false;
      setTimeout(() => { this.addBuyerForm.reset();}, 2000);    
    }
  }

  // Day0
  private selectedPrimaryForaward_0_day0: number=0;      
  private selectedPrimaryCount_0_day0: number=0; 
  private selected_0_day_0: number=0; 

  private selectedPrimaryForaward_1_day0: number=0;      
  private selectedPrimaryCount_1_day0: number=0; 
  private selected_1_day_0: number=0;  

  private selectedPrimaryForaward_2_day0: number=0;      
  private selectedPrimaryCount_2_day0: number=0; 
  private selected_2_day_0: number=0; 

  private selectedPrimaryForaward_3_day0: number=0;      
  private selectedPrimaryCount_3_day0: number=0; 
  private selected_3_day_0: number=0; 

  private selectedPrimaryForaward_4_day0: number=0;      
  private selectedPrimaryCount_4_day0: number=0; 
  private selected_4_day_0: number=0; 

  private selectedPrimaryForaward_5_day0: number=0;      
  private selectedPrimaryCount_5_day0: number=0; 
  private selected_5_day_0: number=0; 

  private selectedPrimaryForaward_6_day0: number=0;      
  private selectedPrimaryCount_6_day0: number=0; 
  private selected_6_day_0: number=0; 

  private selectedPrimaryForaward_7_day0: number=0;      
  private selectedPrimaryCount_7_day0: number=0; 
  private selected_7_day_0: number=0; 

  private selectedPrimaryForaward_8_day0: number=0;      
  private selectedPrimaryCount_8_day0: number=0; 
  private selected_8_day_0: number=0; 

  private selectedPrimaryForaward_9_day0: number=0;      
  private selectedPrimaryCount_9_day0: number=0; 
  private selected_9_day_0: number=0; 

 // Day1
 private selectedPrimaryForaward_0_day1: number=0;      
 private selectedPrimaryCount_0_day1: number=0; 
 private selected_0_day_1: number=0; 

 private selectedPrimaryForaward_1_day1: number=0;      
 private selectedPrimaryCount_1_day1: number=0; 
 private selected_1_day_1: number=0;  

 private selectedPrimaryForaward_2_day1: number=0;      
 private selectedPrimaryCount_2_day1: number=0; 
 private selected_2_day_1: number=0; 

 private selectedPrimaryForaward_3_day1: number=0;      
 private selectedPrimaryCount_3_day1: number=0; 
 private selected_3_day_1: number=0; 

 private selectedPrimaryForaward_4_day1: number=0;      
 private selectedPrimaryCount_4_day1: number=0; 
 private selected_4_day_1: number=0; 

 private selectedPrimaryForaward_5_day1: number=0;      
 private selectedPrimaryCount_5_day1: number=0; 
 private selected_5_day_1: number=0; 

 private selectedPrimaryForaward_6_day1: number=0;      
 private selectedPrimaryCount_6_day1: number=0; 
 private selected_6_day_1: number=0; 

 private selectedPrimaryForaward_7_day1: number=0;      
 private selectedPrimaryCount_7_day1: number=0; 
 private selected_7_day_1: number=0; 

 private selectedPrimaryForaward_8_day1: number=0;      
 private selectedPrimaryCount_8_day1: number=0; 
 private selected_8_day_1: number=0; 

 private selectedPrimaryForaward_9_day1: number=0;      
 private selectedPrimaryCount_9_day1: number=0; 
 private selected_9_day_1: number=0; 

// Day2
private selectedPrimaryForaward_0_day2: number=0;      
private selectedPrimaryCount_0_day2: number=0; 
private selected_0_day_2: number=0; 

private selectedPrimaryForaward_1_day2: number=0;      
private selectedPrimaryCount_1_day2: number=0; 
private selected_1_day_2: number=0;  

private selectedPrimaryForaward_2_day2: number=0;      
private selectedPrimaryCount_2_day2: number=0; 
private selected_2_day_2: number=0; 

private selectedPrimaryForaward_3_day2: number=0;      
private selectedPrimaryCount_3_day2: number=0; 
private selected_3_day_2: number=0; 

private selectedPrimaryForaward_4_day2: number=0;      
private selectedPrimaryCount_4_day2: number=0; 
private selected_4_day_2: number=0; 

private selectedPrimaryForaward_5_day2: number=0;      
private selectedPrimaryCount_5_day2: number=0; 
private selected_5_day_2: number=0; 

private selectedPrimaryForaward_6_day2: number=0;      
private selectedPrimaryCount_6_day2: number=0; 
private selected_6_day_2: number=0; 

private selectedPrimaryForaward_7_day2: number=0;      
private selectedPrimaryCount_7_day2: number=0; 
private selected_7_day_2: number=0; 

private selectedPrimaryForaward_8_day2: number=0;      
private selectedPrimaryCount_8_day2: number=0; 
private selected_8_day_2: number=0; 

private selectedPrimaryForaward_9_day2: number=0;      
private selectedPrimaryCount_9_day2: number=0; 
private selected_9_day_2: number=0; 

// Day3
private selectedPrimaryForaward_0_day3: number=0;      
private selectedPrimaryCount_0_day3: number=0; 
private selected_0_day_3: number=0; 

private selectedPrimaryForaward_1_day3: number=0;      
private selectedPrimaryCount_1_day3: number=0; 
private selected_1_day_3: number=0;  

private selectedPrimaryForaward_2_day3: number=0;      
private selectedPrimaryCount_2_day3: number=0; 
private selected_2_day_3: number=0; 

private selectedPrimaryForaward_3_day3: number=0;      
private selectedPrimaryCount_3_day3: number=0; 
private selected_3_day_3: number=0; 

private selectedPrimaryForaward_4_day3: number=0;      
private selectedPrimaryCount_4_day3: number=0; 
private selected_4_day_3: number=0; 

private selectedPrimaryForaward_5_day3: number=0;      
private selectedPrimaryCount_5_day3: number=0; 
private selected_5_day_3: number=0; 

private selectedPrimaryForaward_6_day3: number=0;      
private selectedPrimaryCount_6_day3: number=0; 
private selected_6_day_3: number=0; 

private selectedPrimaryForaward_7_day3: number=0;      
private selectedPrimaryCount_7_day3: number=0; 
private selected_7_day_3: number=0; 

private selectedPrimaryForaward_8_day3: number=0;      
private selectedPrimaryCount_8_day3: number=0; 
private selected_8_day_3: number=0; 

private selectedPrimaryForaward_9_day3: number=0;      
private selectedPrimaryCount_9_day3: number=0; 
private selected_9_day_3: number=0; 

// Day4
private selectedPrimaryForaward_0_day4: number=0;      
private selectedPrimaryCount_0_day4: number=0; 
private selected_0_day_4: number=0; 

private selectedPrimaryForaward_1_day4: number=0;      
private selectedPrimaryCount_1_day4: number=0; 
private selected_1_day_4: number=0;  

private selectedPrimaryForaward_2_day4: number=0;      
private selectedPrimaryCount_2_day4: number=0; 
private selected_2_day_4: number=0; 

private selectedPrimaryForaward_3_day4: number=0;      
private selectedPrimaryCount_3_day4: number=0; 
private selected_3_day_4: number=0; 

private selectedPrimaryForaward_4_day4: number=0;      
private selectedPrimaryCount_4_day4: number=0; 
private selected_4_day_4: number=0; 

private selectedPrimaryForaward_5_day4: number=0;      
private selectedPrimaryCount_5_day4: number=0; 
private selected_5_day_4: number=0; 

private selectedPrimaryForaward_6_day4: number=0;      
private selectedPrimaryCount_6_day4: number=0; 
private selected_6_day_4: number=0; 

private selectedPrimaryForaward_7_day4: number=0;      
private selectedPrimaryCount_7_day4: number=0; 
private selected_7_day_4: number=0; 

private selectedPrimaryForaward_8_day4: number=0;      
private selectedPrimaryCount_8_day4: number=0; 
private selected_8_day_4: number=0; 

private selectedPrimaryForaward_9_day4: number=0;      
private selectedPrimaryCount_9_day4: number=0; 
private selected_9_day_4: number=0; 

  setPrimaryforward(id:number,count:number,day:number){
    // Day0
    if(count==0 && day==0){
      this.selectedPrimaryForaward_0_day0 = id;    
      this.selectedPrimaryCount_0_day0 = count;
      this.selected_0_day_0 = day;
    }
    if(count==1 && day==0){
      this.selectedPrimaryForaward_1_day0 = id;    
      this.selectedPrimaryCount_1_day0 = count;
      this.selected_1_day_0 = day;
    }
    if(count==2 && day==0){
      this.selectedPrimaryForaward_2_day0 = id;    
      this.selectedPrimaryCount_2_day0 = count;
      this.selected_2_day_0 = day;
    }
    if(count==3 && day==0){
      this.selectedPrimaryForaward_3_day0 = id;    
      this.selectedPrimaryCount_3_day0 = count;
      this.selected_3_day_0 = day;
    }
    if(count==4 && day==0){
      this.selectedPrimaryForaward_4_day0 = id;    
      this.selectedPrimaryCount_4_day0 = count;
      this.selected_4_day_0 = day;
    }
    if(count==5 && day==0){
      this.selectedPrimaryForaward_5_day0 = id;    
      this.selectedPrimaryCount_5_day0 = count;
      this.selected_5_day_0 = day;
    }
    if(count==6 && day==0){
      this.selectedPrimaryForaward_6_day0 = id;    
      this.selectedPrimaryCount_6_day0 = count;
      this.selected_6_day_0 = day;
    }
    if(count==7 && day==0){
      this.selectedPrimaryForaward_7_day0 = id;    
      this.selectedPrimaryCount_7_day0 = count;
      this.selected_7_day_0 = day;
    }
    if(count==8 && day==0){
      this.selectedPrimaryForaward_8_day0 = id;    
      this.selectedPrimaryCount_8_day0 = count;
      this.selected_8_day_0 = day;
    }
    if(count==9 && day==0){
      this.selectedPrimaryForaward_9_day0 = id;    
      this.selectedPrimaryCount_9_day0 = count;
      this.selected_9_day_0 = day;
    }


   // Day1
   if(count==0 && day==1){
    this.selectedPrimaryForaward_0_day1 = id;    
    this.selectedPrimaryCount_0_day1 = count;
    this.selected_0_day_1 = day;
  }
  if(count==1 && day==1){
    this.selectedPrimaryForaward_1_day1 = id;    
    this.selectedPrimaryCount_1_day1 = count;
    this.selected_1_day_1 = day;
  }
  if(count==2 && day==1){
    this.selectedPrimaryForaward_2_day1 = id;    
    this.selectedPrimaryCount_2_day1 = count;
    this.selected_2_day_1 = day;
  }
  if(count==3 && day==1){
    this.selectedPrimaryForaward_3_day1 = id;    
    this.selectedPrimaryCount_3_day1 = count;
    this.selected_3_day_1 = day;
  }
  if(count==4 && day==1){
    this.selectedPrimaryForaward_4_day1 = id;    
    this.selectedPrimaryCount_4_day1 = count;
    this.selected_4_day_1 = day;
  }
  if(count==5 && day==1){
    this.selectedPrimaryForaward_5_day1 = id;    
    this.selectedPrimaryCount_5_day1 = count;
    this.selected_5_day_1 = day;
  }
  if(count==6 && day==1){
    this.selectedPrimaryForaward_6_day1 = id;    
    this.selectedPrimaryCount_6_day1 = count;
    this.selected_6_day_1 = day;
  }
  if(count==7 && day==1){
    this.selectedPrimaryForaward_7_day1 = id;    
    this.selectedPrimaryCount_7_day1 = count;
    this.selected_7_day_1 = day;
  }
  if(count==8 && day==1){
    this.selectedPrimaryForaward_8_day1 = id;    
    this.selectedPrimaryCount_8_day1 = count;
    this.selected_8_day_1 = day;
  }
  if(count==9 && day==1){
    this.selectedPrimaryForaward_9_day1 = id;    
    this.selectedPrimaryCount_9_day1 = count;
    this.selected_9_day_1 = day;
  }

  // Day2
  if(count==0 && day==2){
    this.selectedPrimaryForaward_0_day2 = id;    
    this.selectedPrimaryCount_0_day2 = count;
    this.selected_0_day_2 = day;
  }
  if(count==1 && day==2){
    this.selectedPrimaryForaward_1_day2 = id;    
    this.selectedPrimaryCount_1_day2 = count;
    this.selected_1_day_2 = day;
  }
  if(count==2 && day==2){
    this.selectedPrimaryForaward_2_day2 = id;    
    this.selectedPrimaryCount_2_day2 = count;
    this.selected_2_day_2 = day;
  }
  if(count==3 && day==2){
    this.selectedPrimaryForaward_3_day2 = id;    
    this.selectedPrimaryCount_3_day2 = count;
    this.selected_3_day_2 = day;
  }
  if(count==4 && day==2){
    this.selectedPrimaryForaward_4_day2 = id;    
    this.selectedPrimaryCount_4_day2 = count;
    this.selected_4_day_2 = day;
  }
  if(count==5 && day==2){
    this.selectedPrimaryForaward_5_day2 = id;    
    this.selectedPrimaryCount_5_day2 = count;
    this.selected_5_day_2 = day;
  }
  if(count==6 && day==2){
    this.selectedPrimaryForaward_6_day2 = id;    
    this.selectedPrimaryCount_6_day2 = count;
    this.selected_6_day_2 = day;
  }
  if(count==7 && day==2){
    this.selectedPrimaryForaward_7_day2 = id;    
    this.selectedPrimaryCount_7_day2 = count;
    this.selected_7_day_2 = day;
  }
  if(count==8 && day==2){
    this.selectedPrimaryForaward_8_day2 = id;    
    this.selectedPrimaryCount_8_day2 = count;
    this.selected_8_day_2 = day;
  }
  if(count==9 && day==2){
    this.selectedPrimaryForaward_9_day2 = id;    
    this.selectedPrimaryCount_9_day2 = count;
    this.selected_9_day_2 = day;
  }

   // Day3
   if(count==0 && day==3){
    this.selectedPrimaryForaward_0_day3 = id;    
    this.selectedPrimaryCount_0_day3 = count;
    this.selected_0_day_3 = day;
  }
  if(count==1 && day==3){
    this.selectedPrimaryForaward_1_day3 = id;    
    this.selectedPrimaryCount_1_day3 = count;
    this.selected_1_day_3 = day;
  }
  if(count==2 && day==3){
    this.selectedPrimaryForaward_2_day3 = id;    
    this.selectedPrimaryCount_2_day3 = count;
    this.selected_2_day_3 = day;
  }
  if(count==3 && day==3){
    this.selectedPrimaryForaward_3_day3 = id;    
    this.selectedPrimaryCount_3_day3 = count;
    this.selected_3_day_3 = day;
  }
  if(count==4 && day==3){
    this.selectedPrimaryForaward_4_day3 = id;    
    this.selectedPrimaryCount_4_day3 = count;
    this.selected_4_day_3 = day;
  }
  if(count==5 && day==3){
    this.selectedPrimaryForaward_5_day3 = id;    
    this.selectedPrimaryCount_5_day3 = count;
    this.selected_5_day_3 = day;
  }
  if(count==6 && day==3){
    this.selectedPrimaryForaward_6_day3 = id;    
    this.selectedPrimaryCount_6_day3 = count;
    this.selected_6_day_3 = day;
  }
  if(count==7 && day==3){
    this.selectedPrimaryForaward_7_day3 = id;    
    this.selectedPrimaryCount_7_day3 = count;
    this.selected_7_day_3 = day;
  }
  if(count==8 && day==3){
    this.selectedPrimaryForaward_8_day3 = id;    
    this.selectedPrimaryCount_8_day3 = count;
    this.selected_8_day_3 = day;
  }
  if(count==9 && day==3){
    this.selectedPrimaryForaward_9_day3 = id;    
    this.selectedPrimaryCount_9_day3 = count;
    this.selected_9_day_3 = day;
  }

  // Day4
  if(count==0 && day==4){
    this.selectedPrimaryForaward_0_day4 = id;    
    this.selectedPrimaryCount_0_day4 = count;
    this.selected_0_day_4 = day;
  }
  if(count==1 && day==4){
    this.selectedPrimaryForaward_1_day4 = id;    
    this.selectedPrimaryCount_1_day4 = count;
    this.selected_1_day_4 = day;
  }
  if(count==2 && day==4){
    this.selectedPrimaryForaward_2_day4 = id;    
    this.selectedPrimaryCount_2_day4 = count;
    this.selected_2_day_4 = day;
  }
  if(count==3 && day==4){
    this.selectedPrimaryForaward_3_day4 = id;    
    this.selectedPrimaryCount_3_day4 = count;
    this.selected_3_day_4 = day;
  }
  if(count==4 && day==4){
    this.selectedPrimaryForaward_4_day4 = id;    
    this.selectedPrimaryCount_4_day4 = count;
    this.selected_4_day_4 = day;
  }
  if(count==5 && day==4){
    this.selectedPrimaryForaward_5_day4 = id;    
    this.selectedPrimaryCount_5_day4 = count;
    this.selected_5_day_4 = day;
  }
  if(count==6 && day==4){
    this.selectedPrimaryForaward_6_day4 = id;    
    this.selectedPrimaryCount_6_day4 = count;
    this.selected_6_day_4 = day;
  }
  if(count==7 && day==4){
    this.selectedPrimaryForaward_7_day4 = id;    
    this.selectedPrimaryCount_7_day4 = count;
    this.selected_7_day_4 = day;
  }
  if(count==8 && day==4){
    this.selectedPrimaryForaward_8_day4 = id;    
    this.selectedPrimaryCount_8_day4 = count;
    this.selected_8_day_4 = day;
  }
  if(count==9 && day==4){
    this.selectedPrimaryForaward_9_day4 = id;    
    this.selectedPrimaryCount_9_day4 = count;
    this.selected_9_day_4 = day;
  }

  }

  isPrimarySelected(id:number,count:number,day:number): boolean   
  {
    // Day0
    if(count==0 && day==0){
      if(this.selectedPrimaryForaward_0_day0 == id && this.selectedPrimaryCount_0_day0 === count && this.selected_0_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==0){
      if(this.selectedPrimaryForaward_1_day0 == id && this.selectedPrimaryCount_1_day0 === count && this.selected_1_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==0){
      if(this.selectedPrimaryForaward_2_day0 == id && this.selectedPrimaryCount_2_day0 === count && this.selected_2_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==0){
      if(this.selectedPrimaryForaward_3_day0 == id && this.selectedPrimaryCount_3_day0 === count && this.selected_3_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==0){
      if(this.selectedPrimaryForaward_4_day0 == id && this.selectedPrimaryCount_4_day0 === count && this.selected_4_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==0){
      if(this.selectedPrimaryForaward_5_day0 == id && this.selectedPrimaryCount_5_day0 === count && this.selected_5_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==0){
      if(this.selectedPrimaryForaward_6_day0 == id && this.selectedPrimaryCount_6_day0 === count && this.selected_6_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==0){
      if(this.selectedPrimaryForaward_7_day0 == id && this.selectedPrimaryCount_7_day0 === count && this.selected_7_day_0== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==0){
      if(this.selectedPrimaryForaward_8_day0 == id && this.selectedPrimaryCount_8_day0 === count && this.selected_8_day_0== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==0){
      if(this.selectedPrimaryForaward_9_day0 == id && this.selectedPrimaryCount_9_day0 === count && this.selected_9_day_0== day)
      return true;
      else 
      return false; 
    }

    // Day1
    if(count==0 && day==1){
      if(this.selectedPrimaryForaward_0_day1 == id && this.selectedPrimaryCount_0_day1 === count && this.selected_0_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==1){
      if(this.selectedPrimaryForaward_1_day1 == id && this.selectedPrimaryCount_1_day1 === count && this.selected_1_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==1){
      if(this.selectedPrimaryForaward_2_day1 == id && this.selectedPrimaryCount_2_day1 === count && this.selected_2_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==1){
      if(this.selectedPrimaryForaward_3_day1 == id && this.selectedPrimaryCount_3_day1 === count && this.selected_3_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==1){
      if(this.selectedPrimaryForaward_4_day1 == id && this.selectedPrimaryCount_4_day1 === count && this.selected_4_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==1){
      if(this.selectedPrimaryForaward_5_day1 == id && this.selectedPrimaryCount_5_day1 === count && this.selected_5_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==1){
      if(this.selectedPrimaryForaward_6_day1 == id && this.selectedPrimaryCount_6_day1 === count && this.selected_6_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==1){
      if(this.selectedPrimaryForaward_7_day1 == id && this.selectedPrimaryCount_7_day1 === count && this.selected_7_day_1== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==1){
      if(this.selectedPrimaryForaward_8_day1 == id && this.selectedPrimaryCount_8_day1 === count && this.selected_8_day_1== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==1){
      if(this.selectedPrimaryForaward_9_day1 == id && this.selectedPrimaryCount_9_day1 === count && this.selected_9_day_1== day)
      return true;
      else 
      return false; 
    }

    // Day2
    if(count==0 && day==2){
      if(this.selectedPrimaryForaward_0_day2 == id && this.selectedPrimaryCount_0_day2 === count && this.selected_0_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==2){
      if(this.selectedPrimaryForaward_1_day2 == id && this.selectedPrimaryCount_1_day2 === count && this.selected_1_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==2){
      if(this.selectedPrimaryForaward_2_day2 == id && this.selectedPrimaryCount_2_day2 === count && this.selected_2_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==2){
      if(this.selectedPrimaryForaward_3_day2 == id && this.selectedPrimaryCount_3_day2 === count && this.selected_3_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==2){
      if(this.selectedPrimaryForaward_4_day2 == id && this.selectedPrimaryCount_4_day2 === count && this.selected_4_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==2){
      if(this.selectedPrimaryForaward_5_day2 == id && this.selectedPrimaryCount_5_day2 === count && this.selected_5_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==2){
      if(this.selectedPrimaryForaward_6_day2 == id && this.selectedPrimaryCount_6_day2 === count && this.selected_6_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==2){
      if(this.selectedPrimaryForaward_7_day2 == id && this.selectedPrimaryCount_7_day2 === count && this.selected_7_day_2== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==2){
      if(this.selectedPrimaryForaward_8_day2 == id && this.selectedPrimaryCount_8_day2 === count && this.selected_8_day_2== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==2){
      if(this.selectedPrimaryForaward_9_day2 == id && this.selectedPrimaryCount_9_day2 === count && this.selected_9_day_2== day)
      return true;
      else 
      return false; 
    }

    // Day3
    if(count==0 && day==3){
      if(this.selectedPrimaryForaward_0_day3 == id && this.selectedPrimaryCount_0_day3 === count && this.selected_0_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==3){
      if(this.selectedPrimaryForaward_1_day3 == id && this.selectedPrimaryCount_1_day3 === count && this.selected_1_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==3){
      if(this.selectedPrimaryForaward_2_day3 == id && this.selectedPrimaryCount_2_day3 === count && this.selected_2_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==3){
      if(this.selectedPrimaryForaward_3_day3 == id && this.selectedPrimaryCount_3_day3 === count && this.selected_3_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==3){
      if(this.selectedPrimaryForaward_4_day3 == id && this.selectedPrimaryCount_4_day3 === count && this.selected_4_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==3){
      if(this.selectedPrimaryForaward_5_day3 == id && this.selectedPrimaryCount_5_day3 === count && this.selected_5_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==3){
      if(this.selectedPrimaryForaward_6_day3 == id && this.selectedPrimaryCount_6_day3 === count && this.selected_6_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==3){
      if(this.selectedPrimaryForaward_7_day3 == id && this.selectedPrimaryCount_7_day3 === count && this.selected_7_day_3== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==3){
      if(this.selectedPrimaryForaward_8_day3 == id && this.selectedPrimaryCount_8_day3 === count && this.selected_8_day_3== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==3){
      if(this.selectedPrimaryForaward_9_day3 == id && this.selectedPrimaryCount_9_day3 === count && this.selected_9_day_3== day)
      return true;
      else 
      return false; 
    }

    // Day4
    if(count==0 && day==4){
      if(this.selectedPrimaryForaward_0_day4 == id && this.selectedPrimaryCount_0_day4 === count && this.selected_0_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==1 && day==4){
      if(this.selectedPrimaryForaward_1_day4 == id && this.selectedPrimaryCount_1_day4 === count && this.selected_1_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==2 && day==4){
      if(this.selectedPrimaryForaward_2_day4 == id && this.selectedPrimaryCount_2_day4 === count && this.selected_2_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==3 && day==4){
      if(this.selectedPrimaryForaward_3_day4 == id && this.selectedPrimaryCount_3_day4 === count && this.selected_3_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==4 && day==4){
      if(this.selectedPrimaryForaward_4_day4 == id && this.selectedPrimaryCount_4_day4 === count && this.selected_4_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==5 && day==4){
      if(this.selectedPrimaryForaward_5_day4 == id && this.selectedPrimaryCount_5_day4 === count && this.selected_5_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==6 && day==4){
      if(this.selectedPrimaryForaward_6_day4 == id && this.selectedPrimaryCount_6_day4 === count && this.selected_6_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==7 && day==4){
      if(this.selectedPrimaryForaward_7_day4 == id && this.selectedPrimaryCount_7_day4 === count && this.selected_7_day_4== day)
      return true;
      else 
      return false; 
    }
    if(count==8 && day==4){
      if(this.selectedPrimaryForaward_8_day4 == id && this.selectedPrimaryCount_8_day4 === count && this.selected_8_day_4== day)
      return true;
      else 
      return false; 
    }

    if(count==9 && day==4){
      if(this.selectedPrimaryForaward_9_day4 == id && this.selectedPrimaryCount_9_day4 === count && this.selected_9_day_4== day)
      return true;
      else 
      return false; 
    }
      
  }
  
  onReset() {
    this.submitted = false;
    this.addForm.reset();
  }

  ite:any;
  selectednoofforwardinglocation(){
    let array = [];
    if(this.noofforwardinglocation_1>=10){
      this.ite = 10;
    }else{
      this.ite = this.noofforwardinglocation_1;
    } 
    for (let i = 0; i < this.ite; i++) {
      array.push(i);
    }
    
    return array;
  }
  ite2:any;
  selectednoofforwardinglocation_2(){
    let array = [];
    if(this.noofforwardinglocation_2>=10){
      this.ite2 = 10;
    }else{
      this.ite2 = this.noofforwardinglocation_2;
    }  
    for (let i = 0; i < this.ite2; i++) {
      array.push(i);
    }
    return array;
  }
  ite3:any;
  selectednoofforwardinglocation_3(){
    let array = [];
    if(this.noofforwardinglocation_3>=10){
      this.ite3 = 10;
    }else{
      this.ite3 = this.noofforwardinglocation_3;
    }  
    for (let i = 0; i < this.ite3; i++) {
      array.push(i);
    }
    return array;
  }
  ite4:any;
  selectednoofforwardinglocation_4(){
    let array = [];
    if(this.noofforwardinglocation_4>=10){
      this.ite4 = 10;
    }else{
      this.ite4 = this.noofforwardinglocation_4;
    }  
    for (let i = 0; i < this.ite4; i++) {
      array.push(i);
    }
    return array;
  }
  ite5:any;
  selectednoofforwardinglocation_5(){
    let array = [];
    if(this.noofforwardinglocation_5>=10){
      this.ite5 = 10;
    }else{
      this.ite5 = this.noofforwardinglocation_5;
    }  
    for (let i = 0; i < this.ite5; i++) {
      array.push(i);
    }
    return array;
  }
  ite6:any;
  selectednoofforwardinglocation_6(){
    let array = [];
    if(this.noofforwardinglocation_6>=10){
      this.ite6 = 10;
    }else{
      this.ite6 = this.noofforwardinglocation_6;
    }  
    for (let i = 0; i < this.ite6; i++) {
      array.push(i);
    }
    return array;
  }
  ite7:any;
  selectednoofforwardinglocation_7(){
    let array = [];
    if(this.noofforwardinglocation_7>=10){
      this.ite7 = 10;
    }else{
      this.ite7 = this.noofforwardinglocation_7;
    }  
    for (let i = 0; i < this.ite7; i++) {
      array.push(i);
    }
    return array;
  }

// Day5
private selectedPrimaryForaward_0_day5: number=0;      
private selectedPrimaryCount_0_day5: number=0; 
private selected_0_day_5: number=0; 

private selectedPrimaryForaward_1_day5: number=0;      
private selectedPrimaryCount_1_day5: number=0; 
private selected_1_day_5: number=0;  

private selectedPrimaryForaward_2_day5: number=0;      
private selectedPrimaryCount_2_day5: number=0; 
private selected_2_day_5: number=0; 

private selectedPrimaryForaward_3_day5: number=0;      
private selectedPrimaryCount_3_day5: number=0; 
private selected_3_day_5: number=0; 

private selectedPrimaryForaward_4_day5: number=0;      
private selectedPrimaryCount_4_day5: number=0; 
private selected_4_day_5: number=0; 

private selectedPrimaryForaward_5_day5: number=0;      
private selectedPrimaryCount_5_day5: number=0; 
private selected_5_day_5: number=0; 

private selectedPrimaryForaward_6_day5: number=0;      
private selectedPrimaryCount_6_day5: number=0; 
private selected_6_day_5: number=0; 

private selectedPrimaryForaward_7_day5: number=0;      
private selectedPrimaryCount_7_day5: number=0; 
private selected_7_day_5: number=0; 

private selectedPrimaryForaward_8_day5: number=0;      
private selectedPrimaryCount_8_day5: number=0; 
private selected_8_day_5: number=0; 

private selectedPrimaryForaward_9_day5: number=0;      
private selectedPrimaryCount_9_day5: number=0; 
private selected_9_day_5: number=0; 

// Day6
private selectedPrimaryForaward_0_day6: number=0;      
private selectedPrimaryCount_0_day6: number=0; 
private selected_0_day_6: number=0; 

private selectedPrimaryForaward_1_day6: number=0;      
private selectedPrimaryCount_1_day6: number=0; 
private selected_1_day_6: number=0;  

private selectedPrimaryForaward_2_day6: number=0;      
private selectedPrimaryCount_2_day6: number=0; 
private selected_2_day_6: number=0; 

private selectedPrimaryForaward_3_day6: number=0;      
private selectedPrimaryCount_3_day6: number=0; 
private selected_3_day_6: number=0; 

private selectedPrimaryForaward_4_day6: number=0;      
private selectedPrimaryCount_4_day6: number=0; 
private selected_4_day_6: number=0; 

private selectedPrimaryForaward_5_day6: number=0;      
private selectedPrimaryCount_5_day6: number=0; 
private selected_5_day_6: number=0; 

private selectedPrimaryForaward_6_day6: number=0;      
private selectedPrimaryCount_6_day6: number=0; 
private selected_6_day_6: number=0; 

private selectedPrimaryForaward_7_day6: number=0;      
private selectedPrimaryCount_7_day6: number=0; 
private selected_7_day_6: number=0; 

private selectedPrimaryForaward_8_day6: number=0;      
private selectedPrimaryCount_8_day6: number=0; 
private selected_8_day_6: number=0; 

private selectedPrimaryForaward_9_day6: number=0;      
private selectedPrimaryCount_9_day6: number=0; 
private selected_9_day_6: number=0; 

  onFSLReset() {
    this.FSLRulesSubmitted = false;
    this.addFSLRules.reset();
  }


  selectedLevel :number =0;
  selected(){
    let array = [];
    for (let i = 0; i < this.noofforwarding; i++) {
      array.push(i);
    }
    return array;
  }


  Delete(id: string) {
    // alert(id)
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.moduleService.deleteFSLRulesbyname(id).subscribe(
            res => {
              this.getAllRules();
              Swal.fire(
                'Deleted!',
                'FSL Deleted Successfully.',
                'success',                
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }

  isadded:any = 0;
  addremoverow(type){
    if(type==0){
      this.isadded = 1;
    }
    if(type==1){
      this.isadded = 0;
    }
  }
}
