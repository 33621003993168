import { ModuleService } from './modules/module.service';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { ModulesModule } from './modules/modules.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AuthModule } from './auth/auth.module';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';
import { RouterModule } from '@angular/router';
import { httpInterceptorProviders } from './http-interceptors/index';
import { AuthService } from './auth/auth.service';
import { SidebarService } from './sidebar/sidebar.service';
import { ToastrModule } from 'ngx-toastr';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelect2Module } from 'ng-select2';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

import { MessagingService } from './modules/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [
    AppComponent ,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    PageNotFoundComponentComponent,  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    ModulesModule,
    RouterModule,
    NgSelect2Module,
    BrowserAnimationsModule,    
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NgxDatatableModule,
    AngularFireModule.initializeApp(environment),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({maxOpened:1}),
  ],
  providers: [AuthService,SidebarService,ModuleService,MessagingService, AsyncPipe,
    httpInterceptorProviders, {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// platformBrowserDynamic().bootstrapModule(AppModule);
