import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModuleService } from './../../module.service';
import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
declare var $;
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-number-upload',
  templateUrl: './number-upload.component.html',
  styleUrls: ['./number-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberUploadComponent implements OnInit {

  error: string;
  addForm: FormGroup;
  submitted = false;
  public currentCompany:any;
  rootNode: any;

  dropdownSettingss:IDropdownSettings;
  campaigndropdownSettings:IDropdownSettings;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  showLoadingIndicator: boolean;
  addLabelForm: FormGroup;
  @Input() selectednumber:any = [];


  public numberdata: Array<Select2OptionData>;
  public options: Options;

  constructor(
    private fb: FormBuilder,
    private moduleService: ModuleService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService:ToastrService,    
  ){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  }  

  public rulesData: any;
  public allRulesData: any;
  public allRulesbyname: any;
  public FSLRulesInsertID: any;
  public ruletext: any;

  getAllRules(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getAllRules(this.currentCompany.uuid).subscribe(
      res => {
        //  console.log(res); return false;  
        this.allRulesData = res;
      },
      error => this.error = error,
    );  
  }

  getRulesByName(name: string){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getRulesByName(name,this.currentCompany.uuid).subscribe(
      res => {
        this.allRulesbyname = res;            
      },
      error => this.error = error,
    );  
  }

  Allpublisher : any; 
  getAllpublisher(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleService.getAllpublishers(this.currentCompany.uuid).subscribe(
      res => {
        this.Allpublisher = res
      },
      error => this.error = error,
    );  
}

getallcampain : any; 
getAllcampaign(){
  this.moduleService.getallcampaindata(this.currentCompany.uuid).subscribe(
    res =>{
      this.getallcampain = res;     
      // console.log(this.getallcampain);
    }  
  ); 
}

getallruleset : any; 
getAllruleset(){
  this.moduleService.getallruleset(this.currentCompany.uuid).subscribe(
    res =>{
      this.getallruleset = res;     
      // console.log(this.getallcampain);
    }  
  ); 
}

getallparty : any; 
getAllparty(){
  this.moduleService.getallparty(this.currentCompany.uuid).subscribe(
    res =>{
      this.getallparty = res;     
      // console.log(this.getallcampain);
    }  
  ); 
}


payout_typevalueedit:any = 0;
payout_typevaledit(val:number) {
  this.payout_typevalueedit = val;
  this.addForm.get('payout_typeedit').setValue(val);   
}

getAllLabels(){
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleService.getAllLabels(this.currentCompany.uuid).subscribe(
    res => {
      this.dropdownList = res
    },
    error => this.error = error,
  );  
}

getselectednumberslist: any;
pageTitle:string="Import Numbers";
id:any
ngOnInit() { 
  this.getAllpublisher();
  this.getAllcampaign();
  this.getAllruleset();
  this.getAllLabels();
  this.getAllparty();
  // console.log(this.selectednumber);
  this.dropdownSettings = { 
        singleSelection: false, 
        text:"Select Associated Labels",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
      };   
 
   
   
    this.addForm = this.fb.group({
      number: ['',Validators.required],      
      campaign: [''],      
      ruleset: [''],      
      assosciated_labels: [''],      
      source: [''],      
      party: [''],      
      publisher: ['',Validators.required],   
      payout_typeedit: [''],      
      payout_amount: [''],      
      payout_trigger: [''],      
      payout_seconds: [''],            
    });

    this.addLabelForm = this.fb.group({
      name: ['', Validators.required],
      notes: ['',Validators.required],      
    });

    
    this.id = this.route.snapshot.paramMap.get('id');
    if(this.id==1){
    this.moduleService.getnumbersInfo().subscribe(info => {
      this.getselectednumberslist = info.numberslist;
      // console.log(this.getselectednumberslist);
      var numberlist='';
      if(this.getselectednumberslist){
      for (let index = 0; index < this.getselectednumberslist.length; index++) {
        numberlist+= this.getselectednumberslist[index]+"\r\n";
      }
      
        this.pageTitle = "Batch Edit";
        this.addForm.patchValue({number:numberlist.toString()});
      }      
    
      
      // this.addForm.get('number').setValue("hello");
      // this.addForm.patchValue({
      //   number:this.getselectednumberslist,      
      // });
    
      // console.log(this.getselectednumberslist);
    });
  }
}

  uploadfile: any; 
  filename: any; 
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const ext = file.name.split('.').pop(); ;

      if(ext == 'csv'){
        this.uploadfile = file;        
        this.filename = file.name;        
      }else{
        this.addForm.reset({
          number:''
        })
        this.toastrService.error('Please Select only .csv file to import numbers');
      }
    }
  }

  jklrules:string='false';
    get_jklrules(event:any){
      this.jklrules = event.target.checked;
    }

  get f() { return this.addForm.controls; }  
  onSubmit () {
    const formData = new FormData();      
    this.submitted = true;
    if (this.addForm.invalid) {
        return;
    }
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    // formData.append('number', this.uploadfile);    
    formData.append('number', this.addForm.get('number').value);    
    formData.append('company_uuid', this.currentCompany.uuid);    
    formData.append('campaign',this.addForm.get('campaign').value);
    formData.append('publisher', this.addForm.get('publisher').value);
    formData.append('payout_typeedit', this.addForm.get('payout_typeedit').value);
    formData.append('payout_amount', this.addForm.get('payout_amount').value);
    formData.append('payout_trigger', this.addForm.get('payout_trigger').value);
    formData.append('payout_seconds', this.addForm.get('payout_seconds').value);
    formData.append('ruleset', this.addForm.get('ruleset').value);
    formData.append('assosciated_labels', JSON.stringify(this.addForm.get('assosciated_labels').value));
    formData.append('source', this.addForm.get('source').value);
    formData.append('party', this.addForm.get('party').value);
    // console.log(formData); return false;
    
    this.showLoadingIndicator= true;
    this.moduleService.importNumber(formData).subscribe(
      res => {
        this.showLoadingIndicator= false;
        // console.log(res.duplicatenumbers); return false;
        if (res.status == 'success') {
          this.toastrService.success(res.noofrecords+' Number Imported Successfully');        
          // this.router.navigate(['/numbers']);
          if(res.duplicatenumbers!=''){
          var options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true, 
            // showTitle: true,
            // title: 'Your title',
            useBom: true,
            // noDownload: true,
            headers: ["Duplicate Numbers"]
          };
          new   ngxCsv(res.duplicatenumbers, 'All Duplicate Numbers', options);
          }
          this.router.navigate(['/numbers']);

        }else{
          this.toastrService.error('Failed To Add');
        }
      },
      error => this.error = error,
    );     
  }
    
  onReset() {
    this.submitted = false;
    this.addForm.reset();
  }


  closeModal() {    
    if(this.addLabelForm.get('name').value && this.addLabelForm.get('notes').value)
    {
      $("#addLabels").modal("hide");         
      $(".custom-switch-input").prop('checked',false);
      this.LabelSubmitted = false;
      setTimeout(() => {
        this.addLabelForm.reset();        
        this.isName="";
      }, 2000);    
    }
  }

  isName : any; 
  validatename() {
  if(this.addLabelForm.get('name').value!=''){     
    this.moduleService.validateLabelName(this.addLabelForm.get('name').value).subscribe(
      res =>{
          // console.log(res); return false;  
        this.isName = res;
        if (this.isName.status == 'success') {
          this.isName = "success";
        }
        else{
          this.isName = "fail";
        }
      }    
    );
  }else{
    this.isName="";
  }
}

LabelSubmitted = false;
  get labelcontrol() { return this.addLabelForm.controls; }  
  onLabelSubmit () {
    const formData = new FormData();      
    this.LabelSubmitted = true;
    if (this.addLabelForm.invalid) {
        return;
    }
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('name', this.addLabelForm.get('name').value);
    formData.append('notes', this.addLabelForm.get('notes').value);
    this.moduleService.addLabel(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Label Added Successfully');  
          this.getAllLabels();        
          this.LabelSubmitted = false;
          this.isName = false;
  
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );     
   }


}