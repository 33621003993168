import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found-component',
  templateUrl: './page-not-found-component.component.html',
  styleUrls: ['./page-not-found-component.component.scss']
})
export class PageNotFoundComponentComponent implements OnInit {
  showLoadingIndicator: boolean;

  constructor() { }

  ngOnInit() {
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
  }

}
