import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component,ViewEncapsulation, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router,ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {RequestOptions } from '@angular/http';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import "datatables.net";
import { ColumnMode, DatatableComponent, SortType } from '@swimlane/ngx-datatable';
import { Chart } from 'angular-highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
import 'moment-timezone';
HC_exporting(Highcharts);
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import 'rxjs/add/observable/interval';

import { DataTableDirective } from 'angular-datatables';

declare var $;
const router = Router;


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  totalarr:any[]
}

class filterarr
{
  filtertype: number;
  isorand: number;
  uuid: string;
  name: string;
  isdup: Number;
  duration: Number;
}

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  SortType = SortType;
  labelval: any;
  selected: any;
  numbers: any = '';
  alwaysShowCalendars: boolean;
  showRangeLabelOnInput: boolean;
  keepCalendarOpeningWithRange: boolean;
  // maxDate: moment.Moment;
  // minDate: moment.Moment;
 
  // @ViewChild('dataTable', {static: true}) table: ElementRef;
  @ViewChild('dataTable1', {static: true}) table1: ElementRef;
  @ViewChild('myTable') table: any;
  @ViewChild('myTable2') table2: any;
  @ViewChild('myTable3') table3: any;
  @ViewChild('myTable4') table4: any;
  @ViewChild('myTable5') table5: any;
  @ViewChild('myTable6') table6: any;
  @ViewChild('myTable7') table7: any;
  @ViewChild('myTable8') table8: any;

  dataTable: any;
  dataTable1: any;
  currentUser: any;
  currentCompany: any;
  label: Number;
  error: {};
  showLoadingIndicator: any;
  LoadingIndicatorlivecall: any;
  dtOptions: DataTables.Settings = {
   
  };
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptionsdetails: DataTables.Settings = {};
  labels: any = 0; 
  callsdata: any; 
  private http: HttpClient;
  searchcallsform: FormGroup;
  submitted = false;
  event:any="";
  expanded: any = {};
  timeout: any;
  rows: any[] = [];
  selectednewdates:any;
  // subscriptions : Subscription[];
  
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private el: ElementRef,private ngxLoader: NgxUiLoaderService,
    private cdRef:ChangeDetectorRef
  ){ 
    $("#summarytbl").dataTable().fnDestroy();
    this.dtOptions = {
      "order": [],
      paging:false,
      searching:false,
      info : false,
      
    }
    
    this.http = new HttpClient(handler);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.alwaysShowCalendars = true;
    this.keepCalendarOpeningWithRange = true;
    this.showRangeLabelOnInput = true;
    // this.subscription = Observable.interval(15000)
    // .subscribe((val) => { this.loadlivecalls(); });
    
    this.selected = {startDate: moment().startOf('month').format("MM/DD/YYYY"), endDate: moment().endOf('month').format("MM/DD/YYYY")};    
    this.selected1 = this.selected;  
    
  }


  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }
  
  toggleExpandRow(rowIndex,row) {
    // this.loadingIndicator = true;
    this.table.rowDetail.toggleExpandRow(row);    
    this.details(rowIndex,row);
  }
  
  toggleCollaspeRow(row) {
    this.table.rowDetail.collapseAllRows();
  }


  // Monthlycalls
  togglemonthlycalls(rowIndex,row) {
    this.table2.rowDetail.toggleExpandRow(row);    
    this.mcallsdetails(rowIndex,row);
  }
  togglemonthlyCollaspeRow(row) {
    this.table2.rowDetail.collapseAllRows();
  }
 
  // Weeklycalls
  toggleweeklycalls(rowIndex,row) {
    this.table3.rowDetail.toggleExpandRow(row);    
    this.wcallsdetails(rowIndex,row);
  }
  toggleweeklyCollaspeRow(row) {
    this.table3.rowDetail.collapseAllRows();
  }
  
// By Website

  // Weeklycalls
  toggleweeklywebsitecalls(rowIndex,row) {
    this.table6.rowDetail.toggleExpandRow(row);    
    this.wwcallsdetails(rowIndex,row);
  }
  toggleweeklywebsiteCollaspeRow(row) {
    this.table6.rowDetail.collapseAllRows();
  }

  // Monthlycalls
  togglewmonthlycalls(rowIndex,row) {
    this.table7.rowDetail.toggleExpandRow(row);    
    this.mwcallsdetails(rowIndex,row);
  }
  togglewmonthlyCollaspeRow(row) {
    this.table7.rowDetail.collapseAllRows();
  }
  
  sendMenuUpdateMessage(): void {
    // send message to subscribers via observable subject
      this.moduleservice.sendMenuUpdateMessage('1');
  }

  ranges: any = {
    Today: [ moment.tz(moment(), "America/New_York").format("MM/DD/YYYY"),  moment.tz(moment(), "America/New_York").format("MM/DD/YYYY")],
    Yesterday: [moment.tz(moment().subtract(1, 'days'), "America/New_York").format("MM/DD/YYYY"),  moment.tz(moment().subtract(1, 'days'), "America/New_York").format("MM/DD/YYYY")],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
     moment()
      .subtract(1, 'month')
      .startOf('month')
      ,
     moment()
      .subtract(1, 'month')
      .endOf('month')
    ],   
  };
 
  ngxclass="dark";
  subscriptions : Subscription[];
  subscription: Subscription;
  intervalId: number;
  ismobile: number = 0;
  ngOnInit() {
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    const uuid = this.route.snapshot.paramMap.get('id');
    // console.log(uuid);
    if(uuid!=null){      
      this.getsavedreports(uuid);
    }else{
      this.loadcalls("","");
      this.loadMonthlycalls("","");     
    }
    if($("body").hasClass("theme-white")) {
      this.ngxclass='material';
    }

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      // console.log('is mobile.....');
      this.ismobile = 1;
    }
 
  
  }


  weeklydayscalls = [];
  weeklycalls = [];
  monthlycalls = [];
  calls = [];
  lists = [];
  ColumnMode = ColumnMode;
  loadcalls(filterselected:any,date:any): void {
    
    // this.loadingIndicator = true;
    // this.ngxLoader.start();
    this.ngxLoader.startLoader('loader-01');
    // this.ngxLoader.start();
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const formData = new FormData();      
    formData.append('filterselected', JSON.stringify(filterselected));
    formData.append('timezone', currentUser.timezone);
    formData.append('user_uuid', currentUser.uuid);
    formData.append('date', JSON.stringify(date));
    formData.append('isallcalls', "0");

    this.http.post<any>(
      this.ServerUrl+'detail-report/'+currentCompany.uuid+'/0/0',            
       formData,{
        headers: new HttpHeaders()
        // .set('Pragma', 'no-cache') 
        // .set('Cache-Control', 'no-cache') 
      }
    ).subscribe(resp => {
        // this.monthlycalls = resp[0].Response.yrlydata;
        this.calls = resp[0].Response.data;
        this.lists = resp[0].Response.data;
        // this.loadingIndicator = false;
        // this.ngxLoader.stopBackground("loader-01");
        this.ngxLoader.stopLoader('loader-01');
        // console.log(this.blogPostsList);
    }, error => {
        // this.ngxLoader.stopBackground("loader-01");
        this.ngxLoader.stopLoader('loader-01');
        // this.loadingIndicator = false;
    });
   
  }
 
  loadMonthlycalls(filterselected:any,date:any): void {    
    // this.loadingIndicator = true;
    this.ngxLoader.startLoader('loader-02');
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const formData = new FormData();      
    formData.append('filterselected', JSON.stringify(filterselected));
    formData.append('timezone', currentUser.timezone);
    formData.append('user_uuid', currentUser.uuid);
    formData.append('date', JSON.stringify(date));
    formData.append('isallcalls', "0");

    this.http.post<any>(
      this.ServerUrl+'detail-monthly-report/'+currentCompany.uuid+'/0/0',            
       formData,{
        headers: new HttpHeaders()       
      }
    ).subscribe(resp => {
        this.monthlycalls = resp[0].Response.yrlydata;
       
        // this.loadingIndicator = false;
        this.ngxLoader.stopLoader('loader-02');        
    }, error => {
        // this.ngxLoader.stopBackground("loader-01");
        this.ngxLoader.stopLoader('loader-02');
        // this.loadingIndicator = false;
    });
   
  }

  loadWeeklycalls(filterselected:any,date:any): void {    
    // this.loadingIndicator = true;
    this.ngxLoader.startLoader('loader-03');
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const formData = new FormData();      
    formData.append('filterselected', JSON.stringify(filterselected));
    formData.append('timezone', currentUser.timezone);
    formData.append('date', JSON.stringify(date));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('isallcalls', "0");

    this.http.post<any>(
      this.ServerUrl+'detail-weekly-report/'+currentCompany.uuid+'/0/0',            
       formData,{
        headers: new HttpHeaders()       
      }
    ).subscribe(resp => {
        this.weeklycalls = resp[0].Response.yrlydata;
       
        // this.loadingIndicator = false;
        this.ngxLoader.stopLoader('loader-03');        
    }, error => {
        // this.ngxLoader.stopBackground("loader-01");
        this.ngxLoader.stopLoader('loader-03');
        // this.loadingIndicator = false;
    });
   
  }
  
  loadWeekdayscalls(filterselected:any,date:any): void {    
    // this.loadingIndicator = true;
    this.ngxLoader.startLoader('loader-04');
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const formData = new FormData();      
    formData.append('filterselected', JSON.stringify(filterselected));
    formData.append('timezone', currentUser.timezone);
    formData.append('date', JSON.stringify(date));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('isallcalls', "0");

    this.http.post<any>(
      this.ServerUrl+'detail-weekdays-report/'+currentCompany.uuid+'/0/0',            
       formData,{
        headers: new HttpHeaders()       
      }
    ).subscribe(resp => {
        this.weeklydayscalls = resp[0].Response.yrlydata;
       
        // this.loadingIndicator = false;
        this.ngxLoader.stopLoader('loader-04');        
    }, error => {
        // this.ngxLoader.stopBackground("loader-01");
        this.ngxLoader.stopLoader('loader-04');
        // this.loadingIndicator = false;
    });
   
  }

  websiteweeklycalls = [];
  loadWebsiteWeeklycalls(filterselected:any,date:any): void {    
    // this.loadingIndicator = true;
    this.ngxLoader.startLoader('loader-05');
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const formData = new FormData();      
    formData.append('filterselected', JSON.stringify(filterselected));
    formData.append('timezone', currentUser.timezone);
    formData.append('date', JSON.stringify(date));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('isallcalls', "0");

    this.http.post<any>(
      this.ServerUrl+'detail-website-weekly-report/'+currentCompany.uuid+'/0/0',            
       formData,{
        headers: new HttpHeaders()       
      }
    ).subscribe(resp => {
        this.websiteweeklycalls = resp[0].Response.yrlydata;
       
        // this.loadingIndicator = false;
        this.ngxLoader.stopLoader('loader-05');        
    }, error => {
        // this.ngxLoader.stopBackground("loader-01");
        this.ngxLoader.stopLoader('loader-05');
        // this.loadingIndicator = false;
    });
   
  }
  
  // by Website
  wmonthlycalls = [];
  loadwMonthlycalls(filterselected:any,date:any): void {    
    // this.loadingIndicator = true;
    this.ngxLoader.startLoader('loader-07');
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const formData = new FormData();      
    formData.append('filterselected', JSON.stringify(filterselected));
    formData.append('timezone', currentUser.timezone);
    formData.append('date', JSON.stringify(date));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('isallcalls', "0");

    this.http.post<any>(
      this.ServerUrl+'detail-website-monthly-report/'+currentCompany.uuid+'/0/0',            
       formData,{
        headers: new HttpHeaders()       
      }
    ).subscribe(resp => {
        this.wmonthlycalls = resp[0].Response.yrlydata;
        this.ngxLoader.stopLoader('loader-07');        
    }, error => {
        // this.ngxLoader.stopBackground("loader-01");
        this.ngxLoader.stopLoader('loader-07');
        // this.loadingIndicator = false;
    });
   
  }


// By brand website

brandwebsitecalls = [];
loadBrandWebsitecalls(filterselected:any,date:any): void {    
  // this.loadingIndicator = true;
  this.ngxLoader.startLoader('loader-08');
  let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const formData = new FormData();      
  formData.append('filterselected', JSON.stringify(filterselected));
  formData.append('timezone', currentUser.timezone);
  formData.append('date', JSON.stringify(date));
  formData.append('user_uuid', currentUser.uuid);
  formData.append('isallcalls', "0");

  this.http.post<any>(
    this.ServerUrl+'detail-brand-website-report/'+currentCompany.uuid+'/0/0',            
     formData,{
      headers: new HttpHeaders()       
    }
  ).subscribe(resp => {
      this.brandwebsitecalls = resp[0].Response.yrlydata;
     
      // this.loadingIndicator = false;
      this.ngxLoader.stopLoader('loader-08');        
  }, error => {
      // this.ngxLoader.stopBackground("loader-01");
      this.ngxLoader.stopLoader('loader-08');
      // this.loadingIndicator = false;
  });
 
}

brandandwebsitecalls = [];
website = [];
loadBrandAndWebsitecalls(filterselected:any,date:any): void {    
  // this.loadingIndicator = true;
  this.ngxLoader.startLoader('loader-09');
  let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const formData = new FormData();      
  formData.append('filterselected', JSON.stringify(filterselected));
  formData.append('timezone', currentUser.timezone);
  formData.append('date', JSON.stringify(date));
  formData.append('user_uuid', currentUser.uuid);
  formData.append('isallcalls', "0");

  this.http.post<any>(
    this.ServerUrl+'detail-brandandwebsite-report/'+currentCompany.uuid+'/0/0',            
     formData,{
      headers: new HttpHeaders()       
    }
  ).subscribe(resp => {
      this.brandandwebsitecalls = resp[0].Response.yrlydata;
      this.website = resp[0].Response.websites;
      
      this.ngxLoader.stopLoader('loader-09');        
  }, error => {
      // this.ngxLoader.stopBackground("loader-01");
      this.ngxLoader.stopLoader('loader-09');
      // this.loadingIndicator = false;
  });
 
}



  isLoading: boolean;
  readonly headerHeight = 40;
  readonly rowHeight = 40;
  readonly pageLimit = 100;
  // loadingIndicator = false;
  // onScroll(event:any) {
  //     let offsetY = event.offsetY;
  //     let offsetX = event.offsetX;
  //     // console.log(event.offsetY);return
  //     // if(offsetY != 125 || offsetY!=undefined){
  //       const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

  //         if (offsetX==0 && !this.loadingIndicator && offsetY + viewHeight >= this.calls.length * this.rowHeight) {
  //         // if (!this.loadingIndicator && offsetY + viewHeight >= this.rows.length * 100) {
  //         let limit = this.pageLimit;
  //         if (this.calls.length === 0) {
  //           const pageSize = Math.ceil(viewHeight / this.rowHeight);
  //           limit = Math.max(pageSize, this.pageLimit);
  //         }
          
  //         this.loaddataonscroll(limit);
  //       }
  //     // }
  // }




  filterselectedforexport:any;
  dateforexport:any;
  

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }



  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
       dtInstance.destroy();
       this.dtTrigger.next();     
    });
  }

   
  details(i,data){
            this.ngxLoader.startLoader('loader-01');
            var labels ='';
            if(data.labelwise_calls.length>0){
              
              for (let index = 0; index < data.labelwise_calls.length; index++) {
                const element = data.labelwise_calls[index];
                const gt60data = data.gt60labelwise_calls.filter((item,i)=>item.label == element.label);
                labels += ` <tr style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${element.label}</td>
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${element.count}</td>
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${gt60data && gt60data.length>0 ? gt60data[0].count:'-'}</td>
              </tr>`;                                             
              }             
            }else{
              labels += ` <tr style="border: 1px solid #d7d7d7;
              height: 35px!important;
              padding: 12px!important;">
              <td style="border: 1px solid #d7d7d7;
              height: 35px!important;
              padding: 12px!important;" colspan="3">No data found</td>              
            </tr>`;                                             
            }
            
    
        var finalresult = `<span class="text-job text-primary">Brand Wise Calls</span><table style="width: -webkit-fill-available;" class="monthcalls" id="myTable">
                <tr style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">Brand Name</th>
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">Calls</th>                  
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">>60 Calls</th>                  
                </tr>
                ${labels}
              </table>`;
        
        setTimeout(() => {
          $('.appendData').append(finalresult);
          this.ngxLoader.stopLoader('loader-01');
        }, 2000);
       
}
 
mcallsdetails(i,data){
            this.ngxLoader.startLoader('loader-02');
            var labels ='';

            if(data.labelwise_calls.length>0){
              
              for (let index = 0; index < data.labelwise_calls.length; index++) {
                const element = data.labelwise_calls[index];
                const gt60data = data.gt60labelwise_calls.filter((item,i)=>item.label == element.label);
                labels += ` <tr style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${element.label}</td>
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${element.count}</td>
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${gt60data && gt60data.length>0 ? gt60data[0].count:'-'}</td>
              </tr>`;                                             
              }             
            }else{
              labels += ` <tr style="border: 1px solid #d7d7d7;
              height: 35px!important;
              padding: 12px!important;">
              <td style="border: 1px solid #d7d7d7;
              height: 35px!important;
              padding: 12px!important;" colspan="3">No data found</td>              
            </tr>`;                                             
            }
            
    
        var finalresult = `'<span class="text-job text-primary">Brand Wise Calls</span><table style="width: -webkit-fill-available;" class="monthcalls" id="myTable">
                <tr style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">Brand Name</th>
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">Calls</th>
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">>60 Calls</th>        
                </tr>
                ${labels}
              </table>`;
        
        setTimeout(() => {
          // console.log(finalresult);          
          $('.appendMonthlyData').append(finalresult);
          this.ngxLoader.stopLoader('loader-02');
        }, 2000);
       
}
  
  wcallsdetails(i,data){
            this.ngxLoader.startLoader('loader-03');
            var labels ='';
            if(data.labelwise_calls.length>0){              
              for (let index = 0; index < data.labelwise_calls.length; index++) {
                const element = data.labelwise_calls[index];
                const gt60data = data.gt60labelwise_calls.filter((item,i)=>item.label == element.label);
                labels += ` <tr style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${element.label}</td>
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${element.count}</td>
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${gt60data && gt60data.length>0 ? gt60data[0].count:'-'}</td>
              </tr>`;                                             
              }             
            }else{
              labels += ` <tr style="border: 1px solid #d7d7d7;
              height: 35px!important;
              padding: 12px!important;">
              <td style="border: 1px solid #d7d7d7;
              height: 35px!important;
              padding: 12px!important;" colspan="3">No data found</td>              
            </tr>`;                                             
            }
            
    
        var finalresult = `'<span class="text-job text-primary">Brand Wise Calls</span><table style="width: -webkit-fill-available;" class="monthcalls" id="myTable">
                <tr style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">Brand Name</th>
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">Calls</th>
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">>60 Calls</th>        
                </tr>
                ${labels}
              </table>`;
        
        setTimeout(() => {
          $('.appendWeeklyData').append(finalresult);
          this.ngxLoader.stopLoader('loader-03');
        }, 2000);
       
  }

  // By Website
 
  wwcallsdetails(i,data){
            this.ngxLoader.startLoader('loader-05');
            var labels ='';
            if(data.labelwise_calls.length>0){
              
              for (let index = 0; index < data.labelwise_calls.length; index++) {
                const element = data.labelwise_calls[index];
                const gt60data = data.gt60labelwise_calls.filter((item,i)=>item.label == element.label);

                labels += ` <tr style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${element.label}</td>
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${element.count}</td>
                <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${gt60data && gt60data.length>0 ? gt60data[0].count:'-'}</td>
              </tr>`;                                             
              }             
            }else{
              labels += ` <tr style="border: 1px solid #d7d7d7;
              height: 35px!important;
              padding: 12px!important;">
              <td style="border: 1px solid #d7d7d7;
              height: 35px!important;
              padding: 12px!important;" colspan="3">No data found</td>              
            </tr>`;                                             
            }
            
    
        var finalresult = `'<span class="text-job text-primary">Brand Wise Calls</span><table style="width: -webkit-fill-available;" class="monthcalls" id="myTable">
                <tr style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">Website Name</th>
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">Calls</th>
                  <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">>60 Calls</th>        
                </tr>
                ${labels}
              </table>`;
        
        setTimeout(() => {
          $('.appendWeeklyWebsiteData').append(finalresult);
          this.ngxLoader.stopLoader('loader-05');
        }, 2000);
       
  }

  mwcallsdetails(i,data){
    this.ngxLoader.startLoader('loader-07');
    var labels ='';

    if(data.labelwise_calls.length>0){
      
      for (let index = 0; index < data.labelwise_calls.length; index++) {
        const element = data.labelwise_calls[index];
        const gt60data = data.gt60labelwise_calls.filter((item,i)=>item.label == element.label);
        labels += ` <tr style="border: 1px solid #d7d7d7;
        height: 35px!important;
        padding: 12px!important;">
        <td style="border: 1px solid #d7d7d7;
        height: 35px!important;
        padding: 12px!important;">${element.label}</td>
        <td style="border: 1px solid #d7d7d7;
        height: 35px!important;
        padding: 12px!important;">${element.count}</td>
        <td style="border: 1px solid #d7d7d7;
                height: 35px!important;
                padding: 12px!important;">${gt60data && gt60data.length>0 ? gt60data[0].count:'-'}</td>
      </tr>`;                                             
      }             
    }else{
      labels += ` <tr style="border: 1px solid #d7d7d7;
      height: 35px!important;
      padding: 12px!important;">
      <td style="border: 1px solid #d7d7d7;
      height: 35px!important;
      padding: 12px!important;" colspan="3">No data found</td>      
              
    </tr>`;                                             
    }
    

var finalresult = `'<span class="text-job text-primary">Website Wise Calls</span><table style="width: -webkit-fill-available;" class="monthcalls" id="myTable">
        <tr style="border: 1px solid #d7d7d7;
        height: 35px!important;
        padding: 12px!important;">
          <th style="background: #f8aa00!important;
          color: white!important;padding: 12px!important;">Website Name</th>
          <th style="background: #f8aa00!important;
          color: white!important;padding: 12px!important;">Calls</th>
          <th style="background: #f8aa00!important;
                  color: white!important;padding: 12px!important;">>60 Calls</th>        
        </tr>
        ${labels}
      </table>`;

setTimeout(() => {
  // console.log(finalresult);          
  $('.appendWMonthlyData').append(finalresult);
  this.ngxLoader.stopLoader('loader-07');
}, 2000);

}


  
   noofrecords:any = 500;
   exportdata(){
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      useBom: true,
      headers: ["Website", "Brand", "Caller ID", "Call Number",  "Forwarded Number","Date of Call","Time of Call","Call Duration"]
    };

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const formData = new FormData();      
    formData.append('user_uuid', currentUser.uuid);
    formData.append('timezone', this.currentUser.timezone);
    formData.append('filterselected', JSON.stringify(this.filterselected));
    formData.append('date',JSON.stringify(this.selecteddate));
    formData.append('totalrecords', this.noofrecords);
    this.showLoadingIndicator =true;
    this.moduleservice.getCalldetailsForExport(this.currentCompany.uuid,formData).subscribe(
      res => {
        new   ngxCsv(res.data, 'All Calls', options);
        this.showLoadingIndicator =false;
      },
      error => this.error = error
  );      
  }

  exportcalldata(){
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      useBom: true,
      headers: ["UUID","Name(Filter)", "Incoming","Connected","Qualified","Converted","Completed","No Answer","No Connect","Payout","Revenue","Profit","CR%","RPC","ACL","TCL"]
    };
    new   ngxCsv(this.callsdata, 'All Calls', options);
  }

  isclick:number = 0;
  istougle:number = 1;

  togglepanel(){
    if(this.isclick==1){
      this.istougle = 1;
      this.isclick = 0;
    }else{
      this.isclick = 1;
      this.istougle = 0;
    }
  }

  filter:number;
  filtertext:string;
  activeresultdata:any; 
  resultdata:any; 
  nonactiveresultdata:any; 
  filtertype:any = 1;   
  filterdata(type:number){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.filter = 0;
    this.isselectElement = 0;
    if(type == 1){      
      this.activeresultdata=[];
      this.nonactiveresultdata=[];
      this.filter = 1;
      this.filtertype = 1;
      this.filtertext = 'Select Campaign';
      this.filtervalue="";
      this.moduleservice.getallcampain(this.currentCompany.uuid).subscribe(
        res => {
          this.activeresultdata = res.active;
          this.nonactiveresultdata = res.non_active;
        });
    }
    if(type == 2){
     this.resultdata=[];
      this.filtertype = 2;
      this.filter = 1;
      this.filtertext = 'Select Target';
      this.filtervalue="";
      this.moduleservice.getAlltarget(this.currentCompany.uuid).subscribe(
        res => {
          this.resultdata = res;
        });
    }
    if(type == 3){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 3;
      this.filtertext = 'Select Buyer';
      this.filtervalue="";
      this.moduleservice.getallbuyers(this.currentCompany.uuid).subscribe(
        res => {
          this.resultdata = res;
        });      
    }
    if(type == 4){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 4;
      this.filtertext = 'Select Publisher';
      this.filtervalue="";
      this.moduleservice.getAllpublishers(this.currentCompany.uuid).subscribe(
        res => {
          this.resultdata = res;
        });     
    }
   
    if(type == 5){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 5;
      this.filtertext = 'Enter Caller ID';
      this.filtervalue="";    
    }
  
    if(type == 6){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 6;
      this.filtertext = 'Enter Number Called';
      this.filtervalue="";    
    }
   
    if(type == 8){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 8;
      this.filtertext = 'Select IVR Inputs';
      this.filtervalue="";    
      this.resultdata = [{
        'id':0,
        'text':'No',
      },{
        'id':1,
        'text':'Yes',
      }];
    }
    
    if(type == 11){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 11;
      this.filtertext = 'Select Duration';
      this.filtervalue="";    
      this.resultdata = [{
        'id':0,
        'text':'Greater Than',
      }
      // ,{
      //   'id':1,
      //   'text':'Less Than',
      // }
    ];
    }

  }

  clearfilter(index:any){
    // this.isselectElement = 0;
    this.filterselected.splice(index,1);    
    
    this.loadcalls(this.filterselected,this.selecteddate);
    this.loadMonthlycalls(this.filterselected,this.selecteddate);
    this.loadWeeklycalls(this.filterselected,this.selecteddate);
    this.loadWeekdayscalls(this.filterselected,this.selecteddate);    
  }

  filtervalue: any = '';
  filterdate: any = '';
  startdate: any;
  enddate: any;
  diff: any;
  days: any;
  selected1: any;
  selecteddate: any;
  isadded: any=1;
  filterval(event:any){
    // console.log(this.selected1); return false;
    // this.filterselected=[];
    let customObj = new filterarr();
    customObj.filtertype = this.filtertype;
    customObj.uuid = this.filtervalue; 
    customObj.name = this.selectElementText; 
    customObj.duration = this.selectElementDuration; 
    customObj.isorand = 0; 
    // console.log(this.selectElementText);
    if(this.selectElementText!=undefined && this.filterselected?.length==0 && !event){
      this.filterselected.push(customObj);  
    }

    if(event && event.startDate==null && event.endDate==null)
    return false;
    if(event){
    var start = moment(event.startDate._d).format('MM/DD/YYYY HH:mm:ss');
    var end = moment(event.endDate._d).format('MM/DD/YYYY HH:mm:ss');
    // console.log(this.startdate);
    // console.log(this.enddate);return false;
    this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
    this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
    
    // this.diff = this.enddate.diff(this.startdate, 'days');
    // console.log(this.startdate);
    
      this.selected1 = {
        startDate: this.startdate,
        endDate: this.enddate,
      };
      this.selecteddate = this.selected1;
      // console.log(this.selected1);
      var a = moment(start);
      var b = moment(end);
      if(b.diff(a, 'days')>0){
        this.daytypeval = 2;      
      }else{
        this.daytypeval = 1;      
      }
    }else{
      // console.log(123);
      
      this.isselectElement = 1;
      this.isadded = 0;
      const uuid = this.route.snapshot.paramMap.get('id');
      if(uuid!=null){        
        var start = moment(this.selected.startDate).format('MM/DD/YYYY HH:mm:ss');
        var end = moment(this.selected.endDate).format('MM/DD/YYYY HH:mm:ss'); 
      }else{
        if(this.selected1.startDate._d == undefined){
          var start = moment(this.selected1.startDate).format('MM/DD/YYYY HH:mm:ss');
          var end = moment(this.selected1.endDate).format('MM/DD/YYYY HH:mm:ss');
        }else{
          var start = moment(this.selected1.startDate._d).format('MM/DD/YYYY HH:mm:ss');
          var end = moment(this.selected1.endDate._d).format('MM/DD/YYYY HH:mm:ss');
        }
      }   
      
      // $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
      // $("#tabs1").addClass("activetab");this.filtertype=3;
      
      // console.log(this.selected.startDate);
      // console.log(end);
      // console.log(this.enddate);return false;
      this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
      this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
      this.selected1 = {
        startDate: this.startdate,
        endDate: this.enddate,
      };
     
      // console.log(123);
      this.selecteddate = this.selected1;
      this.daytypeval = 2;
    }

    this.loadBrandAndWebsitecalls(this.filterselected,"");
    // this.loadcalls(this.filterselected,this.selecteddate);
    // this.loadMonthlycalls(this.filterselected,this.selecteddate);
    // this.loadWeeklycalls(this.filterselected,this.selecteddate);
    // this.loadWeekdayscalls(this.filterselected,this.selecteddate);
  }
  

  calcelfilter(){
    this.isclick = 0; 
    // this.filter = 0; 
    // this.filtertype = 0; 
    this.isselectElement = 0; 
    this.filtertype = 1; 
    this.filterdata(1); 
    this.filtervalue = ''; 
    this.filterselected=[];
    this.isadded = 1;
    this.loadcalls("","");
    this.loadMonthlycalls("","");
    this.loadWeeklycalls("","");
    this.loadWeekdayscalls("","");
  
  }

  isselectElement:any=0;
  selectElementText:any;
  selectElementDuration:any="";
  filterselected: Array<filterarr> = [];
  gettext(event:any,type){
    if(type==1){
     this.selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
    }else if(type==3){
     
    //  this.selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
     this.selectElementDuration = event.target.value;      
    //  console.log(this.selectElementDuration);
    }else{
     this.selectElementText = event.target.value
    }
    // console.log(event.target.value);   
  }
  addorrule(){
    let customObj = new filterarr();
    customObj.filtertype = this.filtertype;
    customObj.uuid = this.filtervalue; 
    customObj.name = this.selectElementText; 
    customObj.duration = this.selectElementDuration; 
    customObj.isorand = 0; 
    // console.log(this.selectElementText);
    if(this.selectElementText!=undefined){
      this.filterselected.push(customObj);  
    }
    // console.log(this.filterselected);
  }

  addandrule(){
    let customObj = new filterarr();
    customObj.filtertype = this.filtertype;
    customObj.uuid = this.filtervalue; 
    customObj.name = this.selectElementText; 
    customObj.duration = this.selectElementDuration; 
    customObj.isorand = 1; 
    // console.log(this.selectElementText);
    if(this.selectElementText!=undefined){
      this.filterselected.push(customObj);  
    }
  }

  editfilter(){
    this.isadded=1;
    this.isselectElement=0;
  }

  charttype:string = "Calls Per Hour";
  daytypeval:number = 1;
  chartby(type:number){
    this.filterdate="";
    if(type==1){
      this.daytypeval = 1;
      this.charttype = "Calls Per Hour";
      // this.getChartNumberOfCalls(0,0,1);
    }
    if(type==2){
      this.daytypeval = 2;
      this.charttype = "Calls Per Day";
      // this.getChartNumberOfCalls(0,0,2);
    }
    if(type==3){
      this.daytypeval = 3;
      this.charttype = "Calls Per Month";
      // this.getChartNumberOfCalls(0,0,3);
    }    
  }

  savedreportsettings:any;
  selecteddates:any;
  isreport:any = 0;
  getsavedreports(uuid:string){
    this.moduleservice.getsavedreportsbyuuid(uuid).subscribe(
      res => {
        this.labelval = res.reportlabelname;
        this.daytypeval = 2;     
        if(res.filtertype!=''){
          this.isreport = 1;
          if(res.filtertype!=0){ this.isclick = 1; }
          this.filter = res.filtertype;
          let startday = res.start_date;
          let endday = res.end_date;
          const d = new Date(startday);
          const d1 = new Date(endday);
          moment(d).format('MMMM d, YYYY');
          moment(d1).format('MMMM d, YYYY');
          var start = moment(d).format('MM/DD/YYYY HH:mm:ss');
          var end = moment(d1).format('MM/DD/YYYY HH:mm:ss');
        
          this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
          this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
          // console.log(this.filter);
          // console.log(this.filtervalue);
          // console.log(this.selected);
          
          this.selected = {startDate: d, endDate: d1};
          this.selecteddates = {startDate: this.startdate, endDate: this.enddate};
          this.filterselected = JSON.parse(res.filterarr);
          var a = moment(d);
          var b = moment(d1);
          if(b.diff(a, 'days')>0){
              this.daytypeval = 2;
              this.charttype = "Calls Per Day";
          }else{
              this.daytypeval = 1;
              this.charttype = "Calls Per Hour";
          }
        
        }
        this.filterdata(this.filter);
        if(res.filtervalue!=''){
          this.isclick = 1;
          this.filtervalue = res.filtervalue;          
        }
        
        this.selectednewdates = this.selecteddates;
        this.loadcalls(this.filterselected,this.selecteddates);
        this.loadMonthlycalls(this.filterselected,this.selecteddates);
        this.loadWeeklycalls(this.filterselected,this.selecteddates);
        this.loadWeekdayscalls(this.filterselected,this.selecteddates);
      });
  }

  getRowClass = (row) => {    
   return {
     'row-color': row.date_of_call == 'Total Result' || row.month == 'Total Result' || row.month == 'Total Results' || row.brand == 'Total Results'
   };
  }

  islabelvaltext:any;
  savelabel(){
    // console.log(this.labelval);
    // console.log(this.selected.startDate._d);
    // console.log(this.filtertype);
    // console.log(this.filtervalue);
    const uuid = this.route.snapshot.paramMap.get('id');
    // console.log(this.selected1);
    // return false;
   
    if(this.labelval==undefined){
      this.islabelvaltext='yes';
      return;
    }
    this.islabelvaltext='';
    const formData = new FormData();
    if(uuid!=null){
      formData.append('uuid', uuid);  
    }
    if(!this.selecteddate){
      this.selecteddate =this.selected1;
    }
    var start = moment(this.selecteddate.startDate).format('MM/DD/YYYY HH:mm:ss');
    var end = moment(this.selecteddate.endDate).format('MM/DD/YYYY HH:mm:ss');
  
    this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
    this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
    // console.log(this.filtertype);
    // console.log(this.startdate);
    // console.log(this.enddate);
    // return false;
    if(this.filtervalue==""){
      this.filtertype = 0;
    }    
    // console.log(this.filterselected);return false;
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('user_uuid', this.currentUser.uuid);
    formData.append('reportlabelname', this.labelval);
    formData.append('start_date', this.startdate);
    formData.append('end_date', this.enddate);
    formData.append('filtertype', this.filtertype);
    formData.append('filtervalue', this.filtervalue);
    formData.append('filterarr', JSON.stringify(this.filterselected));
    this.moduleservice.savereport(formData).subscribe(
      res => {
        if (res.status !== 'error') {
          if(res.isavail!=1){
            this.labelval="";
          }
          this.sendMenuUpdateMessage();
          this.toastrService.success('Report Saved Successfully');         
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      });
  }

  deletelabel(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Selected Report Name!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const uuid = this.route.snapshot.paramMap.get('id');
        this.moduleservice.deleteSelectedSavedReport(uuid).subscribe(
          res => {
              Swal.fire(
                'Deleted!',
                'Records Deleted Successfully.',
                'success',                
              )
              this.sendMenuUpdateMessage();
              setTimeout(() => {
                Swal.close()                
                this.router.navigate(['/reports']);
              }, 2000);
              
            },
            error => this.error = error
        );      
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }

  // groupby:any=1;
  getSummary(type){
    // alert(type);
    // this.filterselected=[];
    // console.log(this.selecteddate); return
    if(this.selecteddate == undefined){
      this.selecteddate = this.selected1;
    }
    // this.selecteddate = this.selected1;
    $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
    
    if(type==1){
       $("#tabs1").addClass("activetab");this.filtertype=1;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,1);
    }
    if(type==2){
       $("#tabs2").addClass("activetab");this.filtertype=4;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,4);
    }
    if(type==3){
       $("#tabs3").addClass("activetab");this.filtertype=2;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,2);
    }
    if(type==4){
       $("#tabs4").addClass("activetab");this.filtertype=3;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,3);
    }
    if(type==5){
       $("#tabs5").addClass("activetab");this.filtertype=5;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,5);
    }
    if(type==5){ $("#tabs5").addClass("activetab");this.filtertype=5;}
    if(type==6){ $("#tabs6").addClass("activetab");this.filtertype=6;}
    if(type==7){ $("#tabs7").addClass("activetab");this.filtertype=7;}
    if(type==8){ $("#tabs8").addClass("activetab");this.filtertype=8;}
    if(type==9){ $("#tabs9").addClass("activetab");this.filtertype=9;
    // this.loadcallsdata(this.filterselected,this.selecteddate,9);
    }
    if(type==10){ $("#tabs10").addClass("activetab");this.filtertype=10;
    // this.loadcallsdata(this.filterselected,this.selecteddate,10);
    }
  }
 
  islive:Number=0;
  getcallDetails(type){
    // alert(type);
    this.filterselected=[];
    $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
    if(type==1){
       this.islive = 0;
       $("#calltabs1").addClass("activetab");this.filtertype=1;
       this.loadcalls(this.filterselected,this.selecteddate);
       this.loadMonthlycalls(this.filterselected,this.selecteddate);       
    }
    if(type==5){
       this.islive = 5;
       $("#calltabs5").addClass("activetab");
       this.loadWeeklycalls(this.filterselected,this.selecteddate);
       this.loadWeekdayscalls(this.filterselected,this.selecteddate);
    }
    if(type==2){
       this.islive = 1;
       $("#calltabs2").addClass("activetab");this.filtertype=4;
       this.loadWebsiteWeeklycalls(this.filterselected,this.selecteddate);
       this.loadwMonthlycalls(this.filterselected,this.selecteddate);
    }    
    if(type==3){
       this.islive = 2;
       $("#calltabs3").addClass("activetab");
       this.loadBrandWebsitecalls(this.filterselected,this.selecteddate);      
    }    
    if(type==4){
       this.islive = 3;
       $("#calltabs4").addClass("activetab");
       this.loadBrandAndWebsitecalls(this.filterselected,this.selecteddate);
      
    }    
  }

  
  scrolltoy(){
    const scrollDemo = document.querySelector("datatable-body");
    $('datatable-body').scrollLeft(scrollDemo.scrollLeft + 500);  
  }
  scrolltox(){
    const scrollDemo = document.querySelector("datatable-body");
    $('datatable-body').scrollLeft(scrollDemo.scrollLeft - 500);    
  }

  filterbyvalue(uuid,value,type = 0){
    // console.log(uuid);
    // console.log(value); return false;
    // this.togglepanel();
    this.istougle = 1;
    this.isclick = 1;
    this.filterdata(this.filtertype);
    let customObj = new filterarr();
    customObj.filtertype = this.filtertype;
    customObj.uuid = uuid; 
    customObj.name = value; 
    customObj.isorand = 1; 
    customObj.isdup = type; 
    // console.log(this.selectElementText);
    if(value!=""){
      this.filterselected.push(customObj);  
    }
    // console.log(this.filterselected);return    
    this.filterval('');
  }

  
  ngOnDestroy(){
    // this.dtTrigger.unsubscribe();
    // this.subscription.unsubscribe();
    // console.log('Report method destroyed');    
  }  


  play(url: string, incomingnumber:any, date:any){
    // console.log(res.data[0]);
    let urltype;
    if(date <= "2022-10-17"){
      urltype = 'https://repuatationmanagement.s3.us-west-2.amazonaws.com/';
    }else{
      urltype = "https://d2pktjhs7fqsvz.cloudfront.net/"; 
    }
    

    this.toastrService.show(`<audio style="width: 130px;height: 30px;" controls><source src="${urltype}${url}" type="audio/mp3"></audio>`,
    'Inbound # - '+incomingnumber , {
      closeButton: true,
        extendedTimeOut: 0,
        timeOut: 0,
        
        tapToDismiss: false,
             enableHtml: true,
             positionClass: 'toast-bottom-right' ,
         });
  }
  copyName(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);   
  }

  
  filtercalldata(filtertype,uuid:any, no:any){
  
    
    this.istougle = 1;
    this.isclick = 1;
    
    this.filterdata(filtertype);
    let customObj = new filterarr();
    customObj.filtertype = filtertype;
    customObj.uuid = uuid; 
    customObj.name = no; 
    customObj.isorand = 1; 
    // console.log(this.selectElementText);
    if(no!=""){
      this.filterselected.push(customObj);  
    }
    this.filter = 1;
    this.isselectElement = 1;
   
    this.loadcalls(this.filterselected,this.selecteddate);
    this.loadMonthlycalls(this.filterselected,this.selecteddate);
    this.loadWeeklycalls(this.filterselected,this.selecteddate);
    this.loadWeekdayscalls(this.filterselected,this.selecteddate);
   
  }
  

    
  }