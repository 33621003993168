import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component,ViewEncapsulation, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ModuleService } from '../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute, Event } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {RequestOptions } from '@angular/http';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from './../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import "datatables.net";
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Chart } from 'angular-highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
import 'moment-timezone';
HC_exporting(Highcharts);
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import 'rxjs/add/observable/interval';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  totalarr:any[]
}

class filterarr
{
  filtertype: number;
  isorand: number;
  uuid: string;
  name: string;
  isdup: Number;
}

@Component({
  selector: 'app-reports-comparison',
  templateUrl: './reports-comparison.component.html',
  styleUrls: ['./reports-comparison.component.scss']
})
export class ReportsComparisonComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
   
    labelval: any;
    selected: any;
    selected_2: any;
    selected2:any;
    numbers: any = '';
    alwaysShowCalendars: boolean;
    showRangeLabelOnInput: boolean;
    keepCalendarOpeningWithRange: boolean;
 
    @ViewChild('dataTable1', {static: true}) table1: ElementRef;
    @ViewChild('myTable') table: any;
  
    dataTable: any;
    dataTable1: any;
    currentUser: any;
    currentCompany: any;
    label: Number;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {
     
    };
    dtTrigger: Subject<any> = new Subject<any>();
    dtOptionsdetails: DataTables.Settings = {};
    labels: any = 0; 
    callsdata: any = []; 
    private http: HttpClient;
    searchcallsform: FormGroup;
    submitted = false;
    event:any="";
    expanded: any = {};
    timeout: any;
    rows: any[] = [];
    selectednewdates:any;
    // subscriptions : Subscription[];
    
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private el: ElementRef,private ngxLoader: NgxUiLoaderService,
      private cdRef:ChangeDetectorRef
    ){ 
      $("#summarytbl").dataTable().fnDestroy();
      this.dtOptions = {
        "order": [],
        paging:false,
        searching:false,
        info : false,
        
      }
      
      this.http = new HttpClient(handler);
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.alwaysShowCalendars = true;
      this.keepCalendarOpeningWithRange = true;
      this.showRangeLabelOnInput = true;
     
      this.selected = {startDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY"), endDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY")};        
      this.selected_2 = {startDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY"), endDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY")};        
      
      this.selected1 = this.selected;  
      this.selected2 = this.selected;  
      
    }
  
  
    onPage(event) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        console.log('paged!', event);
      }, 100);
    }

    sendMenuUpdateMessage(): void {
      // send message to subscribers via observable subject
        this.moduleservice.sendMenuUpdateMessage('1');
    }
  
    ranges: any = {
      Today: [ moment.tz(moment(), "America/New_York").format("MM/DD/YYYY"),  moment.tz(moment(), "America/New_York").format("MM/DD/YYYY")],
      Yesterday: [moment.tz(moment().subtract(1, 'days'), "America/New_York").format("MM/DD/YYYY"),  moment.tz(moment().subtract(1, 'days'), "America/New_York").format("MM/DD/YYYY")],      
    };
    
     
    ngxclass="dark";
    subscriptions : Subscription[];
    subscription: Subscription;
    intervalId: number;
    ngOnInit() {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      const uuid = this.route.snapshot.paramMap.get('id');

      
      // console.log(uuid);
      if(uuid!=null){      
        // this.getsavedreports(uuid);
      }else{
        // this.loadcalls("","");
        // this.loadcallsdata("","","",1);
        // this.getChartNumberOfCalls(0,0,0,2);
        // this.getsavedreports();
      }
      if($("body").hasClass("theme-white")) {
        this.ngxclass='material';
      }
  
    }
  
    ischart:any=0;
    chart:any;
    date1:string;
    chartarg:any;
    bgcolorcode:any; 
    colorcode:any='#fff';   
           
    getChartNumberOfCalls(filterselected:any,date:any,date_2:any,daytype:any){
      this.showLoadingIndicator= true;
      this.ngxLoader.startLoader('loader-03');
      if ($("html").hasClass("theme-light")) {
        this.bgcolorcode='rgb(17,19,25)';          
        // this.colorcode='rgb(17,19,25)';          
      }
  
      this.chartarg = {
        filterselected: filterselected,
        date:date,
        date_2:date_2,
      };
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.moduleservice.getChartbyfilterforcomparison(this.currentCompany.uuid,this.chartarg,daytype,this.currentUser.timezone).subscribe(
        res => {
          this.showLoadingIndicator= false;
          this.ischart= 1;
          this.chart = new Chart({

            chart: {
              polar: true,
              type: 'line',
              zoomType: 'x',
              backgroundColor:this.bgcolorcode,                      
            },
            exporting: {
              enabled: true
            },
            title: {
              text: 'Comparision Report',
              style: {
                color: this.colorcode
              },
            },
            subtitle: {
              text: document.ontouchstart === undefined ?
                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
                style: {
                  color: this.colorcode
              },
            },
            credits: {
              enabled: false
            },  
            tooltip: {
              shared: true,
            },  
            xAxis: {
                minRange: 0,
                gridLineWidth: 0,
                title: {
                  text: 'Number Of Calls',
                  style: {
                    color: this.colorcode
                  },
                },
                categories: res.datearr
            },
            yAxis: {
              min:0,
              gridLineWidth: 1,
              title: {
                text: 'Number Of Calls',
                style: {
                  color: this.colorcode
              },
              }
            },
            colors: ['rgb(58,186,244)','rgb(15, 255, 105)'],
            plotOptions: {              
              column: {
                colorByPoint: true
              },
              line: {
                    dataLabels: {
                      useHTML: true,
                      enabled: true,
                      format: '{point.percentage:.1f} %',
                      color: 'white',                
                  },
                  enableMouseTracking: false,
              }
            },
            series: [{
              data: res.chartdata,
              type: 'column',      
              name:this.selecteddate.startDate.split(' ')[0] +' | '+this.selecteddate.endDate.split(' ')[0],
              color: 'rgb(58,186,244)',
              
            },{
              data: res.chartdata_2,
              type: 'column',      
              name:this.selecteddate_2.startDate.split(' ')[0] +' | '+this.selecteddate_2.endDate.split(' ')[0],
              color: 'rgb(15, 255, 105)',
            }       
            // ,{
            //   name: 'Converted',
            //   color: '#F9B236',
            //   type: 'spline',
            //   data: res.converted,   
            // },{
            //   name: 'Connected',
            //   type: 'spline',
            //   color: '#20bf55',    
            //   data: res.connected,           
            // }
            // ,{
            //   name: 'No Answer',
            //   type: 'spline',
            //   color: 'red',    
            //   data: res.noanswer,           
            // },{
            //   name: 'No Connect',
            //   type: 'spline',
            //   color: 'red',    
            //   data: res.noconnect,          
            //   }
            ]
          }); 
          this.ngxLoader.stopLoader('loader-03');
        },
        error => this.error = error
      );  
      
    }
  
    play(url: string, incomingnumber:any){
          
      this.toastrService.show(`<audio style="width: 130px;height: 30px;" controls><source src="https://dialdigit2.s3.us-west-1.amazonaws.com/${url}" type="audio/mp3"></audio>`,
      'Inbound # - '+incomingnumber , {
        closeButton: true,
          extendedTimeOut: 0,
          timeOut: 0,
          
          tapToDismiss: false,
               enableHtml: true,
               positionClass: 'toast-bottom-right' ,
           });
    }
  
    filterselectedforexport:any;
    dateforexport:any;
    totaldata:any;
    totalcount:any;
  
    loadcallsdata(filterselected:any,date:any,date_2:any,groupby:any){
      $("#datatable1").dataTable().fnDestroy();
      // $("#summarytbl").dataTable().fnDestroy();
      
      this.ngxLoader.startLoader('loader-02');
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      // this.ngxLoader.startLoader('loader-03');
    // console.log(this.ServerUrl);
    
      this.http.post<any>(
                this.ServerUrl+'getcallsdataforcomparison/'+this.currentCompany.uuid, {'filterselected':filterselected,'date':date,'date_2':date_2, 'timezone':this.currentUser.timezone,'groupby':groupby}
              , {}
              ).subscribe(resp => {
                    //                    
                    this.callsdata = resp.data.numbers; 
                    // console.log(resp.data.total);
                    
                    this.totalcount = resp.data.total; 
                    this.totaldata  = resp.data.length ;
                    this.dtTrigger.next();
                    this.ngxLoader.stopLoader('loader-02');
                  },
                  error => this.error = error
              );   
    }
  
   
     noofrecords:any = 500;
     exportdata(){
    
      var that = this;
      var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true, 
        // showTitle: true,
        // title: 'Your title',
        useBom: true,
        // noDownload: true,
        headers: ["Number Called", "Caller ID"," Publisher","Target","Campaign","Routed To","Call Duration","Labels","IVR Inputs","Date of Call","Time of Call","Revenue","Payout"]
      };
  
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  
      const formData = new FormData();      
      formData.append('user_uuid', currentUser.uuid);
      formData.append('timezone', this.currentUser.timezone);
      formData.append('filterselected', JSON.stringify(this.filterselected));
      formData.append('date',JSON.stringify(this.selecteddate));
      formData.append('totalrecords', this.noofrecords);
      // console.log(formData);return;
      this.showLoadingIndicator =true;
      this.moduleservice.getCalldetailsForExport(this.currentCompany.uuid,formData).subscribe(
        res => {
          // console.log(res);return;
          new   ngxCsv(res.data, 'All Calls', options);
          this.showLoadingIndicator =false;
        },
        error => this.error = error
    );      
    }
  
    exportcalldata(){
      var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true, 
        useBom: true,
        headers: ["UUID","Name(Filter)", "Incoming","Connected","Qualified","Converted","Completed","No Answer","No Connect","Payout","Revenue","Profit","CR%","RPC","ACL","TCL"]
      };
      new   ngxCsv(this.callsdata, 'All Calls', options);
    }
  
    isclick:number = 0;
    istougle:number = 1;
  
    togglepanel(){
      if(this.isclick==1){
        this.istougle = 1;
        this.isclick = 0;
      }else{
        this.isclick = 1;
        this.istougle = 0;
      }
    }
  
    filter:number;
    filtertext:string;
    activeresultdata:any; 
    resultdata:any; 
    nonactiveresultdata:any; 
    filtertype:any = 1;   
    filterdata(type:number){
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.filter = 0;
      this.isselectElement = 0;
      if(type == 1){      
        this.activeresultdata=[];
        this.nonactiveresultdata=[];
        this.filter = 1;
        this.filtertype = 1;
        this.filtertext = 'Select Campaign';
        this.filtervalue="";
        this.moduleservice.getallcampain(this.currentCompany.uuid).subscribe(
          res => {
            this.activeresultdata = res.active;
            this.nonactiveresultdata = res.non_active;
          });
      }
      if(type == 2){
       this.resultdata=[];
        this.filtertype = 2;
        this.filter = 1;
        this.filtertext = 'Select Target';
        this.filtervalue="";
        this.moduleservice.getAlltarget(this.currentCompany.uuid).subscribe(
          res => {
            this.resultdata = res;
          });
      }
      if(type == 3){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 3;
        this.filtertext = 'Select Buyer';
        this.filtervalue="";
        this.moduleservice.getallbuyers(this.currentCompany.uuid).subscribe(
          res => {
            this.resultdata = res;
          });      
      }
      if(type == 4){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 4;
        this.filtertext = 'Select Publisher';
        this.filtervalue="";
        this.moduleservice.getAllpublishers(this.currentCompany.uuid).subscribe(
          res => {
            this.resultdata = res;
          });     
      }
     
      if(type == 5){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 5;
        this.filtertext = 'Enter Caller ID';
        this.filtervalue="";    
      }
    
      if(type == 6){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 6;
        this.filtertext = 'Enter Number Called';
        this.filtervalue="";    
      }
     
      if(type == 8){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 8;
        this.filtertext = 'Select IVR Inputs';
        this.filtervalue="";    
        this.resultdata = [{
          'id':0,
          'text':'No',
        },{
          'id':1,
          'text':'Yes',
        }];
      }
      
      if(type == 9){
        this.resultdata=[];
        this.filter = 1;
        this.filtertype = 9;
        this.filtertext = 'Select Ringtree';
        this.filtervalue="";    
        this.resultdata = [{
          'id':0,
          'text':'No',
        },{
          'id':1,
          'text':'Yes',
        }];
      }
  
    }
  
    clearfilter(index:any){
      this.filterselected.splice(index,1);
      this.loadcallsdata(this.filterselected,this.selecteddate,this.selecteddate_2,1);
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.selecteddate_2,this.daytypeval);
    }

    comparisondate:any;
    setdate(event:any,type:Number){
      if(event && event.startDate==null && event.endDate==null)
      return false;
      if(event){
      var start = moment(event.startDate._d).format('MM/DD/YYYY HH:mm:ss');
      var end = moment(event.endDate._d).format('MM/DD/YYYY HH:mm:ss');
      this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
      this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
      
        this.selected1 = {
          startDate: this.startdate,
          endDate: this.enddate,
        };
        this.comparisondate = this.selected1;
        // var a = moment(start);
        // var b = moment(end);
        // if(b.diff(a, 'days')>0){
          this.daytypeval = 1;      
        // }else{
        //   this.daytypeval = 1;      
        // }
        this.isfiltered = 1;
      }else{
        this.isselectElement = 1;
        this.isadded = 0;
        const uuid = this.route.snapshot.paramMap.get('id');
        if(uuid!=null){
          
          var start = moment(this.selected.startDate).format('MM/DD/YYYY HH:mm:ss');
          var end = moment(this.selected.endDate).format('MM/DD/YYYY HH:mm:ss'); 
        }else{
          if(this.selected1.startDate._d!=undefined){
            var start = moment(this.selected1.startDate._d).format('MM/DD/YYYY HH:mm:ss');
            var end = moment(this.selected1.endDate._d).format('MM/DD/YYYY HH:mm:ss');
          }else{
            var start = moment(this.selected2.startDate).format('MM/DD/YYYY HH:mm:ss');
            var end = moment(this.selected2.endDate).format('MM/DD/YYYY HH:mm:ss');
            
          }
        }      
        this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
        this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
        if(this.isfiltered==0){
          this.selected1 = {
            startDate: this.startdate,
            endDate: this.enddate,
          };
          this.comparisondate = "";
        }else{
          this.comparisondate = this.selected1;
        }
        
        this.daytypeval = 1;
      }

      if(type == 1){
        this.selecteddate = this.comparisondate;
      }else{
        this.selecteddate_2 = this.comparisondate;
      }
    }
  
    filtervalue: any = '';
    filterdate: any = '';
    startdate: any;
    enddate: any;
    diff: any;
    days: any;
    selected1: any;
    selecteddate: any;
    selecteddate_2: any;
    isadded: any=1;
    isfiltered: any=0;
    filterval(group_by:Number){
      if(this.selecteddate==undefined || this.selecteddate_2==undefined){
        this.selecteddate = {startDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY"), endDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY")};
        this.selecteddate_2 = {startDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY"), endDate: moment.tz(moment(), "America/New_York").format("MM/DD/YYYY")};
      }
      // console.log(this.selecteddate);
      // console.log(this.selecteddate_2);
      //  return false;
      // this.filterselected=[];
      let customObj = new filterarr();
      customObj.filtertype = this.filtertype;
      customObj.uuid = this.filtervalue; 
      customObj.name = this.selectElementText; 
      customObj.isorand = 0; 
      // console.log(this.selectElementText);
      if(this.selectElementText!=undefined && this.filterselected?.length==0 && !event){
        this.filterselected.push(customObj);  
      }
     
      if(group_by == undefined){
        group_by = 1;
      }
      // this.loadcalls(this.filterselected,this.selecteddate);
      this.loadcallsdata(this.filterselected,this.selecteddate,this.selecteddate_2,group_by);
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.selecteddate_2, 1);
     
    }
    
  
    calcelfilter(){
      this.isclick = 0; 
      // this.filter = 0; 
      // this.filtertype = 0; 
      this.isselectElement = 0; 
      this.filtertype = 1; 
      this.filterdata(1); 
      this.filtervalue = ''; 
      this.filterselected=[];
      this.isadded = 1;
      // this.loadcalls("","");
      this.loadcallsdata(this.filterselected,this.selecteddate,this.selecteddate_2,1);
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.selecteddate_2,this.daytypeval);
    }
  
    isselectElement:any=0;
    selectElementText:any;
    filterselected: Array<filterarr> = [];
    gettext(event:any,type){
      if(type==1)
       this.selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
       else
       this.selectElementText = event.target.value
      // console.log(event.target.value);   
    }
    addorrule(){
      let customObj = new filterarr();
      customObj.filtertype = this.filtertype;
      customObj.uuid = this.filtervalue; 
      customObj.name = this.selectElementText; 
      customObj.isorand = 0; 
      // console.log(this.selectElementText);
      if(this.selectElementText!=undefined){
        this.filterselected.push(customObj);  
      }
      // console.log(this.filterselected);
    }
  
    addandrule(){
      let customObj = new filterarr();
      customObj.filtertype = this.filtertype;
      customObj.uuid = this.filtervalue; 
      customObj.name = this.selectElementText; 
      customObj.isorand = 1; 
      // console.log(this.selectElementText);
      if(this.selectElementText!=undefined){
        this.filterselected.push(customObj);  
      }
    }
  
    editfilter(){
      this.isadded=1;
      this.isselectElement=0;
    }
  
    charttype:string = "Calls Per Hour";
    daytypeval:number = 1;
    chartby(type:number){
      this.filterdate="";
      if(type==1){
        this.daytypeval = 1;
        this.charttype = "Calls Per Hour";
        this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.selecteddate_2,1);
      }
      if(type==2){
        this.daytypeval = 2;
        this.charttype = "Calls Per Day";
        this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.selecteddate_2,2);
      }
      if(type==3){
        this.daytypeval = 3;
        this.charttype = "Calls Per Month";
        this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.selecteddate_2,3);
      }    
    }
  
    // savedreportsettings:any;
    // selecteddates:any;
    // isreport:any = 0;
    // getsavedreports(uuid:string){
    //   this.moduleservice.getsavedreportsbyuuid(uuid).subscribe(
    //     res => {
    //       this.labelval = res.reportlabelname;
    //       this.daytypeval = 2;     
    //       if(res.filtertype!=''){
    //         this.isreport = 1;
    //         if(res.filtertype!=0){ this.isclick = 1; }
    //         this.filter = res.filtertype;
    //         let startday = res.start_date;
    //         let endday = res.end_date;
    //         const d = new Date(startday);
    //         const d1 = new Date(endday);
    //         moment(d).format('MMMM d, YYYY');
    //         moment(d1).format('MMMM d, YYYY');
    //         var start = moment(d).format('MM/DD/YYYY HH:mm:ss');
    //         var end = moment(d1).format('MM/DD/YYYY HH:mm:ss');
          
    //         this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
    //         this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
                       
    //         this.selected = {startDate: d, endDate: d1};
    //         this.selecteddates = {startDate: this.startdate, endDate: this.enddate};
    //         this.filterselected = JSON.parse(res.filterarr);
    //         var a = moment(d);
    //         var b = moment(d1);
    //         if(b.diff(a, 'days')>0){
    //             this.daytypeval = 2;
    //             this.charttype = "Calls Per Day";
    //         }else{
    //             this.daytypeval = 1;
    //             this.charttype = "Calls Per Hour";
    //         }
          
    //       }
    //       this.filterdata(this.filter);
    //       if(res.filtervalue!=''){
    //         this.isclick = 1;
    //         this.filtervalue = res.filtervalue;          
    //       }
          
    //       this.selectednewdates = this.selecteddates;
         
    //       this.loadcallsdata(this.filterselected,this.selecteddates,1);
    //       this.getChartNumberOfCalls(this.filterselected,this.selecteddates,this.daytypeval);
          
    //     });
    // }
  
    // islabelvaltext:any;
    // savelabel(){
    
    //   const uuid = this.route.snapshot.paramMap.get('id');
     
    //   if(this.labelval==undefined){
    //     this.islabelvaltext='yes';
    //     return;
    //   }
    //   this.islabelvaltext='';
    //   const formData = new FormData();
    //   if(uuid!=null){
    //     formData.append('uuid', uuid);  
    //   }
    //   if(!this.selecteddate){
    //     this.selecteddate =this.selected1;
    //   }
    //   var start = moment(this.selecteddate.startDate).format('MM/DD/YYYY HH:mm:ss');
    //   var end = moment(this.selecteddate.endDate).format('MM/DD/YYYY HH:mm:ss');
    
    //   this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
    //   this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
    
    //   if(this.filtervalue==""){
    //     this.filtertype = 0;
    //   }    
    //   // console.log(this.filterselected);return false;
    //   formData.append('company_uuid', this.currentCompany.uuid);
    //   formData.append('reportlabelname', this.labelval);
    //   formData.append('start_date', this.startdate);
    //   formData.append('end_date', this.enddate);
    //   formData.append('filtertype', this.filtertype);
    //   formData.append('filtervalue', this.filtervalue);
    //   formData.append('filterarr', JSON.stringify(this.filterselected));
    //   this.moduleservice.savereport(formData).subscribe(
    //     res => {
    //       if (res.status !== 'error') {
    //         if(res.isavail!=1){
    //           this.labelval="";
    //         }
    //         this.sendMenuUpdateMessage();
    //         this.toastrService.success('Report Saved Successfully');         
    //       }else{
    //         this.toastrService.error('Failed To Add Label');
    //       }
    //     });
    // }
  
    // deletelabel(){
    //   Swal.fire({
    //     title: 'Are you sure?',
    //     text: 'You want to Delete Selected Report Name!',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, delete it!',
    //     cancelButtonText: 'No, keep it'
    //   }).then((result) => {
    //     if (result.value) {
    //       const uuid = this.route.snapshot.paramMap.get('id');
    //       this.moduleservice.deleteSelectedSavedReport(uuid).subscribe(
    //         res => {
    //             Swal.fire(
    //               'Deleted!',
    //               'Records Deleted Successfully.',
    //               'success',                
    //             )
    //             this.sendMenuUpdateMessage();
    //             setTimeout(() => {
    //               Swal.close()                
    //               this.router.navigate(['/reports']);
    //             }, 2000);
                
    //           },
    //           error => this.error = error
    //       );      
    //     } else if (result.dismiss === Swal.DismissReason.cancel) {
    //       Swal.fire(
    //         'Cancelled',
    //         'Your Record is safe :)',
    //         'error'
    //       )
    //       setTimeout(() => {
    //         Swal.close()
    //       }, 2000);
    //     }
    //   });
    // }
  
    // groupby:any=1;
    getSummary(type){
     
      if(this.selecteddate == undefined){
        this.selecteddate = this.selected1;
      }
      // this.selecteddate = this.selected1;
      $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
      if(type==1){
         $("#tabs1").addClass("activetab");this.filtertype=1;
         this.loadcallsdata(this.filterselected,this.selecteddate,this.selecteddate_2,1);
      }
      if(type==2){
         $("#tabs2").addClass("activetab");this.filtertype=4;
         this.loadcallsdata(this.filterselected,this.selecteddate,this.selecteddate_2,4);
      }
      if(type==3){
         $("#tabs3").addClass("activetab");this.filtertype=2;
         this.loadcallsdata(this.filterselected,this.selecteddate,this.selecteddate_2,2);
      }
      if(type==4){
         $("#tabs4").addClass("activetab");this.filtertype=3;
         this.loadcallsdata(this.filterselected,this.selecteddate,this.selecteddate_2,3);
      }
      if(type==5){
         $("#tabs5").addClass("activetab");this.filtertype=5;
         this.loadcallsdata(this.filterselected,this.selecteddate,this.selecteddate_2,5);
      }
      if(type==5){ $("#tabs5").addClass("activetab");this.filtertype=5;}
      if(type==6){ $("#tabs6").addClass("activetab");this.filtertype=6;}
      if(type==7){ $("#tabs7").addClass("activetab");this.filtertype=7;}
      if(type==8){ $("#tabs8").addClass("activetab");this.filtertype=8;}
    }
   
    scrolltoy(){
      $('datatable-body').scrollLeft(1000);
   
    }
    scrolltox(){
      $('datatable-body').scrollLeft(0);
    }
  
  
    filterbyvalue(uuid,value,type = 0){
     
      this.istougle = 1;
      this.isclick = 1;
      this.filterdata(this.filtertype);
      let customObj = new filterarr();
      customObj.filtertype = this.filtertype;
      customObj.uuid = uuid; 
      customObj.name = value; 
      customObj.isorand = 1; 
      customObj.isdup = type; 
      if(value!=""){
        this.filterselected.push(customObj);  
      }
      this.filterval(this.filtertype);
    }
  
    
    ngOnDestroy(){
      this.dtTrigger.unsubscribe();
      console.log('Report method destroyed');    
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }  
  
    
  
    copyName(val: string){
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);   
    }
  
    
    filtercalldata(filtertype,uuid:any, no:any){
    
      
      this.istougle = 1;
      this.isclick = 1;
      
      this.filterdata(filtertype);
      let customObj = new filterarr();
      customObj.filtertype = filtertype;
      customObj.uuid = uuid; 
      customObj.name = no; 
      customObj.isorand = 1; 
      if(no!=""){
        this.filterselected.push(customObj);  
      }
      this.filter = 1;
      this.isselectElement = 1;
   
      this.loadcallsdata(this.filterselected,this.selecteddate,this.selecteddate_2,1);
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.selecteddate_2,this.daytypeval);    
      
    }
    
  
  }
