import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModuleService } from './../module.service';
import { Chart } from 'angular-highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
import "datatables.net";
import { GlobalVariable } from '../../global';
import { HttpBackend, HttpClient } from '@angular/common/http';
HC_exporting(Highcharts);
declare var $;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  showLoadingIndicator: any;
  currentUser: any;
  currentCompany: any;
  error: any;
  NumberOfCalls: any;  
  NumberOfIVR: any;
  NumberOfNumbers: any=0;
  NumberOfSMS: any;
  event:any="";

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  dtOptions: DataTables.Settings = {};
  private http: HttpClient;
  labels: any[];

  constructor(  private moduleservice: ModuleService,
    handler: HttpBackend,
    ) {
      this.http = new HttpClient(handler);
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     }
  
  currentDate = new Date();

  ngOnInit() {
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);

    // console.log(this.currentCompany);

    this.getNumberOfCounts();
    // this.getNumberOfIVR();
    // this.getNumberOfNumbers();
    // this.getNumberOfSMS();   
    this.getChartNumberOfCalls(this.event);
    this.loadcalls();
  }

  
  chart:any;
  date1:string;
  bgcolorcode:any;
  getChartNumberOfCalls(event:any){
    // console.log(event);return false;
    if(event!=''){
      this.date1 = event.endDate._d;  
    }else{
      this.date1 = '';
    }
    // console.log(this.date1);
    this.moduleservice.getChartbyDate(this.currentCompany.uuid,this.date1,this.currentUser.timezone,this.currentUser.uuid).subscribe(
      res => {
        //  console.log(res); 
        if ($("html").hasClass("dark")) {
          this.bgcolorcode='rgb(17,19,25)';
        }
        this.chart = new Chart({
          chart: {
            // polar: true,
            type: 'line',
            zoomType: 'x',
            backgroundColor:this.bgcolorcode
          },
          exporting: {
            enabled: true
          },
          title: {
            text: ''
          },
          // subtitle: {
          //   text: document.ontouchstart === undefined ?
          //     'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
          // },
          credits: {
            enabled: false
          },  
          xAxis: {
              minRange: 0,
              gridLineWidth: 0,
              title: {
                text: 'Hours'
              }
          },
          yAxis: {
            min:0,
            gridLineWidth: 1,
            title: {
              text: 'Number Of Calls'
            }
          },
          legend: {
            enabled: false
          },        
          // plotOptions: {
          //   area: {
          //     fillColor: {
          //       linearGradient: {
          //         x1: 0,
          //         y1: 0,
          //         x2: 0,
          //         y2: 1
          //       },
          //       stops: [
          //         [0, '#7cb5ec'],
          //         [1, 'rgba(124,181,236,0)']
          //       ]
          //     },              
          //     marker: {
          //       radius: 2
          //     },
          //     lineWidth: 1,
          //     states: {
          //       hover: {
          //         lineWidth: 1
          //       }
          //     },
          //     threshold: null
          //   }
            
          // },
          plotOptions: {
            area: {
                  fillColor: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1
                    },
                    stops: [
                      [0, '#7cb5ec'],
                      [1, 'rgba(124,181,236,0)']
                    ]
                  },  
                  marker: {
                    radius: 2
                  },
                  lineWidth: 1,
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
                  threshold: null
              },
              
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
          },
          series: [{
            data: res,
            type: 'area',      
            name:"Call Count"
          }]
        }); 
      },
      error => this.error = error
    );  
  }

  // getchart(event:any){  
  //   const date = event.startDate._d;  
  //   // console.log();
  //   this.moduleservice.getChartbyDate(this.currentUser.uuid,date).subscribe(
  //     res => {
  //       this.NumberOfCalls = res;   
  //     },
  //     error => this.error = error
  //   );  
  // }


  loadcalls(){
    if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
      $(this.table.nativeElement).dataTable().fnDestroy();
    } 
 
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var that = this;
    this.dtOptions = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,          
      "paging":   false,
      "info":     false,
      // "lengthMenu": [[-1, 50, 100, 500, 2000, 5000], ["All", 50, 100, 500, 2000, 5000 ]],
      "pageLength": 20,
      // "ordering": false,
      "searching":false,
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
          this.ServerUrl+'getlastweekcalls/'+this.currentCompany.uuid+'/'+this.currentUser.timezone+'/'+this.currentUser.uuid, {'dataTablesParameters':dataTablesParameters}
         , {}
        ).subscribe(resp => {
                  // console.log(resp); return false;
                  that.labels = resp.data;   
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"did"},{data:"incoming_number"},{data:"publisher"},{data:"target"},{data:"campaign"},{data:"routed_to"},{data:"routed_to"},{data:"routed_to"},
      ],
  };

  this.dataTable = $(this.table.nativeElement);
  this.dataTable.dataTable(this.dtOptions);
  }  

  getNumberOfCounts(){    
    // console.log(this.currentUser.timezone);
    this.moduleservice.getNumberOfCount(this.currentCompany.uuid,this.currentUser.timezone,this.currentUser.uuid).subscribe(
      res => {
        this.NumberOfCalls = res.NumberOfcalls;   
        this.NumberOfIVR = res.NumberOfivr;   
        this.NumberOfNumbers = res.NumberOfnos;   
        this.NumberOfSMS = res.NumberOfsms;   
        // console.log(this.NumberOfCounts);
      },
      error => this.error = error
    );  
  }

  // getNumberOfIVR(){
  //   this.moduleservice.getNumberOfCount(this.currentUser.uuid,2).subscribe(
  //     res => {
  //       this.NumberOfIVR = res; 
  //     },
  //     error => this.error = error
  //   );    
  // }
  // getNumberOfNumbers(){
  //   this.moduleservice.getNumberOfCount(this.currentUser.uuid,3).subscribe(
  //     res => {
  //       this.NumberOfNumbers = res; 
  //     },
  //     error => this.error = error
  //   );    
  // }
  // getNumberOfSMS(){
  //   this.moduleservice.getNumberOfCount(this.currentUser.uuid,4).subscribe(
  //     res => {
  //       this.NumberOfSMS = res; 
  //     },
  //     error => this.error = error
  //   );    
  // }

}
