import { GlobalVariable } from './../../../global';
import { AfterViewInit, ChangeDetectionStrategy,ViewChild, ElementRef, ChangeDetectorRef,Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup,FormArray,Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from './../../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { ngxCsv } from 'ngx-csv';

declare var $;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignEditComponent implements OnInit {

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  @ViewChild('dataTableTarget', {static: true}) tabletarget: ElementRef;
  @ViewChild('dataTableRouting', {static: true}) tablerouting: ElementRef;
  
   editForm: FormGroup;
   payoutForm: FormGroup;
   publishtertocampaignForm: FormGroup;
   editRoutingForm: FormGroup;
   currentCompany: any;
   locations:any = [];
   error: {};
   showLoadingIndicator: any;
   getdata: any;
   uuid:any;
   dropdownSettingss:IDropdownSettings;
   labeldropdownSettingss:IDropdownSettings;
   dropdownList = [];
   selectedItems = [];
   dropdownSettings = {};
   pubdropdownSettings = {};
   labeldropdownSettings = {};

   private ServerUrl = GlobalVariable.BASE_API_URL;
   
   dtOptions: DataTables.Settings = {};
   dtOptionsTarget: DataTables.Settings = {};
   dtOptionsRouting: DataTables.Settings = {};
   dataTable: any;
   dataTableTarget: any;
   dataTableRouting: any;
   campaignpublisher: any;
   private http: HttpClient;

   constructor(
       private renderer: Renderer2,
       private moduleservice: ModuleService,
       private toastrService:ToastrService,
       handler: HttpBackend,
       private fb: FormBuilder,
       private router: Router,       
       private route: ActivatedRoute,
       private cdRef: ChangeDetectorRef   
       ){
        this.http = new HttpClient(handler);
      this.uuid = this.route.snapshot.paramMap.get('id');     
   }  
 
  ngAfterViewChecked(): void {
    this.cdRef.detectChanges(); 
  }

   public allRulesbyname: any;
   getRulesByName(target_uuid: string){
    //  console.log(name);
    if(target_uuid!=''){
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleservice.getRulesByTargetuuid(target_uuid,this.currentCompany.uuid).subscribe(
        res => {
          // console.log(res);
          this.allRulesbyname = res;            
        },
        error => this.error = error,
      );  
    }else{
      this.allRulesbyname="";
    }
  }

  allweekdays:any;
loadallweekdays(){
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleservice.loadallweekdays(this.currentCompany.uuid).subscribe(
    res => {
      //  console.log(res); return false;  
      this.allweekdays = res.data;
    },
    error => this.error = error,
  );  
}

labeldata:[];
getAllLabels(){
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleservice.getAllLabels(this.currentCompany.uuid).subscribe(
    res => {
      this.labeldata = res;
      // console.log(res);
    },
    error => this.error = error,
  );  
}

   editpayout_typeval:number;
   isrecording:number;
   campaignNumbers: any = [];
   campaignLabels: any = [];

   ngOnInit() {
     this.showLoadingIndicator= true;
     setTimeout(()=>{this.showLoadingIndicator= false; },1000);

     this.dropdownSettings = { 
        singleSelection: true, 
        text:"Select Number",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class",
      };   
     
      this.pubdropdownSettings = { 
        singleSelection: true, 
        text:"Select Publisher",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class",
      };   

      this.labeldropdownSettings = { 
        singleSelection: false, 
        text:"Select Labels",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
      };   
      
     this.getAllLabels();
     this.getAllpublishers();
     this.loadallweekdays()
      this.moduleservice.getcampainbyuuid(this.uuid).subscribe(
      res => {
        this.getdata = res.campaigns;
        // this.campaignNumbers = res.campaign_numbers;
        // if(this.campaignNumbers.length>0){
        //   this.maxconcurrency = true;
        //   $(".labelnumber").prop('checked',true);
        // }

        // this.campaignLabels = res.campaign_labels;
        // if(this.campaignLabels.length>0){
        //   this.isselectlabel = true;
        //   $(".labelswitch").prop('checked',true);
        // }
        this.editpayout_typeval = res.campaigns.payout_type;
        this.payout_typevalueedit = res.campaigns.payout_type;
        this.isrecording = res.campaigns.is_recording;
        // this.getRulesByName(res.campaigns.target_uuid);
        
        this.editForm.patchValue({
          campaignName: res.campaigns.name,
          country: res.campaigns.country,
          number: res.campaigns.number,
          publisher: res.campaigns.publisher_uuid,
          // target: res.campaigns.target_uuid,   

          // conversation_revenue: res.campaigns.conversation_revenue,   
          // conversation_event: res.campaigns.conversation_event,   
          // conversation_seconds: res.campaigns.conversation_seconds,   
          
          // payout_typeedit: res.campaigns.payout_type,      
          // payout_amount: res.campaigns.payout_amount,      
          // payout_trigger: res.campaigns.payout_trigger,      
          // payout_seconds: res.campaigns.payout_seconds    
        });  
        this.numberIsFor = res.campaigns.numberIs_For;
        // console.log(res.campaigns.numberIs_For);
        this.editForm.get('numberIsFor').setValue(res.campaigns.numberIs_For); 
      },
      error => this.error = error,
    );     

     this.loadNumbers();
    
     this.getAlltarget();
     
     this.loadcampaignpublishers(this.uuid);
     this.loadcampaigntarget();
     this.loadallRoutingplan(this.uuid);

     this.payoutForm = this.fb.group({
      user_uuid: [''],      
      phoneno: [''],      
      publisher_uuid: [''],      
      payout_typeedit: [''],      
      payout_amount: [''],      
      payout_trigger: [''],      
      payout_seconds: [''],         
     });
     
     this.publishtertocampaignForm = this.fb.group({
      user_uuid: [''],      
      number: [''],      
      publisher_uuid: [''],      
      payout_typeedit: [''],      
      payout_amount: [''],      
      payout_trigger: [''],      
      payout_seconds: [''],         
     });
     
     this.editRoutingForm = this.fb.group({
      user_uuid: [''], 
      uuid: [''],      
      priority: [''],      
      weight: [''],      
      conversation_revenue: [''],      
      conversation_event: [''],      
      conversation_seconds: [''],            
      restrictdup: [''],            
      restrictafter: [''],            
      days: [''],            
      settingToggleDurationval: [''],            
      settingToggleDuration: [''],            
     });

     this.editForm = this.fb.group({
       campaignName : ['', Validators.required],
       user_uuid: [''],      
       country: [''],      
       // option: [''],      
       number: [''],      
       labeldata: [''],      
       numberIsFor: [''],      
       // numberType: [''],             
       publisher: [''],             
       target: [''], 
       conversation_revenue: [''],      
       conversation_event: [''],      
       conversation_seconds: [''],        

      //  payout_typeedit: ['',Validators.required],      
      //  payout_amount: [''],      
      //  payout_trigger: [''],      
      //  payout_seconds: [''],            
       recording: ['']            
     });
     // this.options = {
     //   multiple: true,
     //   theme: 'classic',
     //   closeOnSelect: false,
     //   width: '370'
     // };
   }

   getpayoutdata(uuid,phone,number_uuid){
    this.payoutForm.get('phoneno').setValue(number_uuid); 
    // this.payoutForm.get('phoneno').setValue(phone); 
    this.payoutForm.get('publisher_uuid').setValue(uuid); 
    this.moduleservice.getpayoutdata(uuid,number_uuid).subscribe(
      resp => {
        // console.log(resp);
        if(resp!=null){
          this.editpayout_typeval = resp.payout_type;
          this.payout_typevalueedit = resp.payout_type;
          this.payoutForm.get('payout_typeedit').setValue(this.editpayout_typeval); 
          this.payoutForm.patchValue({
            payout_typeedit: resp.payout_type,      
            payout_amount: resp.payout_amount,      
            payout_trigger: resp.payout_trigger,      
            payout_seconds: resp.payout_seconds    
          });
        }else{
          this.editpayout_typeval = 0;
          this.payout_typevalueedit = 0;
          this.payoutForm.get('payout_typeedit').setValue(0); 
          this.payoutForm.patchValue({
            payout_typeedit: 0,      
            payout_amount: "",      
            payout_trigger: 0,      
            payout_seconds: ""    
          });
        }
        },
        error => this.error = error
    );
   }

   loadcampaignpublishers(uuid){
    // console.log('ok');
    // return false;
      if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
        $(this.table.nativeElement).dataTable().fnDestroy();
      }    
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.dtOptions = {
        "pagingType": 'full_numbers',
        "processing": true,
        "serverSide": true,          
        "pageLength": 20,      
        "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],      
        "order": [[ 0, "asc" ]],
        "searchDelay": 350,
        ajax: (dataTablesParameters: any, callback) => {
          this.http.post<DataTablesResponse>(
            this.ServerUrl+'getCampaignPublisher/'+uuid,
            dataTablesParameters, {}
          ).subscribe(resp => {
                    this.campaignpublisher = resp.data;   
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
                });
        },
        columns: [
          {data:"enabled"},{data:"publisher"},{data:"phoneno"},{data:"conf"},{data:"status"},{data:"action"},
        ],
        columnDefs: [
          { 
              "targets": [0], 
              "orderable": false,
          },
          ],
    };

  this.dataTable = $(this.table.nativeElement);
  this.dataTable.dataTable(this.dtOptions);
}
  
allTargets:any
loadcampaigntarget(){
  if ($.fn.DataTable.isDataTable(this.tabletarget.nativeElement) ) {
    $(this.tabletarget.nativeElement).dataTable().fnDestroy();
   }    
   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
   this.dtOptionsTarget = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,          
      "pageLength": 20,     
      "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],             
      "order": [[ 0, "asc" ]],
      ajax: (dataTablesParameters: any, callback) => {
        this.http.post<DataTablesResponse>(
          this.ServerUrl+'gettargets/'+this.currentCompany.uuid+'/'+this.uuid,
          dataTablesParameters, {}
        ).subscribe(resp => {
                  this.allTargets = resp.data;   
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"name"},{data:"typeval"},{data:"action"},
      ],
      columnDefs: [
        { 
            "targets": [0], 
            "orderable": false,
        },
        ],
  };

this.dataTableTarget = $(this.tabletarget.nativeElement);
this.dataTableTarget.dataTable(this.dtOptionsTarget);
}

allRoutingPlan:any
loadallRoutingplan(uuid){
   if ($.fn.DataTable.isDataTable(this.tablerouting.nativeElement) ) {
    $(this.tablerouting.nativeElement).dataTable().fnDestroy();
   }    

   this.dtOptionsRouting = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,     
      // "scrollX": true,     
      "pageLength": 20,      
      "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],            
      "order": [[ 0, "asc" ]],
      "searching": false,
      ajax: (dataTablesParameters: any, callback) => {
        this.http.post<DataTablesResponse>(
          this.ServerUrl+'getallRoutingplan/'+uuid,
          dataTablesParameters, {}
        ).subscribe(resp => {
                  this.allRoutingPlan = resp.data;   
                  // console.log(this.allRoutingPlan);
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"prority"},{data:"weight"},{data:"name"},{data:"destination"},{data:"revenue"},{data:"status"},{data:"action"}
      ],
      columnDefs: [
        { 
            "targets": [0], 
            "orderable": false,
        },
        ],
  };
  this.dataTableRouting = $(this.tablerouting.nativeElement);
  this.dataTableRouting.dataTable(this.dtOptionsRouting);
}

DeleteRoutingPlan(uuid: any) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to Delete Record!',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.moduleservice.deleteRoutingPlan(uuid,currentUser.uuid).subscribe(
          res => {
            // console.log(res);return false;
            Swal.fire(
              'Deleted!',
              'Record Deleted Successfully.',
              'success',                
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);

            this.loadallRoutingplan(this.uuid);
            this.loadcampaigntarget();
          },
          error => this.error = error
      );       
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
        'error'
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}

DeletePublisherPayout(uuid: any) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to Delete Record!',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.moduleservice.deletePublisherPayout(uuid).subscribe(
          res => {
            // console.log(res);return false;
            Swal.fire(
              'Deleted!',
              'Record Deleted Successfully.',
              'success',                
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);

            this.loadcampaignpublishers(this.uuid);            
          },
          error => this.error = error
      );       
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
        'error'
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}


setRouting(uuid){
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want Add Routing Plan!',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, Add it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.moduleservice.setcampaignRouting(uuid,this.uuid,currentUser.uuid).subscribe(
          res => {

            var currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.editForm.get('user_uuid').setValue(currentUser.uuid); 
            // console.log(res);return false;
            this.loadallRoutingplan(this.uuid);
            this.loadcampaigntarget();
            // this.toastrService.success('Number Deleted Successfully');
            Swal.fire(
              'Added!',
              'Routing Plan Added Successfully.',
              'success',                
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
            
            
          },
          error => this.error = error
      );       
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Routing Plan Cancelled :)',
        'error'
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}


  //  public numberdata: Array<Select2OptionData>;
   public numberdata: [];   
   loadNumbers(){
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.loadNumbersfordropdown(this.currentCompany.uuid).subscribe(
       resp => {
           this.numberdata = resp;
          //  console.log(this.numberdata);
         },
         error => this.error = error
     );
   }
   public options: Options;
   
 
   publisherdata:any;
   getAllpublishers(){
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.getAllpublishers(this.currentCompany.uuid).subscribe(
       res => {
         this.publisherdata= res
        //  console.log(res);
         },
         error => this.error = error
     );
   }

   targetdata:any;
   getAlltarget(){
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.getAlltarget(this.currentCompany.uuid).subscribe(
       res => {
         this.targetdata= res
         },
         error => this.error = error
     );
   }
 
   numberIsFor:number;
   numberIsForval(type:number){
     this.numberIsFor = type;
     this.editForm.get('numberIsFor').setValue(type); 
 
   }
 
 Submitted = false;
 //Add Publisher
//  uuid = this.route.snapshot.paramMap.get('id');
payout_typevalueedit:any = 0;
payout_typevaledit(val:number) {
  this.payout_typevalueedit = val;
  this.payoutForm.get('payout_typeedit').setValue(val); 
  this.publishtertocampaignForm.get('payout_typeedit').setValue(val); 
}

 get editFormcontrol() { return this.editForm.controls; }  
 onSubmit () {
  //  console.log(this.campaignNumbers);
  //  return false;
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.editForm.get('user_uuid').setValue(currentUser.uuid); 
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    
    // this.editForm.get('number').setValue(this.campaignNumbers)
    // this.editForm.get('labeldata').setValue(this.campaignLabels)
    if(this.recording==2){
      this.editForm.get('recording').setValue(2)
    }
     this.showLoadingIndicator= true;
     this.moduleservice.updateCampaign(this.uuid,this.currentCompany.uuid,this.editForm.value).subscribe(
       res => {
        //  console.log(res); return false;
        this.showLoadingIndicator= false;
         if (res.status !== 'error') {
           this.isName = "fail";
           this.toastrService.success('Campaign Updated Successfully');  
           this.Submitted = false;          
           this.router.navigate(['/campaigns']);
         }else{
           this.toastrService.error('Failed To Update Campaign');
         }
       },
       error => this.error = error,
     );     
}

isName : any; 
  validatename() {
  if(this.editForm.get('campaignName').value!=''){     
    this.moduleservice.validateCampaignName(this.editForm.get('campaignName').value).subscribe(
      res =>{
          // console.log(res); return false;  
        this.isName = res;
        if (this.isName.status == 'success') {
          this.isName = "success";
        }
        else{
          this.isName = "fail";
        }
      }    
    );
  }else{
    this.isName="";
  }
}

closeModal(){
  if (!this.payoutForm.invalid) {
    $("#addLabels").modal("hide");
  }
}  

closepubModal(){
  if (!this.publishtertocampaignForm.invalid) {
    $("#addpublisher").modal("hide");
  }
}  
closeroutingModal(){
  if (!this.editRoutingForm.invalid) {
    $("#editRouting").modal("hide");
  }
}  

payoutSubmitted = false;   
 get payoutFormcontrol() { return this.payoutForm.controls; }  
 onPayoutSubmit () {
  //  console.log(this.payoutForm.value);
  //  return false;
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.payoutForm.get('user_uuid').setValue(currentUser.uuid); 
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.addpublisherpayout(this.uuid,this.payoutForm.value).subscribe(
       res => {
         if (res.status !== 'error') {
           this.toastrService.success('Publisher Payout Added Successfully');  
           this.Submitted = false;          
           this.loadcampaignpublishers(this.uuid);
         }else{
           this.toastrService.error('Failed To Update Campaign');
         }
       },
       error => this.error = error,
     );     
    }

 publisherselected:any;   
 publisherNumber:any;   
 publisherSubmitted = false;   
 get publisherFormcontrol() { return this.publishtertocampaignForm.controls; }  
 onpublisherSubmit () {
  if(this.publisherselected){
    this.publisherselected = this.publisherselected[0]['id'];
    this.publishtertocampaignForm.get('publisher_uuid').setValue(this.publisherselected); 
  }
  if(this.publisherNumber){
    this.publisherNumber = this.publisherNumber[0]['id'];
    this.publishtertocampaignForm.get('number').setValue(this.publisherNumber); 
  }
  //  console.log(this.publisherNumber);
  //  return false;
  
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.publishtertocampaignForm.get('user_uuid').setValue(currentUser.uuid); 

     this.moduleservice.addpublishertocampaign(this.uuid,this.publishtertocampaignForm.value).subscribe(
       res => {
         if (res.status !== 'error') {
           this.toastrService.success('Publisher to Campaign Added Successfully');  
           this.Submitted = false;          
           this.loadcampaignpublishers(this.uuid);
           this.publishtertocampaignForm.reset();
         }else{
           this.toastrService.error('Failed To Update Campaign');
         }
       },
       error => this.error = error,
     );     
    }
 
  recording:any=2;
  get_recordingstatus(event:any){
    this.recording = event.target.checked;
    // console.log(this.recording);
    if(this.recording==true){
    this.editForm.get('recording').setValue(1); 
    this.recording = 1;
    }
    if(this.recording==false){
    this.editForm.get('recording').setValue(0); 
    this.recording = 0
    }
  } 
  
  maxconcurrency:any;
  get_maxcon(event:any){
    this.maxconcurrency = event.target.checked;    
    // this.isselectlabel = false;    
    // $(".labelswitch").prop('checked',false);
  }
  
  isselectlabel:any;
  get_labelisselect(event:any){
    this.isselectlabel = event.target.checked;    
    // this.maxconcurrency = false;    
    // $(".labelnumber").prop('checked',false);
  }

  settingToggleDurationvalue:any = 0;
  settingToggleDuration(val:number) {
    this.settingToggleDurationvalue = val;
    this.editRoutingForm.get('settingToggleDurationval').setValue(val); 
  } 

  editRoutingdata(uuid:string,priority:number,weight:number,revenue:number,type:number,seconds:any,restrictdup:any,restrictafter:any,settingToggleDurationval:any,days:any){
    // console.log(uuid)
    this.editRoutingForm.patchValue({
      uuid: uuid,
      priority: priority,
      weight: weight,
      conversation_revenue: revenue,
      conversation_event: type,
      conversation_seconds: seconds,  
      restrictdup: restrictdup,         
      restrictafter: restrictafter,         
      days: days,         
      settingToggleDurationval: settingToggleDurationval,         
      settingToggleDuration: settingToggleDurationval,         
    });  
    this.settingToggleDurationvalue = settingToggleDurationval;

  }

 get editRoutingSubmit() { return this.editRoutingForm.controls; }  
 oneditRoutingSubmit () {  
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.editRoutingForm.get('user_uuid').setValue(currentUser.uuid); 
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.editRoutingSubmit(this.uuid,this.editRoutingForm.value).subscribe(
       res => {
         if (res.status !== 'error') {
           this.toastrService.success('Routing Plan Updated Successfully');  
           this.Submitted = false;          
           this.loadallRoutingplan(this.uuid);           
         }else{
           this.toastrService.error('Failed To Update Campaign');
         }
       },
       error => this.error = error,
     );     
}

exportdata(){

  var options = { 
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true, 
    useBom: true,
    headers: ["UUID", "Enabled"," Publisher","Publisher UUID","Number","Associated Labels","Number UUID","Configuration Overrides","Status"]
  };
  // console.log(this.campaignpublisher);
  new   ngxCsv(this.campaignpublisher, 'All Campaign Publishers', options);
  }

 }
